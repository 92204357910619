import { fallback, http } from '@wagmi/core';
import { arbitrum, base, mainnet, polygon } from '@wagmi/core/chains';
import { createConfig } from 'wagmi';

import IconArbitrum from 'assets/networks/arbitrum.svg?react';
import IconBaseSepolia from 'assets/networks/baseSepolia.svg?react';
import IconEthereum from 'assets/networks/ethereum.svg?react';
import IconPolygonMumbai from 'assets/networks/polygon.svg?react';

export const config = createConfig({
  chains: [arbitrum, mainnet, polygon, base],
  transports: {
    [arbitrum.id]: fallback([
      http('https://arb-mainnet.g.alchemy.com/v2/x9t-jIF0rKax1nMqS0juYuL4reuRvVW5'),
      http(),
    ]),
    [mainnet.id]: fallback([
      http('https://eth-mainnet.g.alchemy.com/v2/x9t-jIF0rKax1nMqS0juYuL4reuRvVW5'),
      http(),
    ]),
    [polygon.id]: fallback([
      http('https://polygon-mainnet.g.alchemy.com/v2/x9t-jIF0rKax1nMqS0juYuL4reuRvVW5'),
      http(),
    ]),
    [base.id]: fallback([
      http('https://base-mainnet.g.alchemy.com/v2/x9t-jIF0rKax1nMqS0juYuL4reuRvVW5'),
      http(),
    ]),
  },
});

export const CHAINS = {
  mainnet,
  arbitrum,
  polygon,
  base,
};

export const CHAINS_IDS = [mainnet.id, arbitrum.id, polygon.id, base.id];

export const CHAINS_LOGOS = {
  [arbitrum.id]: <IconArbitrum className="w-8 h-8" />,
  [mainnet.id]: <IconEthereum className="w-8 h-8" />,
  [base.id]: <IconBaseSepolia className="w-8 h-8" />,
  [polygon.id]: <IconPolygonMumbai className="w-8 h-8" />,
};

export const chainIcons: Record<number, string> = {
  42161: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=040',
  137: 'https://cryptologos.cc/logos/polygon-matic-logo.svg?v=040',
  1: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=040',
  8453: 'https://www.base.org/_next/static/media/logo.f6fdedfc.svg',
};
