import React from 'react';

import { MovesEnum } from 'shared/constants/shared-enums';

import paper from 'assets/png/paper.png';
import scissors from 'assets/png/scissors.png';
import rock from 'assets/png/stone.png';
import waitingIcon from 'assets/png/waiting-icon.png';

interface IconProps {
  src: string;
  alt: string;
  label: string;
  winner: boolean;
  waiting: boolean;
  draw?: boolean;
}

const Icon: React.FC<IconProps> = ({ src, alt, label, winner, waiting, draw }) => {
  return (
    <div
      className={`flex flex-col items-center rounded-[7px] w-[80px] lg:w-[186px] h-[39px] lg:h-[58px]
      ${
        draw
          ? 'bg-[#1D213E] border  border-[#36336E]'
          : winner
            ? 'bg-[#4E6CDE] border border-[#64E3FF]'
            : 'bg-[#7B3656] border border-[#E81C34]'
      }
      ${waiting ? 'opacity-50 border border-[#202066]' : ''}
    `}
    >
      <img className="w-[24px] lg:w-[40px]" src={src} alt={alt} />
      <p
        className={`text-[9px] sm:text-[12px] text-center ${
          winner ? 'text-white' : 'text-simple-text'
        }`}
      >
        {label}
      </p>
    </div>
  );
};

export const renderStreamIcon = (
  move?: MovesEnum,
  winner?: boolean,
  waiting?: boolean,
  draw: boolean,
) => {
  switch (move) {
    case MovesEnum.Paper:
      return (
        <Icon
          src={paper}
          alt="paper"
          label="Paper"
          winner={!!winner}
          waiting={!!waiting}
          draw={draw}
        />
      );
    case MovesEnum.Rock:
      return (
        <Icon
          src={rock}
          alt="rock"
          label="Rock"
          winner={!!winner}
          waiting={!!waiting}
          draw={draw}
        />
      );
    case MovesEnum.None:
      return (
        <Icon src={waitingIcon} alt="" label="" winner={!!winner} waiting={!!waiting} draw={draw} />
      );
    case MovesEnum.Scissors:
      return (
        <Icon
          src={scissors}
          alt="scissors"
          label="Scissors"
          winner={!!winner}
          waiting={!!waiting}
          draw={draw}
        />
      );
    default:
      return null;
  }
};
