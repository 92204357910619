export enum GameStatusEnum {
  NONE,
  NO_ONE_JOIN,
  WAIT_YOUR_MOVE,
  WAIT_YOUR_APPROVE,
  WAIT_YOUR_REVEAL,
  WAIT_OPPONENT_MOVE,
  WAIT_OPPONENT_REVEAL,
  JOIN_GAME,
  // TODO CHECK modal joining room
}

export enum MintingEnum {
  NONE,
  TAKE_MONEY_BACK,
  TECHNICAL_DEFEAT,
  YOUR_MOVE,
  YOUR_REVEAL,
  // TODO do refactor and add also loaders from playerCard
}
