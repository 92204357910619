import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Connector, useChainId, useConnect } from 'wagmi';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import useMediaQuery from 'shared/hooks/useMediaQuery';

import { defaultWallets } from './config';
import { ConnectorButton } from './connector-button';

export const Connect = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { t } = useTranslation();
  const chainId = useChainId();
  const { connectors, connect } = useConnect();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isMobile = useMediaQuery('(max-width: 448px)');
  const isTablet = useMediaQuery('(max-width: 1512px)');

  const hasConnectors = connectors && connectors.length > 0;
  const availableWallets = hasConnectors ? connectors : defaultWallets;

  if (isMobile)
    return (
      <div
        ref={ref}
        className="w-full max-w-[754px] border border-border px-3 pb-3 pt-4 rounded-[16px] bg-one-more-blue flex flex-col items-center mb-5"
      >
        <p className="capitalize text-[14px] font-bold text-yellow-text mb-[12px]">
          {hasConnectors ? t('common.connect') : t('common.openWith')}{' '}
          {!hasConnectors && <span className="text-white">{t('common.aWallet')}</span>}
        </p>
        <div
          className={`grid w-full gap-1.5 ${
            availableWallets.length % 3 === 0
              ? 'grid-cols-3'
              : availableWallets.length % 2 === 0
                ? 'grid-cols-2'
                : 'grid-cols-1'
          }`}
        >
          {availableWallets.length === 1 && (
            <PrimaryButton
              onClick={() => {
                connect({ connector: availableWallets[0], chainId });
              }}
              svg={
                <img
                  src={availableWallets[0].icon}
                  alt={availableWallets[0].name}
                  className="w-8 h-8 sm:w-[56px] sm:h-[56px] mr-3"
                />
              }
              text={`${t('common.connect')} ${availableWallets[0].name}`}
            />
          )}
          {availableWallets.length > 1 &&
            availableWallets.map((connector) => (
              <ConnectorButton
                key={connector.name}
                connector={connector}
                onClick={() =>
                  hasConnectors ? connect({ connector, chainId }) : connector.connect()
                }
              />
            ))}
        </div>
      </div>
    );

  if (isTablet) {
    if (availableWallets.length === 1) {
      return (
        <div ref={ref} className="w-full flex justify-center">
          <PrimaryButton
            onClick={() => {
              connect({ connector: availableWallets[0], chainId });
            }}
            text={`${t('common.connect')} ${availableWallets[0].name}`}
            svg={
              <img
                src={availableWallets[0].icon}
                alt={availableWallets[0].name}
                className="w-8 h-8"
              />
            }
          />
        </div>
      );
    } else {
      return (
        <div ref={ref} className="w-full flex justify-center">
          <PrimaryButton
            text={'common.connect'}
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
          <AnimatedTransition show={isModalOpen}>
            <div className="bg-[rgba(0,0,0,0.5)] absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
              <div className="w-full max-w-[754px] xlg:ml-auto border border-border p-8 pt-4 rounded-[16px] bg-one-more-blue flex flex-col items-center mb-5">
                <p className="capitalize text-[40px] font-bold gradient-title mb-[12px]">
                  {t('common.connect')} <span>{t('common.aWallet')}</span>
                </p>
                <div
                  className={`grid ${
                    availableWallets.length === 1 ? 'grid-cols-1' : 'grid-cols-2'
                  } w-full gap-3`}
                >
                  {availableWallets.map((connector) => (
                    <ConnectorButton
                      key={connector.name}
                      connector={connector as Connector}
                      onClick={() => connect({ connector, chainId })}
                    />
                  ))}
                </div>
              </div>
            </div>
          </AnimatedTransition>
        </div>
      );
    }
  }

  return (
    <div
      ref={ref}
      className="w-full max-w-[754px] xlg:ml-auto border border-border p-8 pt-4 rounded-[16px] bg-one-more-blue flex flex-col items-center mb-5"
    >
      <p className="capitalize text-[40px] font-bold gradient-title mb-[12px]">
        {t('common.connect')} <span>{t('common.aWallet')}</span>
      </p>
      <div
        className={`grid ${
          availableWallets.length === 1 || availableWallets.length % 2 !== 0
            ? 'grid-cols-1'
            : 'grid-cols-2'
        } w-full gap-3`}
      >
        {availableWallets.map((connector) => (
          <ConnectorButton
            key={connector.name}
            connector={connector}
            onClick={() => connect({ connector, chainId })}
          />
        ))}
        {availableWallets.length === 1 && (
          <PrimaryButton
            text={'common.connect'}
            onClick={() => {
              connect({ connector: availableWallets[0], chainId });
            }}
          />
        )}
      </div>
    </div>
  );
});
