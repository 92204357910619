import { useEffect, useState } from 'react';

import { MovesEnum } from 'shared/constants/shared-enums';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { Phase } from 'shared/store/stream-store';

import paperInactive from 'assets/png/paper-inactive.png';
import paper from 'assets/png/paper.png';
import scissorsInactive from 'assets/png/scissors-inactive.png';
import scissors from 'assets/png/scissors.png';
import rockInactive from 'assets/png/stone-inactive.png';
import rock from 'assets/png/stone.png';

import { MoveCard } from './move-card';

interface MoveCardsProps {
  position: 'left' | 'right';
  isAnimating: boolean;
  selectedMove: MovesEnum;
  isWinner: boolean;
  isRoundFinished: boolean;
  roundDraw: boolean;
  currentPhase: Phase;
  isGameFinished: boolean;
}

export const MoveCards = ({
  position,
  isAnimating,
  selectedMove,
  isWinner,
  isRoundFinished,
  roundDraw,
  currentPhase,
  isGameFinished,
}: MoveCardsProps) => {
  const [activeItem, setActiveItem] = useState<MovesEnum>(MovesEnum.Rock);
  const isMobile = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    if (isAnimating && selectedMove === MovesEnum.None) {
      const animationTimeout = setTimeout(() => {
        if (activeItem === MovesEnum.Rock) {
          setActiveItem(position === 'left' ? MovesEnum.Paper : MovesEnum.Scissors);
        }

        if (activeItem === MovesEnum.Paper) {
          setActiveItem(position === 'left' ? MovesEnum.Scissors : MovesEnum.Rock);
        }

        if (activeItem === MovesEnum.Scissors) {
          setActiveItem(position === 'left' ? MovesEnum.Rock : MovesEnum.Paper);
        }
      }, 100);

      return () => clearTimeout(animationTimeout);
    }
  }, [isAnimating, activeItem, selectedMove]);

  if ((currentPhase === 'done' || isGameFinished) && isMobile) {
    return null;
  }

  return (
    <div className="relative">
      <MoveCard
        order={2}
        position={position}
        isWinner={isWinner && selectedMove === MovesEnum.Scissors}
        isLooser={isRoundFinished && !isWinner && selectedMove === MovesEnum.Scissors}
        roundDraw={roundDraw && selectedMove === MovesEnum.Scissors}
        isRoundFinished={isRoundFinished}
        isActive={isAnimating && activeItem === MovesEnum.Scissors}
        activeIcon={scissors}
        title="Scissors"
        inactiveIcon={scissorsInactive}
        isAnimating={isAnimating}
      />

      <MoveCard
        order={1}
        position={position}
        isWinner={isWinner && selectedMove === MovesEnum.Paper}
        isLooser={isRoundFinished && !isWinner && selectedMove === MovesEnum.Paper}
        roundDraw={roundDraw && selectedMove === MovesEnum.Paper}
        isRoundFinished={isRoundFinished}
        isActive={isAnimating && activeItem === MovesEnum.Paper}
        activeIcon={paper}
        title="Paper"
        inactiveIcon={paperInactive}
        isAnimating={isAnimating}
      />

      <MoveCard
        order={0}
        position={position}
        isWinner={isWinner && selectedMove === MovesEnum.Rock}
        isLooser={isRoundFinished && !isWinner && selectedMove === MovesEnum.Rock}
        roundDraw={roundDraw && selectedMove === MovesEnum.Rock}
        isRoundFinished={isRoundFinished}
        isActive={isAnimating && activeItem === MovesEnum.Rock}
        activeIcon={rock}
        inactiveIcon={rockInactive}
        title="Rock"
        isAnimating={isAnimating}
      />
    </div>
  );
};
