import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { useRoomStore } from 'shared/store/roomStore';

const useCreateJoinRoomEvents = () => {
  const { activeRoomId } = useParams();
  const { address } = useAccount();
  const { setPlayerAAddress } = useRoomStore();

  useEffect(() => {
    if (!activeRoomId) {
      setPlayerAAddress(address?.toLowerCase() as `0x${string}`);
    }
  }, [activeRoomId]);
};

export default useCreateJoinRoomEvents;
