import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { useMoveHandler } from 'modules/Room/Hooks/useMoveHandler';
import { RockPaperScissors } from 'modules/Room/features/RockPaperScissors/RockPaperScissors';

import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';

const moveContainerStyles =
  'w-full pb-4 sm:pb-5 sm:w-[80%] sm:ml-[20%] px-[14px] flex items-end justify-end';

const MobilePlayMenu = () => {
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const { playMoves } = usePlayerGamesStore();
  const { roundCounter } = useRoomStore();

  const selectMoveHandler = useMoveHandler();

  return (
    <div className={moveContainerStyles} style={{ zIndex: 10000 }}>
      <RockPaperScissors
        move={playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move}
        selectMove={selectMoveHandler}
      />
    </div>
  );
};
export default MobilePlayMenu;
