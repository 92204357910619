import React from 'react';

import LoaderHoc from 'shared/components/ui/Loaders/LoaderHoc/LoaderHoc';

import polygonBlue from 'assets/animation/blocks/polygon-blue.png';

import './MintingLoader.scss';

interface Props {
  messages?: string[];
  className?: string;
  hash?: `0x${string}` | null | '';
  selector?: string;
}
// todo: check do we need still selector prop, same  in LoaderHoc
const MintingLoader = ({ messages, className, hash, selector }: Props) => {
  return (
    <LoaderHoc messages={messages} className={className} hash={hash} selector={selector}>
      <div className="flex center mb-10 ">
        <div className="polygons-container">
          <img src={polygonBlue} alt="polygon blue" className="polygon" />
          <img src={polygonBlue} alt="polygon blue" className="polygon" />
          <img src={polygonBlue} alt="polygon blue" className="polygon" />
        </div>
      </div>
    </LoaderHoc>
  );
};

export default MintingLoader;
