import React from 'react';

import HeartEmpty from 'assets/heart-black.svg';
import Heart from 'assets/heart-filled.svg';

interface HeartsProps {
  wins: number;
  winsTo: number;
  prefix: string;
}

export const Hearts = ({ wins, winsTo, prefix }: HeartsProps) => {
  return (
    <div className={'flex gap-3 flex-row-reverse'}>
      {Array.from({ length: winsTo }).map((_, i) =>
        i < wins ? (
          <img
            key={`${prefix}-filled-${i}`}
            src={HeartEmpty}
            alt="gold-heart"
            className="w-[14px] h-[14px] sm:w-[26px] sm:h-[26px]"
          />
        ) : (
          <img
            key={`${prefix}-empty-${i}`}
            src={Heart}
            alt={'heart'}
            className="w-[14px] h-[14px] sm:w-[26px] sm:h-[26px]"
          />
        ),
      )}
    </div>
  );
};
