import React, { Fragment, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { Transition } from '@headlessui/react';
import { EyeIcon } from 'lucide-react';
import { useAccount, useDisconnect, useSwitchChain } from 'wagmi';

import { useSwitchChainHandler } from 'modules/Room/Hooks/useSwitchHandler';

import Dropdown from 'shared/components/ui/DropDown/DropDown';
import { MediaBtn } from 'shared/components/ui/buttons/media-button';
import { chainIcons } from 'shared/config';
import { ROUTES } from 'shared/constants';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { SOCIAL_MEDIA } from 'shared/constants/socialMedia';
import { useRoomStore } from 'shared/store/roomStore';
import { useUIStore } from 'shared/store/uiStore';

import AboutIcon from 'assets/about-mobile.svg?react';
import ArrowDown from 'assets/arrowDownOneMore.svg?react';
import CloseXIcon from 'assets/close-x.svg?react';
import CopyIcon from 'assets/copy2.svg?react';
import IconDiscord from 'assets/discord.svg?react';
import GameIcon from 'assets/game.svg?react';
import LogoutIcon from 'assets/logout-mobile.svg?react';
import ReferralIcon from 'assets/referral.svg?react';
import SearchIcon from 'assets/search.svg?react';
import IconTelegram from 'assets/telegram.svg?react';

import { MenuItem } from './menu-item';

interface OverlayProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const Overlay = ({ open, setOpen }: OverlayProps) => {
  const { isConnected, chain, chainId } = useAccount();
  const { disconnect } = useDisconnect();
  const { setShowHash, someoneElseGame } = useRoomStore();
  const { activeRoomId } = useParams();
  const chainRef = useRef<HTMLButtonElement>(null);
  const showHandler = () => {
    setOpen(!open);
    setShowHash(true);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { roomStatus } = useRoomStore();
  const [networkOpen, setNetworkOpen] = useState(false);
  const { chains } = useSwitchChain();
  const switchChainHandler = useSwitchChainHandler();
  const socialMediaClick = (url: string) => () => {
    window.open(url, '_blank');
    setOpen(false);
  };
  const chainsList = useMemo(
    () =>
      chains.map((chain) => ({
        label: chain.name,
        value: chain.id,
        tooltip: `chains.${chain.id}`,
      })),
    [chains],
  );
  const { setLobbyCreateGameOpen } = useUIStore();
  return createPortal(
    <Transition
      show={open}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as={Fragment}
    >
      <div className="fixed inset-0 bg-black bg-opacity-50 z-[9999]" onClick={() => setOpen(false)}>
        <Transition.Child
          enter="transition ease-in-out duration-500 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          as={Fragment}
        >
          <div className="w-[70%] ml-[30%] md:w-[488px] md:ml-auto  h-[100%]  bg-[#0D1450] pt-[11px] px-[25px] relative">
            <button className="absolute right-[24px] top-[24px]" onClick={() => setOpen(false)}>
              <CloseXIcon className="w-[14px] h-[14px]" />
            </button>

            <ul className={'mt-[34px]'}>
              {isConnected && (
                <li className="border-b py-[16px] border-[#272D63]">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setNetworkOpen(!networkOpen);
                    }}
                    className="text-white text-[16px] md:text-[32px] font-[600] flex items-center cursor-pointer gap-2.5"
                    ref={chainRef}
                  >
                    {chain && chainIcons?.[chain?.id] && (
                      <img
                        src={chainIcons?.[chain?.id]}
                        className="mr-2.5 w-5 h-5 md:w-10 md:H-10"
                      />
                    )}
                    {chain?.name}
                    <ArrowDown
                      className={`${
                        networkOpen ? 'transform rotate-180' : ' '
                      } transition ease-in-out duration-500 w-[16px]`}
                    />
                  </button>

                  <Dropdown
                    open={networkOpen}
                    list={chainsList}
                    onClick={async (value) => {
                      await switchChainHandler(value);
                      setNetworkOpen(false);
                      if (location.pathname.startsWith(ROUTES.ROOM)) {
                        navigate(ROUTES.LOBBY);
                      }
                    }}
                    active={chainId}
                    position={{ top: 110, left: 0 }}
                  />
                </li>
              )}
              <MenuItem
                icon={SearchIcon}
                text="pages.joinGame"
                href={ROUTES.LOBBY}
                onClick={() => {
                  setLobbyCreateGameOpen(false);
                  setOpen(false);
                }}
                className="md:hidden"
              />
              <MenuItem
                icon={GameIcon}
                text="pages.play"
                href={ROUTES.LOBBY}
                orange
                onClick={() => {
                  setLobbyCreateGameOpen(true);
                  setOpen(false);
                }}
              />
              <MenuItem
                icon={EyeIcon}
                text="pages.explore"
                href={ROUTES.EXPLORE_GAMES}
                onClick={() => {
                  setLobbyCreateGameOpen(true);
                  setOpen(false);
                }}
              />
              <MenuItem
                icon={AboutIcon}
                text="pages.about"
                href="https://rock-paper-scissors.game/rules"
                external
                onClick={() => {
                  setOpen(false);
                }}
              />
              <MenuItem
                icon={ReferralIcon}
                text="pages.referral"
                href="https://rock-paper-scissors.game/referral"
                external
                onClick={() => {
                  setOpen(false);
                }}
              />

              {activeRoomId && !someoneElseGame && roomStatus !== RoomStatusEnum.Closed && (
                <MenuItem icon={CopyIcon} text="hash.show" external onClick={showHandler} />
              )}

              <li className="border-b py-[16px] md:py-[26px] border-border-menu flex gap-2">
                <MediaBtn
                  className="mt-[16px] bg-tg"
                  onClick={() => {
                    socialMediaClick(SOCIAL_MEDIA.TELEGRAM)();
                  }}
                >
                  <IconTelegram className="md:w-[30px] h-[24px] w-[25px]" />
                  <span className="hidden md:block">Telegram</span>
                </MediaBtn>
                <MediaBtn
                  className="mt-[16px]  bg-discord"
                  onClick={() => {
                    socialMediaClick(SOCIAL_MEDIA.DISCORD)();
                  }}
                >
                  <IconDiscord className="md:w-[33px] md:h-[24px] w-[25px]" />
                  <span className="hidden md:block">Discord</span>
                </MediaBtn>
              </li>

              {isConnected && (
                <MenuItem
                  icon={LogoutIcon}
                  text="common.disconnect"
                  external
                  last
                  onClick={() => {
                    setOpen(false);
                    disconnect();
                  }}
                />
              )}
            </ul>
          </div>
        </Transition.Child>
      </div>
    </Transition>,
    document.body,
  );
};
