import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetHistoryMoves } from 'modules/Room/Hooks/useGetHistoryMoves';
import MobileHistoryMove from 'modules/Room/mobile/MobileHistoryMoves/MobileHistoryMove';

import './MobileHistoryMoves.scss';

const MobileHistoryMoves = () => {
  const gameHistory = useGetHistoryMoves();
  const historyRef = useRef<HTMLDivElement>(null);
  const [visibleIndex, setVisibleIndex] = useState<number | null>(0);

  useEffect(() => {
    if (historyRef.current) {
      historyRef.current.scrollTop = 0;
    }
  }, []);

  const { activeRoomId } = useParams();

  useEffect(() => {
    if (!historyRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        let bottom = Infinity;
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio === 1) {
            if (bottom > entry.intersectionRect.bottom) {
              bottom = entry.intersectionRect.bottom;
              setVisibleIndex(Number(entry.target.getAttribute('data-index')));
            }
          }
        });
      },
      {
        root: historyRef.current,
        threshold: 1,
      },
    );

    const items = historyRef.current.querySelectorAll('.history-move');
    items.forEach((item) => observer.observe(item));

    return () => {
      items.forEach((item) => observer.unobserve(item));
    };
  }, [gameHistory]);

  if (!activeRoomId) return null;

  return (
    <div
      ref={historyRef}
      className="flex flex-col items-center w-full h-[100px] sm:h-[130px] overflow-auto history-wheel"
    >
      {gameHistory.map((round, index) => (
        <MobileHistoryMove
          key={round.encrMoveA + index}
          round={round}
          gameCount={gameHistory.length - index}
          lastRound={true}
          visible={visibleIndex === index}
          dataIndex={index}
        />
      ))}
      <div className={'history-move min-h-[45px] sm:min-h-[63px] w-full'} />
    </div>
  );
};

export default MobileHistoryMoves;
