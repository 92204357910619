import { PublicClient } from 'viem';

import contractAbi from 'shared/contract/contract.abi.json';
import { RoomInfo } from 'shared/types/app-types';

export const readRoomIdCounter = async (provider: PublicClient) => {
  try {
    const counter = await provider.readContract({
      address: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
      abi: contractAbi,
      functionName: 'roomIdCounter',
    });
    return counter as bigint;
  } catch (error) {
    console.error('Error reading roomIdCounter:', error);
    throw new Error('Failed to read roomIdCounter');
  }
};

export const readActiveRoomCounter = async (provider: PublicClient) => {
  try {
    const counter = await provider.readContract({
      address: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
      abi: contractAbi,
      functionName: 'activeRoomCounter',
    });
    return counter as bigint;
  } catch (error) {
    console.error('Error reading roomIdCounter:', error);
    throw new Error('Failed to read roomIdCounter');
  }
};

export const readGetRoomsInfo = async (provider: PublicClient, from: number, to: number) => {
  try {
    const rooms = await provider.readContract({
      address: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
      abi: contractAbi,
      functionName: 'getRoomsInfo',
      args: [from, to],
    });
    return rooms as RoomInfo[];
  } catch (error) {
    console.error('Error reading getRoomsInfo:', error);
    throw new Error('Failed to read getRoomsInfo');
  }
};
