import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { GameStatusEnum } from '../../game-status/constants/game-status-enum';
import { useGameStatusStore } from '../../game-status/store/game-status-store';
import classNames from 'classnames';
import { useAccount } from 'wagmi';

import MobileBank from 'modules/Room/mobile/MobileBank/MobileBank';
import MobileScore from 'modules/Room/mobile/MobileScore/MobileScore';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { Avatar } from 'shared/components/ui/Avatar/Avatar';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { RoomStatusEnum, WinnerStatusEnum } from 'shared/constants/shared-enums';
import { useRoomStore } from 'shared/store/roomStore';

import ZIcon from 'assets/animation/sleep/Z.svg?react';

import './MobileGameCard.scss';
import { Hearts } from './hearts';

interface Props {
  className?: string;
}

const MobileGameCard = ({ className }: Props) => {
  const { t } = useTranslation();
  const [showSleep, setShowSleep] = useState<boolean>(false);
  const [playerAWinner, setPlayerAWinner] = useState<boolean | null>(null);
  const { address } = useAccount();
  const { gameStatus } = useGameStatusStore();
  let sleepTimeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (
      gameStatus === GameStatusEnum.WAIT_OPPONENT_MOVE ||
      gameStatus === GameStatusEnum.WAIT_OPPONENT_REVEAL
    ) {
      sleepTimeoutRef.current = setTimeout(() => {
        setShowSleep(true);
      }, 40000);
    } else {
      clearTimeout(sleepTimeoutRef.current!);
      setShowSleep(false);
    }
  }, [gameStatus]);
  const {
    amIPlayerA,
    playerA,
    playerB,
    roomBalance,
    someoneElseGame,
    winnerStatus,
    roomStatus,
    winsTo,
  } = useRoomStore();
  const { activeRoomId } = useParams();
  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed) {
      if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerA
      ) {
        setPlayerAWinner(true);
      } else if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerB
      ) {
        setPlayerAWinner(false);
      }
    }
  }, [winnerStatus, roomStatus]);
  const classNamePlayerACard = someoneElseGame ? 'order-1' : amIPlayerA ? 'order-1' : 'order-3';
  const classNamePlayerBCard = someoneElseGame ? 'order-3' : amIPlayerA ? 'order-3' : 'order-1';
  const playerName = (playerAddress: `0x${string}`) => {
    if (someoneElseGame) {
      if (playerB.address === playerAddress) {
        return 'common.playerB';
      } else {
        return 'common.playerA';
      }
    } else if (playerAddress === address?.toLowerCase()) {
      return 'common.you';
    } else if (!activeRoomId && playerAddress === NULL_ADDRESS) {
      return 'common.noOpponent';
    } else if (playerAddress === NULL_ADDRESS) {
      return 'common.connecting';
    } else {
      return 'common.opponent';
    }
  };
  return (
    <div
      className={`${
        className ? className : ''
      } flex w-full py-[12px] px-[16px] items-center rounded-[4px] border border-border transition bg-opacity-30 bg-background justify-between  sm:justify-around relative mb-[20px]`}
    >
      <div className={`${classNamePlayerACard} flex flex-col items-center`}>
        <div
          className={classNames(
            ' flex gap-3 sm:gap-4 mb-2',
            classNamePlayerACard === 'order-3' ? 'transform scale-x-[-1]' : '',
          )}
        >
          <Hearts wins={Number(playerB.wins)} winsTo={Number(winsTo)} prefix={'a'} />
        </div>
        <div
          className={` ${
            playerAWinner && roomStatus === RoomStatusEnum.Closed
              ? 'border-yellow border-2'
              : 'background-dark-background'
          } relative border border-border rounded-[6px] flex flex-col items-center`}
        >
          <Avatar
            userAddress={playerA.address}
            className={`w-[64px]  h-[64px] sm:w-[110px] sm:h-[110px] rounded-t-[4px] rounded-b-none `}
          />
          <AnimatedTransition show={showSleep && !amIPlayerA}>
            <>
              <ZIcon className="zIconMobile absolute top-3 left-[-90%] sm:left-[-60%]" />
              <ZIcon className="zIconMobile absolute top-3 left-[-90%] sm:left-[-60%]" />
              <ZIcon className="zIconMobile absolute top-3 left-[-90%] sm:left-[-60%]" />
            </>
          </AnimatedTransition>
          <span className="text-gray text-[10px] sm:text-[16px] my-[1px]">
            {t(playerName(playerA.address))}
          </span>
        </div>
      </div>
      <div className="order-2">
        <MobileScore />
        <MobileBank />
      </div>
      <div className={`${classNamePlayerBCard} flex flex-col items-center`}>
        <div
          className={classNames(
            ' flex gap-3 sm:gap-4 mb-2',
            classNamePlayerBCard === 'order-3' ? 'transform scale-x-[-1]' : '',
          )}
        >
          <Hearts wins={Number(playerA.wins)} winsTo={Number(winsTo)} prefix={'b'} />
        </div>
        <div
          className={` ${
            !playerAWinner &&
            roomStatus === RoomStatusEnum.Closed &&
            playerB.address !== NULL_ADDRESS
              ? 'border-yellow border-2'
              : 'background-dark-background'
          } relative background-dark-background border border-border rounded-[6px] flex flex-col items-center`}
        >
          {activeRoomId && playerB.address !== NULL_ADDRESS ? (
            <Avatar
              userAddress={playerB.address}
              className={`w-[64px] h-[64px] rounded-t-[4px] rounded-b-none sm:w-[110px] sm:h-[110px] ${
                !playerAWinner && roomStatus === RoomStatusEnum.Closed ? 'border-yellow' : ''
              }`}
            />
          ) : (
            <p className="text-blue w-[64px] h-[64px] rounded-t-[4px] sm:w-[110px] sm:h-[110px] text-[46px] sm:text-[76px] border-b border-border bg-deep-blue text-center">
              ?
            </p>
          )}
          <span className="text-gray text-[10px] sm:text-[16px] my-[1px]">
            {t(playerName(playerB.address))}
          </span>
          <AnimatedTransition show={showSleep && amIPlayerA}>
            <>
              <ZIcon className="zIconMobile absolute top-3 left-[-90%] sm:left-[-60%]" />
              <ZIcon className="zIconMobile absolute top-3 left-[-90%] sm:left-[-60%]" />
              <ZIcon className="zIconMobile absolute top-3 left-[-90%] sm:left-[-60%]" />
            </>
          </AnimatedTransition>
        </div>
      </div>
    </div>
  );
};

export default MobileGameCard;
