import { useEffect, useRef } from 'react';

import { useNewRoomStore } from 'shared/store/room-store';
import { useStreamStore } from 'shared/store/stream-store';

import SceneManager from './core/SceneManager';
import Loading from './scenes/Loading';
import Main from './scenes/Main';

const PixiComponent = () => {
  const pixiContainer = useRef<HTMLDivElement | null>(null);
  const sceneManagerRef = useRef<SceneManager | null>(null);

  const { manageQueue, currentPhase, currentRound, queue } = useStreamStore();
  const { /* startStopRoom,*/ room, rounds /* , initialLoading, stopPolling  */ } =
    useNewRoomStore();

  useEffect(() => {
    const sceneManager = new SceneManager();

    const initializeApp = async () => {
      const canvas = document.createElement('canvas');
      canvas.style.width = '100%';
      canvas.style.height = '100%';

      if (pixiContainer.current) {
        while (pixiContainer.current.firstChild) {
          pixiContainer.current.removeChild(pixiContainer.current.firstChild);
        }
        pixiContainer.current.appendChild(canvas);
      }
      const { width, height } = pixiContainer.current!.getBoundingClientRect();

      await sceneManager.init({
        canvas,
        resizeTo: pixiContainer.current as HTMLElement,
        powerPreference: 'high-performance',
        resolution: window.devicePixelRatio || 1,
        background: '#000000',
        backgroundAlpha: 0,
        clearBeforeRender: true,
        width,
        height,
        antialias: false,
        hello: true,
      });
      await sceneManager.switchScene(Loading);
      await sceneManager.switchScene(Main);

      sceneManagerRef.current = sceneManager;
      sceneManager.triggerResize(width, height);
    };

    initializeApp().catch((error) => {
      console.error('Failed to initialize PixiJS app:', error);
    });

    return () => {
      if (sceneManagerRef.current && sceneManagerRef.current.app) {
        sceneManagerRef.current.app.destroy();
        sceneManagerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    manageQueue({ phase: currentPhase, round: currentRound });
    if (sceneManagerRef.current) {
      const mainScene = sceneManagerRef.current.currentScene;
      if (mainScene instanceof Main) {
        mainScene.updatePhase(currentPhase, currentRound, rounds, room, queue);
      }
    }
  }, [currentPhase, currentRound, manageQueue]);

  return <div ref={pixiContainer} style={{ width: '100%', height: '100%' }} />;
};

export default PixiComponent;
