import Scene from '../core/Scene';
import { Debug } from '../utils/debug';
import { centerObjectsByWindow } from '../utils/misc';
import { Sprite } from 'pixi.js';

export default class Loading extends Scene {
  loading: Sprite | undefined;

  async load() {
    await this.utils.assetLoader.loadAssetsGroup('loading');

    this.loading = Sprite.from('loading');

    this.loading.alpha = 0.5;
    this.loading.scale.set(0.5);

    centerObjectsByWindow(this.loading);

    this.addChild(this.loading);
  }

  async start() {
    await this.utils.assetLoader.loadAssetsGroup('game');
    await this.utils.assetLoader
      .loadSpineAssetsGroup('game', (progress: number) => {
        if (progress === 0 || progress >= 99)
          Debug.log(`loadSpineAssetsGroup Loading progress = ${progress * 100}%`);
      })
      .catch((error) => {
        Debug.error('Failed to load Spine assets:', error);
      });
  }
}
