import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  svg: React.ReactNode;
  active: boolean;
  className?: string;
  labelYellow?: string;
  labelWhite?: string;
}

const MainPageNetworkBtn = ({ labelYellow, labelWhite, active, svg, className }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <button
        disabled
        className={`${active ? 'shadow-sequence ' : ''} ${
          className ? className : ''
        } mb-[8px] border-white grid place-items-center border rounded-[10px] sm:rounded-[20px] bg-gradient-to-b from-violet to-[#172178] w-[54px] sm:w-[128px] h-[54px]  sm:h-[128px]`}
      >
        {svg}
      </button>
      <p className="text-yellow font-bold text-[12px] w-[54px] sm:w-full sm:text-[24px] text-center sm:whitespace-nowrap">
        {t(labelYellow!)} <span className="text-white">{t(labelWhite!)}</span>
      </p>
    </div>
  );
};

export default MainPageNetworkBtn;
