import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'wagmi';

import { networkHandler } from 'shared/utils/networkHandler';

import LinkSvg from 'assets/link.svg?react';

import './LoaderHoc.scss';

interface Props {
  messages?: string[];
  hash?: `0x${string}` | null;
  className?: string;
  children: React.ReactNode;
  selector?: string; // Add selector prop
}

const LoaderHoc = ({ messages, className, children, hash, selector }: Props) => {
  const { chainId } = useAccount();
  const { t } = useTranslation();
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (messages && messages.length > 0) {
      interval = setInterval(() => {
        setCurrentMessage((prevMessage) => (prevMessage + 1) % messages.length);
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [messages]);

  const content = (
    <div
      className={`flex relative justify-center items-center w-[300px]  sm:w-[400px]  ${
        className ? className : ''
      }`}
    >
      {children}
      {messages && messages.length >= 0 && (
        <div className="absolute w-[90%]" style={{ top: 'calc(100% + 10px)' }}>
          <div className="h-[2px] bg-loader-border" />
          <div className="bg-loader-content text-center text-wrap flex flex-col items-center justify-center sm:py-[16px] py-[8px] px-[20px]">
            {hash && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-white flex justify-center text-[12px] sm:text-[16px]"
                href={`${networkHandler(chainId!)}/${hash}`}
              >
                <LinkSvg className="h-[18px] w-[16px] sm:h-[22px] sm:w-[22px] mr-[10px] text-white" />{' '}
                {t('common.transaction')}...
                {hash!.slice(-4)}
              </a>
            )}
            <span className="text-white text-[12px] sm:text-[16px]">
              {t(messages[currentMessage])}
            </span>
          </div>
          <div className="h-[2px] bg-loader-border" />
        </div>
      )}
    </div>
  );

  if (selector) {
    const portalRoot = document.querySelector(selector);
    if (portalRoot) {
      return ReactDOM.createPortal(content, portalRoot);
    }
  }

  return content;
};

export default LoaderHoc;
