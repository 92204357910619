import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { useReveal } from 'modules/Room/Hooks/useReveal';
import { GameStatusEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import { MovesEnum, RoomStatusEnum, RoundStagesEnum } from 'shared/constants/shared-enums';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { useStartGameStore } from 'shared/store/start-game-store';

const useRevealLogic = () => {
  const { playMoves } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { roundStage, roundCounter, roomStatus, playerA, roundMoves, roomExpire } = useRoomStore();
  const { address, chainId } = useAccount();
  const { revealGameStatus } = useGameStatusStore();
  const { writeReveal } = useReveal();
  const amIPlayerA = playerA?.address.toLowerCase() === address?.toLowerCase();
  useEffect(() => {
    const isReadyForReveal =
      roundStage === RoundStagesEnum.RevealMove && roomStatus === RoomStatusEnum.ReadyForReveal;
    const isPlayerMoveNone =
      ((amIPlayerA && roundMoves.playerA.move === MovesEnum.None) ||
        (!amIPlayerA && roundMoves.playerB.move === MovesEnum.None)) &&
      playMoves[activeRoomId! + address + chainId + roundCounter];
    const isRevealModalError = revealGameStatus.error;
    const isReadyForRevealCondition =
      (isReadyForReveal && isPlayerMoveNone) ||
      (isReadyForReveal && isRevealModalError && isPlayerMoveNone);
    if (
      isReadyForRevealCondition &&
      !playMoves[activeRoomId! + address + chainId + roundCounter]?.revealHash?.hash &&
      !roomExpire
    ) {
      setTimeout(
        () => {
          console.log('call reveal');
          writeReveal();
          useGameStatusStore.getState().setGameStatus(GameStatusEnum.WAIT_YOUR_REVEAL);
        },
        isMobile ? 4000 : 0,
      );
    }
  }, [roundStage, playMoves, address, roundCounter, revealGameStatus]);
};

export default useRevealLogic;
