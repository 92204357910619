import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { NULL_ADDRESS } from 'shared/constants/constants';

interface State {
  isLoading: boolean;
  invitedBy: `0x${string}`;
  notFirstTime: boolean;
  activeGames: Record<string, string>;
  isMobileBannerClosed: boolean;
  jwt: Record<`0x${string}`, string>;
  jwtValidUntil: Record<`0x${string}`, number>;
}

interface Actions {
  setIsLoading: (flag: boolean) => void;
  setInvited: (arg0: `0x${string}`) => void;
  setNotFirstTime: (flag: boolean) => void;
  addActiveGame: (key: string, id: string) => void;
  removeActiveGame: (key: string) => void;
  setIsMobileBannerClosed: (flag: boolean) => void;
  setJwt: ({ address, jwt }: { address: `0x${string}` | undefined; jwt: string }) => void;
  setJwtValidUntil: ({
    address,
    jwtValidUntil,
  }: {
    address: `0x${string}` | undefined;
    jwtValidUntil: number;
  }) => void;
}

const initialState: State = {
  isLoading: false,
  invitedBy: NULL_ADDRESS,
  notFirstTime: false,
  activeGames: {},
  isMobileBannerClosed: false,
  jwt: {},
  jwtValidUntil: {},
};

export const useGlobalStore = create<State & Actions>()(
  persist(
    immer((set) => ({
      ...initialState,
      setIsLoading: (newFlag) =>
        set(() => ({
          isLoading: newFlag,
        })),
      setInvited: (address) =>
        set(() => ({
          invitedBy: address,
        })),
      setNotFirstTime: (flag) =>
        set(() => ({
          notFirstTime: flag,
        })),
      addActiveGame: (key, id) =>
        set((state) => ({
          activeGames: {
            ...state.activeGames,
            [key]: id,
          },
        })),
      setIsMobileBannerClosed: (flag) =>
        set(() => ({
          isMobileBannerClosed: flag,
        })),
      removeActiveGame: (key) =>
        set((state) => {
          const { [key]: _, ...rest } = state.activeGames;
          return { activeGames: rest };
        }),
      setJwt: ({ address, jwt }) =>
        set((state) => ({
          jwt: { ...state.jwt, [address!]: jwt },
        })),
      setJwtValidUntil: ({ address, jwtValidUntil }) =>
        set((state) => ({
          jwtValidUntil: { ...state.jwtValidUntil, [address!]: jwtValidUntil },
        })),
    })),
    { name: 'globalStore' },
  ),
);
