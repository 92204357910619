import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';
import { useAccount } from 'wagmi';

import { BurgerMenu } from 'shared/components/sections/burger-menu';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import SecondaryBtn from 'shared/components/ui/SecondaryBtn/SecondaryBtn';
import { ROUTES } from 'shared/constants';

import ArrowLeftIcon from 'assets/arrow-left.svg?react';
import ControllerIcon from 'assets/controller-brown.svg?react';
import logo from 'assets/png/logo.png';

interface HeaderProps {
  title?: React.ReactNode;
  className?: string;
}

export const Header = ({ title, className }: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isConnected } = useAccount();

  const handleRedirectToHome = () => {
    window.open('https://rock-paper-scissors.game/', 'target_blank');
  };

  const handleRedirectToLobby = () => {
    navigate(ROUTES.LOBBY);
  };
  const handleRedirectToExplore = () => {
    navigate(ROUTES.EXPLORE_GAMES);
  };
  const location = useLocation();
  return (
    <header
      className={classNames(
        'flex justify-between md:grid md:grid-cols-3 w-full items-center',
        className,
      )}
    >
      <div className="flex items-center gap-[24px]">
        <button onClick={handleRedirectToHome}>
          <img
            className="w-[85px] lg:w-[94px] cursor-pointer"
            src={logo}
            alt={'rock paper scissors logo'}
          />
        </button>

        <div className="w-[3px] bg-[#272D63] h-[56px] hidden lg:flex" />

        {location.pathname.startsWith(ROUTES.STREAM) && (
          <SecondaryBtn
            text="Back to streams"
            svg={<ArrowLeftIcon className="w-5 h-5 sm:w-6 sm:h-6" />}
            onClick={handleRedirectToExplore}
            className="hidden lg:block"
          />
        )}
      </div>
      <div className="gradient-title text-[16px] sm:text-[30px] lg:text-[40px] font-bold flex justify-center items-center gap-[8px]">
        {title}
      </div>

      <div className="flex justify-end items-center gap-[24px]">
        {isConnected &&
          location.pathname !== ROUTES.LOBBY &&
          !location.pathname.startsWith(ROUTES.ROOM) && (
            <PrimaryButton
              onClick={handleRedirectToLobby}
              text="pages.liveGamesSection.create"
              svg={<ControllerIcon className="w-5 h-5 sm:w-6 sm:h-6" />}
              isAnimated
              className="hidden lg:block"
            />
          )}
        <div className="w-[3px] bg-[#272D63] h-[56px] hidden lg:flex" />
        {isConnected && <BurgerMenu />}
      </div>
    </header>
  );
};
