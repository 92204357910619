import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { CardPlayerEnum, FinishStateEnum } from 'modules/Room/constants/enums';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { ROUTES } from 'shared/constants';
import { RoomStatusEnum, WinnerStatusEnum } from 'shared/constants/shared-enums';
import { useGlobalStore } from 'shared/store/globalStore';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { useTimersStore } from 'shared/store/timersStore';

import { stateMapping } from './stetMapping';

interface GameFinishViewsProps {
  player?: CardPlayerEnum;
}

const GameFinishViews = ({ player }: GameFinishViewsProps) => {
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const { roomStatus, winnerStatus, amIPlayerA } = useRoomStore();
  const { removeActiveGame } = useGlobalStore();
  const { t } = useTranslation();
  const [viewState, setViewState] = useState<FinishStateEnum | null>(null);
  const navigate = useNavigate();
  const { resetGameStatus } = useGameStatusStore();
  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed) {
      resetGameStatus();
    }
  }, [roomStatus]);

  useEffect(() => {
    if (winnerStatus && roomStatus === RoomStatusEnum.Closed && activeRoomId) {
      switch (winnerStatus) {
        case WinnerStatusEnum.WinnerPlayerA:
          setViewState(amIPlayerA ? FinishStateEnum.YouWin : FinishStateEnum.YouLost);
          break;
        case WinnerStatusEnum.WinnerPlayerB:
          setViewState(amIPlayerA ? FinishStateEnum.YouLost : FinishStateEnum.YouWin);
          break;
        case WinnerStatusEnum.TechnicalWinnerA:
          setViewState(
            amIPlayerA ? FinishStateEnum.YouTechnicallyWin : FinishStateEnum.YouTechnicallyLost,
          );
          break;
        case WinnerStatusEnum.TechnicalWinnerB:
          setViewState(
            amIPlayerA ? FinishStateEnum.YouTechnicallyLost : FinishStateEnum.YouTechnicallyWin,
          );
          break;
        case WinnerStatusEnum.PlayerAExitRoom:
          setViewState(FinishStateEnum.YouExitRoom);
          break;
        case WinnerStatusEnum.UnusedRoomClosed:
          setViewState(FinishStateEnum.NoOneWin);
          break;
        default:
          setViewState(null);
      }
    }
  }, [winnerStatus, roomStatus, activeRoomId, amIPlayerA]);

  const startNewGameHandler = () => {
    removeActiveGame(`${chainId}${address?.toLowerCase()}`);
    useRoomStore.getState()?.resetStore();
    navigate(ROUTES.LOBBY);
  };

  const textGenerator = () => {
    if (!viewState) return null;

    const content = stateMapping[viewState];
    return (
      <div className="mb-[20px]">
        {viewState === FinishStateEnum.YouWin ? (
          <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
            {t('gameFinishViews.youWin')}
          </p>
        ) : null}

        {viewState === FinishStateEnum.YouLost ? (
          <p className="text-[14px] sm:text-[24px] mb-[10px] text-red font-bold">
            {t('gameFinishViews.youLose')}
          </p>
        ) : null}

        {content.texts.map((text, index) => (
          <p
            key={text}
            className={`text-white text-[12px] sm:text-[17px] text-justify ${
              index !== content.texts.length - 1 ? 'mb-[20px]' : ''
            }`}
          >
            {t(text)}
          </p>
        ))}
      </div>
    );
  };

  if (!viewState || player === CardPlayerEnum.Opponent || player === CardPlayerEnum.Connect)
    return null;

  return (
    <div className=" w-full">
      {textGenerator()}
      <div className="flex justify-center">
        <PrimaryButton text={t('common.selectNewBet')} onClick={startNewGameHandler} />
      </div>
    </div>
  );
};

export default GameFinishViews;
