import React from 'react';

import SpinnerIcon from 'assets/spinner.svg?react';

interface SpinnerProps {
  className?: string;
}

export const Spinner = ({ className }: SpinnerProps) => {
  return <SpinnerIcon className={`${className ?? ''} animate-spin`} />;
};
