import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'wagmi';

import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { useRoomStore } from 'shared/store/roomStore';

import './Score.scss';

const Score = () => {
  const { t } = useTranslation();
  const { amIPlayerA, playerA, playerB, someoneElseGame, roomStatus } = useRoomStore();
  const classNamePlayerAScore = `  ${
    someoneElseGame ? 'order-1' : amIPlayerA ? 'order-1' : 'order-3'
  }`;
  const classNamePlayerBScore = ` ${
    someoneElseGame ? 'order-3' : amIPlayerA ? 'order-3' : 'order-1'
  }`;
  return (
    <div className="flex flex-col items-center">
      <h4 className="text-blue-text font-xl font-medium mb-1">{t('common.score')}</h4>
      {someoneElseGame && (
        <h5
          className={`${
            roomStatus === RoomStatusEnum.Closed ? 'text-gray' : 'text-green'
          } font-xl font-medium mb-1`}
        >
          {roomStatus === RoomStatusEnum.Closed ? t('common.finished') : t('common.active')}
        </h5>
      )}
      <div className="score bg-black bg-opacity-30 flex justify-around border border-border rounded-[11px] w-[70%] mb-2">
        <div className="score__text text-[44px] text-white font-medium w-[100px] flex justify-between ">
          <span className={`${classNamePlayerAScore} `}>{`${playerA.wins}`}</span>
          <span className="order-2">:</span>
          <span className={`${classNamePlayerBScore}`}>{`${playerB.wins}`}</span>
        </div>
      </div>
    </div>
  );
};

export default Score;
