import { PublicClient } from 'viem';

import { MovesEnum, RoundStagesEnum, RoundWinnerOutcomes } from 'shared/constants/shared-enums';
import contractAbi from 'shared/contract/contract.abi.json';

interface ReadParams {
  provider: PublicClient;
  roomId: bigint;
}

interface RspUniqRoomData {
  roomId: bigint;
  playerA: `0x${string}`;
  playerB: `0x${string}`;
  wins: number;
  token: `0x${string}`;
  bet: bigint;
}

interface RspPlayerWinCounter {
  winsPlayerA: number;
  winsPlayerB: number;
}

export type RspRoomTuple = [
  RspUniqRoomData, // roomData, index 0
  bigint, // pot, index 1
  number, // roundCounter, index 2
  RspPlayerWinCounter, // winsCounter, index 3
  number, // closer (enum Rsp.Closer), index 4
  bigint, // deadline, index 5
  number, // status (enum Rsp.RoomStatus), index 6 CHECK IF POSSIBLE USE ENUMS HERE
  number, // winnerStatus (enum Rsp.WinnerStatus), index 7 CHECK IF POSSIBLE USE ENUMS HERE
];

export const readRoom = async ({ provider, roomId }: ReadParams): Promise<RspRoomTuple> => {
  try {
    const room = await provider.readContract({
      address: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
      abi: contractAbi,
      functionName: 'rooms',
      args: [BigInt(roomId)],
    });
    return room as RspRoomTuple;
  } catch (error) {
    console.error('Error reading room:', error);
    throw new Error('Failed to read room');
  }
};

interface RspRoundData {
  moveA: MovesEnum;
  moveB: MovesEnum;
  encrMoveA: `0x${string}`;
  encrMoveB: `0x${string}`;
  winner: RoundWinnerOutcomes;
  stage: number; //  use enums WHAT ENUM SHOULD BE HERE?
  roundStage: RoundStagesEnum;
}

export const readAllRoomRounds = async ({
  provider,
  roomId,
}: ReadParams): Promise<RspRoundData> => {
  try {
    const rounds = await provider.readContract({
      address: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
      abi: contractAbi,
      functionName: 'getAllRoomRounds',
      args: [BigInt(roomId)],
    });
    // console.log('getAllRoomRounds:', rounds);
    return rounds as RspRoundData;
  } catch (error) {
    console.error('Error reading room:', error);
    throw new Error('Failed to read room');
  }
};
