import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'wagmi';

import { ChainsSwitcher } from 'modules/ConnectPage/components';
import PlaySequence from 'modules/ConnectPage/components/PlaySequence/PlaySequence';
import { Connect } from 'modules/ConnectPage/components/connect-section/CustomWalletButton';
import { isRedirectAvailable } from 'modules/ConnectPage/helpers/isRedirectAvailable';

import NotWorkingLocalStorageModal from 'shared/components/sections/NotWorkingLocalStorageModal/NotWorkingLocalStorageModal';
import { Header } from 'shared/components/sections/header/header';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import GridBackground from 'shared/components/ui/GridBackground/GridBackground';
import useLocalStorageCheck from 'shared/hooks/useLocalStorageCheck';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useGlobalStore } from 'shared/store/globalStore';
import { useRoomStore } from 'shared/store/roomStore';

import CloseXIcon from 'assets/closeX.svg?react';

import './ConnectPage.scss';

const MobileConnectPage = () => {
  const isLocalStorageAvailable = useLocalStorageCheck();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { isConnected, address } = useAccount();
  const { t } = useTranslation();
  const { setPlayerAAddress } = useRoomStore();

  const { isMobileBannerClosed, setIsMobileBannerClosed } = useGlobalStore();

  useEffect(() => {
    if (isConnected) setPlayerAAddress(address?.toLowerCase() as `0x${string}`);
  }, [isConnected]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };
  const menuRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [menuOpen]);

  const isCustomConnectPossible = isRedirectAvailable(window.navigator);

  const handleCloseBanner = () => {
    setIsMobileBannerClosed(true);
  };

  const isBannerShown =
    isCustomConnectPossible && useMediaQuery('(max-width: 768px)') && !isMobileBannerClosed;

  return (
    <GridBackground className="flex items-center flex-col h-screen overflow-auto px-4 pt-[14px] ">
      {isBannerShown && (
        <div className="text-white bg-yellow font-bold text-xs p-2 [&>svg]:w-[24px] [&>svg]:h-[24px] [&>svg]:cursor-pointer flex justify-between items-center absolute top-0 w-full">
          <div className="w-4/5">{t('common.banner')}</div>
          <CloseXIcon onClick={handleCloseBanner} />
        </div>
      )}
      <Header
        className={`mb-[32px]  
        ${isBannerShown ? 'mt-[64px]' : ''}
        `}
      />
      <div className="flex flex-col h-full justify-center">
        <div className="w-full max-w-[754px] xlg:ml-auto border border-border p-3 rounded-[16px] bg-one-more-blue flex flex-col  mb-5">
          <p className="font-black text-[20px] text-yellow-text uppercase">
            {isConnected ? t('common.choose') : t('common.install')}{' '}
            <span className="text-white ">
              {isConnected ? t('common.theNetwork') : t('common.aWallet')}
            </span>
          </p>
          <p className="text-simple-text text-[10px] leading-[140%] ">{t('common.gameWeb3')}</p>
        </div>
        <AnimatedTransition show={!isConnected} asFragment>
          <Connect />
        </AnimatedTransition>
        <AnimatedTransition show={isConnected} asFragment>
          <ChainsSwitcher />
        </AnimatedTransition>

        <AnimatedTransition show={!isLocalStorageAvailable}>
          <NotWorkingLocalStorageModal open={!isLocalStorageAvailable} />
        </AnimatedTransition>
      </div>
    </GridBackground>
  );
};

export default MobileConnectPage;
