import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAccount, usePublicClient } from 'wagmi';

import { NoTableImage } from 'modules/lobby-page/components/live-games-section/no-table-image';

import Dropdown from 'shared/components/ui/DropDown/DropDown';
import { SearchBar } from 'shared/components/ui/SearchBar/SearchBar';
import { Badge } from 'shared/components/ui/badge/badge';
import { ROUTES } from 'shared/constants';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useGamesStore } from 'shared/store/games-store';
import { RoomInfo } from 'shared/types/app-types';

import ArrowIcon from 'assets/arrow.svg?react';
import ClockSmallIcon from 'assets/clock-small.svg?react';
import ClockIcon from 'assets/clock.svg?react';
import CurrencySmallIcon from 'assets/currency-small.svg?react';
import CurrencyIcon from 'assets/currency.svg?react';
import EyeIcon from 'assets/eye.svg?react';
import FilterSmallIcon from 'assets/filter-small.svg?react';
import FilterIcon from 'assets/filter.svg?react';
import PersonIcon from 'assets/person.svg?react';

import { LiveGameItem } from './live-game-item';

// todo all filters and search
// todo as in design
// todo check after change chain in room somehow store returns games from last chain! same for mobile

interface SortingConfig {
  key: 'roomId' | 'amount';
  order: 'asc' | 'desc';
}

const tableTitleStyles =
  'text-[#F7F7F780] text-[9px] sm:text-[20px] font-medium flex gap-2 items-center';
const tableSubTitleStyles = 'text-[#F7F7F7BF] text-[10px] sm:text-[20px] font-semibold';
const tableColumnStyles = 'flex flex-col';
const sectionStyles =
  'relative overflow-hidden z-10 w-full bg-[linear-gradient(0deg,#080540,#080540),radial-gradient(50%_50%_at_50%_50%,rgba(0,11,255,0.3)_0%,rgba(1,0,29,0.3)_100%)] border-[1px] lg:border-[3px] border-border rounded-[8px] lg:rounded-[16px] p-[10px] px-3 xlg:px-[24px] lg:py-[20px] mt-[12px]';
const sectionContainerStyles = 'grid grid-cols-12 lg:gap-8 relative';
const sectionCurrencyFilterStyles =
  'text-white/75 flex gap-2 items-center text-[10px] sm:text-[20px] relative';
const sectionRoomsListStyles =
  'flex flex-col gap-2 sm:gap-4 h-[300px] h-[492px] overflow-scroll mt-[8px] lg:mt-[20px] no-scrollbar overflow-x-hidden relative';
const sectionTitleStyles = 'font-bold text-[20px] lg:text-[52px] gradient-title';

interface LiveGamesSectionProps {
  joinRoomHandler: (roomId: number) => void;
}

export const LiveGamesSection = ({ joinRoomHandler }: LiveGamesSectionProps) => {
  const [currencyFilter, setCurrencyFilter] = useState<string>('All');
  const [isCurrencyFilterOpen, setIsCurrencyFilterOpen] = useState(false);
  const [myGames, setMyGames] = useState<RoomInfo[]>([]);
  const [sortingConfig, setSortingConfig] = useState<SortingConfig>({
    key: 'roomId',
    order: 'desc',
  });
  const [searchValue, setSearchValue] = useState('');
  const [noSearchResults, setNoSearchResults] = useState(false);
  const publicClient = usePublicClient();
  let { chainId, address } = useAccount();
  let { startStore, activeSingle, activeRooms, clearStore } = useGamesStore();
  useEffect(() => {
    if (publicClient) {
      clearStore();
      startStore(publicClient);
    }
    return () => {
      clearStore();
    };
  }, [publicClient, chainId]);
  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  const { t } = useTranslation();
  const { availableCurrencies, currencyForSelect } = useCurrenciesStore();
  const isTablet = useMediaQuery('(min-width: 768px)');

  const handleToggleCurrencyFilter = () => {
    setIsCurrencyFilterOpen(!isCurrencyFilterOpen);
  };

  const handleChangeCurrencyFilter = (value: string) => {
    setCurrencyFilter(value);
    handleToggleCurrencyFilter();
  };

  const handleSort = (key: 'roomId' | 'amount') => {
    setSortingConfig((prevConfig) => ({
      key,
      order: prevConfig.key === key && prevConfig.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const renderGamesList = () => {
    let sortedRooms = [...activeSingle].sort((a, b) => {
      if (a.data.playerA?.toLowerCase() === address?.toLowerCase()) return -1;
      if (b.data.playerA?.toLowerCase() === address?.toLowerCase()) return 1;
      return 0;
    });
    if (currencyFilter !== 'All') {
      sortedRooms = sortedRooms.filter(
        (room) =>
          room.data.token.toLowerCase() ===
          availableCurrencies
            .find((currency) => currency.symbol === currencyFilter)
            ?.address.toLowerCase(),
      );
    }
    return sortedRooms.map((room, index) => (
      <LiveGameItem
        key={`${room.data.roomId.toString()}_${room.data.playerA}`}
        roomId={room.data.roomId}
        address={room.data.playerA as `0x${string}`}
        amount={Number(room.data.bet)}
        currency={availableCurrencies.find(
          (currency) => currency.address.toLowerCase() === room.data.token.toLowerCase(),
        )}
        ourGame={room.data.playerA?.toLowerCase() === address?.toLowerCase()}
        joinRoomHandler={joinRoomHandler}
      />
    ));
  };

  useEffect(() => {
    if (activeRooms.length) {
      let sortedRooms = activeRooms.filter(
        (room) =>
          room.data.playerA?.toLowerCase() === address?.toLowerCase() ||
          room.data.playerB?.toLowerCase() === address?.toLowerCase(),
      );
      setMyGames(sortedRooms);
    }
  }, [activeRooms]);

  const renderMyGamesList = () => {
    if (myGames.length === 0) return null;
    return myGames
      .filter((room) => {
        if (currencyFilter === `All`) return true;
        return (
          room.data.token.toLowerCase() ===
          availableCurrencies
            .find((currency) => currency.symbol === currencyFilter)
            ?.address.toLowerCase()
        );
      })
      .map((room, index) => (
        <LiveGameItem
          key={`${room.data.roomId.toString()}_${room.data.playerA}`}
          roomId={room.data.roomId}
          address={room.data.playerA as `0x${string}`}
          amount={Number(room.data.bet)}
          currency={availableCurrencies.find(
            (currency) => currency.address.toLowerCase() === room.data.token.toLowerCase(),
          )}
          ourGame
        />
      ));
  };

  const navigate = useNavigate();
  return (
    <div className="w-full xlg:max-w-[1184px] relative z-10">
      <div className="flex lg:items-center gap-2 lg:gap-6 flex-col lg:flex-row">
        <div className={sectionTitleStyles}>{t('pages.liveGamesSection.title')}</div>
        {/*{!!activeSingle?.length && <SearchBar setSearch={setSearchValue} />}*/}
        <Badge
          text="lobby.watchGames"
          icon={<EyeIcon />}
          onClick={() => navigate(ROUTES.EXPLORE_GAMES)}
        />
      </div>

      <div className={sectionStyles}>
        {(activeSingle.length === 0 && myGames.length === 0) || noSearchResults ? (
          <NoTableImage noSearchResults={noSearchResults} />
        ) : (
          <div className={sectionContainerStyles}>
            <div className={`${tableColumnStyles} col-span-5 lg:col-span-4`}>
              <span
                className={`${tableTitleStyles} cursor-pointer`}
                onClick={() => handleSort('roomId')}
              >
                {t('pages.liveGamesSection.createdBy')}
                <PersonIcon />
              </span>
              <span className={tableSubTitleStyles}>{t('pages.liveGamesSection.roomId')}</span>
            </div>
            {/* <div className={`${tableColumnStyles} col-span-2`}>
              <span className={`${tableTitleStyles} cursor-pointer`} onClick={() => handleSort('roomId')}>Date created <ClockIcon /></span>
              <span className={tableSubTitleStyles}>
                {sortingConfig.key === 'roomId' ?
                (sortingConfig.order === 'asc' ? 'Oldest Games' : 'Newest Games')
                : 'Newest Games'}
              </span>
            </div> */}
            {/*<div className={`${tableColumnStyles} col-span-3 lg:col-span-2`}>*/}
            {/*  <span*/}
            {/*    className={`${tableTitleStyles} cursor-pointer`}*/}
            {/*    onClick={() => handleSort('amount')}*/}
            {/*  >*/}
            {/*    {t('pages.liveGamesSection.bet')}*/}
            {/*    {isTablet ? <FilterIcon /> : <FilterSmallIcon />}*/}
            {/*  </span>*/}
            {/*  <span className={tableSubTitleStyles}>*/}
            {/*    {sortingConfig.key === 'amount'*/}
            {/*      ? sortingConfig.order === 'asc'*/}
            {/*        ? t('pages.liveGamesSection.lowToHigh')*/}
            {/*        : t('pages.liveGamesSection.lowToHigh')*/}
            {/*      : t('pages.liveGamesSection.lowToHigh')}*/}
            {/*  </span>*/}
            {/*</div>*/}
            <div className={`${tableColumnStyles} col-span-3`}>
              <span className={tableTitleStyles}>
                {t('pages.liveGamesSection.currency')}
                {isTablet ? <CurrencyIcon /> : <CurrencySmallIcon />}
              </span>
              <div onClick={handleToggleCurrencyFilter} className={sectionCurrencyFilterStyles}>
                {currencyFilter}
                <ArrowIcon
                  className={`${
                    isCurrencyFilterOpen ? 'transform rotate-180' : ''
                  } transition ease-in-out duration-500`}
                />

                <Dropdown
                  open={isCurrencyFilterOpen}
                  list={[{ value: 'All', label: 'All' }, ...currencyForSelect]}
                  onClick={(option) => handleChangeCurrencyFilter(option.label)}
                  active={currencyFilter}
                  position={{ top: 30, left: 0 }}
                />
              </div>
            </div>
          </div>
        )}

        <div className={sectionRoomsListStyles}>
          {renderMyGamesList()}
          {renderGamesList()}
        </div>
      </div>
    </div>
  );
};
