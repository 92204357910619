import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import blick from 'assets/animation/buttons/blick.png';
import glare from 'assets/animation/buttons/glare.png';
import LoadingSpinner from 'assets/animation/buttons/spinner-brown.svg?react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  svg?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  innerClassName?: string;
  isAnimated?: boolean;
  buttonType?: 'primary' | 'return' | 'accept';
}

const buttonOptions = {
  primary: {
    buttonStyles: 'bg-primary-border',
    innerWrapperStyles:
      'bg-primary-btn shadow-primary-btn text-[#6C3C09] hover:bg-primary-btn-hover active:shadow-primary-btn-active',
    innerLoadingStyles: 'bg-primary-btn shadow-primary-btn text-[#6C3C09]',
  },
  return: {
    buttonStyles: 'bg-return-border',
    innerWrapperStyles:
      'bg-return-btn shadow-return-btn text-[#182948] active:shadow-return-btn-active',
    innerLoadingStyles: 'bg-return-btn shadow-return-btn text-[#182948]',
  },
  accept: {
    buttonStyles: 'bg-accept-border',
    innerWrapperStyles:
      'bg-accept-btn shadow-accept-btn text-[#184839] active:shadow-accept-btn-active',
    innerLoadingStyles: 'bg-accept-btn shadow-accept-btn text-[#184839]',
  },
};

const PrimaryButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    className,
    innerClassName,
    text,
    svg,
    onClick,
    disabled,
    isLoading,
    isAnimated = false,
    buttonType = 'primary',
    ...rest
  } = props;
  const { t } = useTranslation();
  return isLoading ? (
    <button
      ref={ref}
      className={`rounded-xl outline-none p-0.5 ${className ? className : ''} ${
        disabled ? 'bg-[#322E66]' : buttonOptions[buttonType].buttonStyles
      }`}
      {...rest}
    >
      <div
        className={`relative overflow-hidden rounded-[10px] inset-0 p-2.5 2xl:py-2.5 2xl:px-5 font-semibold text-[22px] ${buttonOptions[buttonType].innerLoadingStyles}`}
      >
        <div className={`flex items-center justify-center gap-1`}>
          <LoadingSpinner className="animate-spin w-6 h-6 my-1" />
        </div>
        {!disabled && (
          <>
            <img
              src={blick}
              alt="wide rectangle"
              className="absolute top-[-30px] left-[-10%] transition-all duration-300 ease-in-out"
            />
            <img
              src={glare}
              alt="glare effect"
              className={`absolute top-[-40px] left-[-100%] transition-all duration-500 ease-in-out group-hover:left-[100%] ${
                isAnimated && !isHovered ? 'animate-glare' : ''
              }`}
            />
          </>
        )}
      </div>
    </button>
  ) : (
    <button
      ref={ref}
      className={`rounded-xl outline-none p-0.5 ${className ? className : ''} ${
        disabled ? 'bg-[#322E66]' : buttonOptions[buttonType].buttonStyles
      }`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
      {...rest}
    >
      <div
        className={`group relative z-10 overflow-hidden rounded-[10px] h-full flex items-center justify-center inset-0 p-2.5 2xl:py-2.5 2xl:px-5 font-semibold text-[22px] ${
          disabled
            ? 'bg-[#100E34] shadow-primary-btn-disabled text-[#322E66]'
            : `${buttonOptions[buttonType].innerWrapperStyles} transition-all ease-in-out duration-300`
        } ${innerClassName ? innerClassName : ''}`}
      >
        <div
          className={`flex items-center relative z-40 justify-center gap-1 text-[14px] sm:text-xl transition-transform duration-300 ease-out ${
            disabled ? '' : 'group-active:scale-90'
          }`}
        >
          {!disabled ? (svg ?? '') : ''}
          {t(text ?? '')}
        </div>

        {!disabled && (
          <>
            <img
              src={blick}
              alt="wide rectangle"
              className="absolute z-30 top-[-30px] left-[-10%] transition-all duration-300 ease-in-out group-hover:left-custom-left"
            />
            <img
              src={glare}
              alt="glare effect"
              className={`absolute z-30 top-[-40px] left-[-100%] transition-all duration-500 ease-in-out group-hover:left-[100%] ${
                isAnimated && !isHovered ? 'animate-glare' : ''
              }`}
            />
          </>
        )}
      </div>
    </button>
  );
});

export default PrimaryButton;
