import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccount } from 'wagmi';

import DesktopConnectPage from 'modules/ConnectPage/page/DesktopConnectPage';
import MobileConnectPage from 'modules/ConnectPage/page/MobileConnectPage';

import { ROUTES } from 'shared/constants';
import useMediaQuery from 'shared/hooks/useMediaQuery';

const ConnectPage = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { isConnected, chainId } = useAccount();
  const navigate = useNavigate();
  useEffect(() => {
    if (isConnected && (chainId === 8453 || chainId === 137 || chainId === 42161)) {
      navigate(ROUTES.LOBBY);
    }
  }, [isConnected, chainId]);
  return isMobile ? <MobileConnectPage /> : <DesktopConnectPage />;
};

export default ConnectPage;
