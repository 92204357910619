import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { generateSalt } from 'shared/constants/utils';
import { useHash } from 'shared/hooks/useHash';
import { useGlobalStore } from 'shared/store/globalStore';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';

export const useSaltGenerator = () => {
  const { address, chainId } = useAccount();
  const { playerA, playerB, roomStatus } = useRoomStore();
  const { activeRoomId } = useParams();
  const { mainSalt, setMainSalt } = usePlayerGamesStore();
  const { handleSetHash, handleGetHash } = useHash();
  const { jwt } = useGlobalStore();
  const processingRef = useRef(false);
  const saltCreator = async () => {
    if (processingRef.current) return;
    processingRef.current = true;
    try {
      let mainSaltValue;
      if (
        !!(
          address &&
          (playerA.address === address.toLowerCase() ||
            playerB.address === address.toLowerCase()) &&
          !mainSalt?.[activeRoomId! + address! + chainId] &&
          roomStatus !== RoomStatusEnum.Closed &&
          jwt[address!]
        )
      ) {
        mainSaltValue = await handleGetHash({
          hashKey: `${activeRoomId}_${chainId}`,
        });
        if (!mainSaltValue) {
          const newSalt = generateSalt();
          setMainSalt(activeRoomId! + address! + chainId, newSalt);
          handleSetHash({
            hashValue: newSalt,
            hashKey: `${activeRoomId}_${chainId}`,
          });
        } else {
          setMainSalt(activeRoomId! + address! + chainId, mainSaltValue);
        }
      }
    } finally {
      processingRef.current = false;
    }
  };
  useEffect(() => {
    if (activeRoomId) {
      saltCreator();
    }
  }, [
    setMainSalt,
    jwt,
    address,
    playerA,
    playerB,
    roomStatus,
    activeRoomId,
    mainSalt,
    handleGetHash,
    handleSetHash,
  ]);
};
