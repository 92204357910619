import { PublicClient } from 'viem';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { NULL_ADDRESS } from 'shared/constants/constants';
import {
  CloserEnum,
  MovesEnum,
  RoomStatusEnum,
  RoundStagesEnum,
  RoundWinnerOutcomes,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { PlayerType } from 'shared/types/app-types';
import { readAllRoomRounds, readRoom } from 'shared/viem-functions/read-game-function';

import {
  parseRoomData,
  parseRounds,
  roomsAreEqual,
  scoreCounter,
  shouldUpdateRounds,
} from './room-utils';

interface roundMove {
  move: MovesEnum;
  encrMove: `0x${string}`;
}

export interface RoomData {
  roomId: number | null; // room id 'rooms'
  playerA: PlayerType; // address, wins 'rooms'
  playerB: PlayerType; // address, wins 'rooms'
  bet: number | null; // bet amount, pot is bet * 2 'rooms'
  token: `0x${string}`; // token address 'rooms'
  roomStatus: RoomStatusEnum; // room state 'rooms'
  winsTo: number; // wins to win the game 'rooms'
  roundCounter: number; // round number 'rooms'
  closer: CloserEnum; // closer 'rooms'
  deadline: bigint; // deadline 'rooms'
  winnerStatus: WinnerStatusEnum; // winner status 'rooms'
}

export interface RoundData {
  playerA: roundMove;
  playerB: roundMove;
  winner: RoundWinnerOutcomes;
  stage: number;
  roundStage: RoundStagesEnum;
}

interface winsData {
  winsPlayerA: number;
  winsPlayerB: number;
}

interface State {
  room: RoomData;
  rounds: RoundData[] | null;
  wins: winsData;
  initialLoading: boolean;
}

interface Actions {
  startStopRoom: ({
    roomId,
    provider,
  }: {
    roomId: number | null;
    provider: PublicClient | null;
  }) => void;
  stopPolling: () => void;
}

let intervalId: ReturnType<typeof setInterval> | null = null;
let isProcessing = false;

const initialState: State = {
  room: {
    roomId: null,
    playerA: {
      address: NULL_ADDRESS,
      wins: 0n,
    },
    playerB: {
      address: NULL_ADDRESS,
      wins: 0n,
    },
    bet: null,
    token: NULL_ADDRESS,
    roomStatus: RoomStatusEnum.None,
    winsTo: 3,
    roundCounter: 1,
    closer: CloserEnum.None,
    deadline: 0n,
    winnerStatus: WinnerStatusEnum.None,
  },
  rounds: null,
  wins: {
    winsPlayerA: 0,
    winsPlayerB: 0,
  },

  initialLoading: true,
};

export const useNewRoomStore = create<State & Actions>()(
  immer(
    devtools((set, getState) => ({
      ...initialState,

      startStopRoom: ({ roomId, provider }) => {
        if (roomId === null && intervalId) {
          clearInterval(intervalId);
          intervalId = null;
          return;
        }

        if (roomId && provider) {
          if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }

          intervalId = setInterval(() => {
            (async () => {
              if (isProcessing) return;
              isProcessing = true;
              try {
                // 1. Read room data
                const room = await readRoom({
                  provider,
                  roomId: BigInt(roomId),
                });
                const parsedRoom = parseRoomData(room);

                // 2. Compare old vs new
                const currentStore = getState();

                // Temporary variables to store updates
                let newRoom = null;
                let newRounds = null;
                let newWins = null;
                let shouldStopPolling = false;

                // If room is different or we have no rounds, then fetch new rounds
                if (!roomsAreEqual(parsedRoom, currentStore.room) || !currentStore.rounds) {
                  const rounds = await readAllRoomRounds({
                    provider,
                    roomId: BigInt(roomId),
                  });
                  // We'll update the room
                  newRoom = parsedRoom;

                  // Parse newly fetched rounds
                  const parsedRounds = parseRounds(rounds);
                  const existingRounds = currentStore.rounds;
                  const isDifferent = shouldUpdateRounds(existingRounds, parsedRounds);

                  if (isDifferent) {
                    // Recompute wins only if rounds have changed
                    const wins = scoreCounter(parsedRounds);

                    // Check if the new wins differ from the current wins
                    if (
                      wins.winsPlayerA !== currentStore.wins.winsPlayerA ||
                      wins.winsPlayerB !== currentStore.wins.winsPlayerB
                    ) {
                      newWins = wins;
                    }

                    // We'll update rounds
                    newRounds = parsedRounds;
                  }

                  // 5. If room is closed => stop polling
                  if (parsedRoom.roomStatus === RoomStatusEnum.Closed) {
                    if (intervalId) clearInterval(intervalId);
                    intervalId = null;
                    shouldStopPolling = true;
                  }
                }

                // --- Single set call at the end ---
                set((draft) => {
                  if (newRoom !== null) {
                    draft.room = newRoom;
                  }
                  if (newRounds !== null) {
                    draft.rounds = newRounds;
                  }
                  if (newWins !== null) {
                    draft.wins = newWins;
                  }
                  if (draft.initialLoading) {
                    draft.initialLoading = false;
                  }
                });

                // If we stopped polling, exit the function
                if (shouldStopPolling) {
                  return;
                }
              } catch (err) {
                console.error('Error in startStopRoom interval:', err);
              } finally {
                isProcessing = false;
              }
            })();
          }, 3000);
        }
      },
      stopPolling: () => {
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
      },
    })),
  ),
);
