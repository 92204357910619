import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { useCloseUnusedRoom } from 'modules/Room/game-status/hooks/use-close-unused-room';

import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { TIME_BEFORE_SHOW_TIMER_IN_MODALS } from 'shared/constants/timers';
import { Timer } from 'shared/constants/types';
import { useRoomStore } from 'shared/store/roomStore';
import { Actions, State, useTimersStore } from 'shared/store/timersStore';
import { epochToLocalUTC } from 'shared/utils/time';

interface UseTimerLogicProps {
  gameStatusName: string;
  setShowTimer: (value: boolean) => void;
  setShowCloseButton: (value: boolean) => void;
  setTechnicalDefeatError: (value: boolean) => void;
  selector: string;
}

export const useTimerLogic = ({
  gameStatusName,
  setShowTimer: setShowTimerParent,
  setShowCloseButton: setShowCloseButtonParent,
  setTechnicalDefeatError,
  selector,
}: UseTimerLogicProps) => {
  const { closerRoom, roomStatus, roundCounter, setRoomExpire } = useRoomStore();
  const [showInnerTimer, setShowInnerTimer] = useState(false);
  const timeOutRef = useRef<null | Timer>(null);
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const onExpire = () => {
    setShowInnerTimer(false);
    setShowTimerParent(false);
    setShowCloseButtonParent(true);
    setRoomExpire(true);
  };
  const timersStore = useTimersStore();
  const storedTimerKey = `${gameStatusName}ShowTimer` as keyof State;
  const setStoredTimerKey = `set${gameStatusName.charAt(0).toUpperCase()}${gameStatusName.slice(
    1,
  )}ShowTimer` as keyof Actions;

  useEffect(() => {
    if (
      !timersStore[storedTimerKey]?.[activeRoomId! + address + chainId + roundCounter] &&
      roomStatus !== RoomStatusEnum.Closed
    ) {
      if (new Date() >= epochToLocalUTC(closerRoom?.deadline)) {
        setShowInnerTimer(true);
        setShowTimerParent(true);
      } else {
        timersStore[setStoredTimerKey](
          activeRoomId! + address + chainId + roundCounter,
          new Date(new Date().getTime() + TIME_BEFORE_SHOW_TIMER_IN_MODALS),
        );
        timeOutRef.current = setTimeout(() => {
          setShowInnerTimer(true);
          setShowTimerParent(true);
        }, TIME_BEFORE_SHOW_TIMER_IN_MODALS);
      }
    } else if (roomStatus !== RoomStatusEnum.Closed) {
      const currentTime = new Date();
      const storedTimerValue =
        timersStore[storedTimerKey]?.[activeRoomId! + address + chainId + roundCounter];
      const targetTime = storedTimerValue ? new Date(storedTimerValue) : new Date();
      const diffInMilliseconds = targetTime.getTime() - currentTime.getTime();
      if (diffInMilliseconds >= TIME_BEFORE_SHOW_TIMER_IN_MODALS) {
        setShowInnerTimer(true);
        setShowTimerParent(true);
      } else {
        const timeRemaining = targetTime.getTime() - currentTime.getTime();
        timeOutRef.current = setTimeout(() => {
          setShowInnerTimer(true);
          setShowTimerParent(true);
        }, timeRemaining);
      }
    }
    return () => {
      clearTimeout(timeOutRef.current as Timer);
    };
  }, [setShowTimerParent]);

  const { closeUnusedRoomHandler, isSuccess, technicalDefeatError } = useCloseUnusedRoom();
  useEffect(() => {
    if (technicalDefeatError) setTechnicalDefeatError(technicalDefeatError);
  }, [technicalDefeatError, setTechnicalDefeatError]);

  return {
    isSuccess,
    showInnerTimer,
    setShowInnerTimer,
    closeUnusedRoomHandler,
    onExpire,
  };
};
