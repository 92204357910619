import { getBlockNumber } from '@wagmi/core';

import { config } from 'shared/config';

export const fetchBlockNumber = async (chainId: 1 | 42161 | 137 | 8453 | undefined) => {
  return await getBlockNumber(config, {
    chainId: chainId,
    cacheTime: 0,
  });
};
