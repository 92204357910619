import { Log, PublicClient, decodeEventLog } from 'viem';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { NULL_ADDRESS } from 'shared/constants/constants';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import contract from 'shared/contract/contract.abi.json';
import { RoomInfo, RoomStatus } from 'shared/types/app-types';
import { fetchBlockNumber } from 'shared/viem-functions/block-numbers';
import {
  readActiveRoomCounter,
  readGetRoomsInfo,
  readRoomIdCounter,
} from 'shared/viem-functions/find-rooms';

//todo: write tests
// todo : check all logic how works add to active and remove from active, add to single and remove from single

interface State {
  activeRooms: RoomInfo[];
  playedRooms: RoomInfo[];
  activeSingle: RoomInfo[];
  lastRoomId: number;
  lastFetched: number;
  loading: boolean;
  intervalId: NodeJS.Timeout | null;
  lastBlockNumber: number;
  time: number;
}

interface Actions {
  clearStore: () => void;
  startStore: (provider: PublicClient) => Promise<void>;
}

const initialState: State = {
  intervalId: null,
  activeRooms: [],
  playedRooms: [],
  activeSingle: [],
  lastRoomId: 0,
  lastFetched: 0,
  loading: true,
  lastBlockNumber: 0,
  time: 0,
};

const fetchRoomEvents = async (
  publicClient: PublicClient,
  currentFromBlock: bigint | undefined,
): Promise<
  | {
      removeFromActive: bigint[];
      addToActive: bigint[];
      updateGames: bigint[];
      addToSingle: bigint[];
      removeFromSingle: bigint[];
      lastBlockNumber: number;
      time: number;
    }
  | undefined
> => {
  try {
    const toBlock = await fetchBlockNumber(undefined);
    let fromBlock = currentFromBlock ?? toBlock;
    if (fromBlock === toBlock) return;

    const logs: Log[] = await publicClient.getLogs({
      fromBlock,
      toBlock,
      address: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
    });
    const decodedLogs = logs.map((log) => {
      try {
        return decodeEventLog({
          abi: contract,
          data: log.data,
          topics: log.topics,
        });
      } catch (error) {
        console.error('Error decoding log:', error);
        return null;
      }
    });

    const conclusion = {
      removeFromActive: [] as bigint[],
      addToActive: [] as bigint[],
      updateGames: [] as bigint[],
      addToSingle: [] as bigint[],
      removeFromSingle: [] as bigint[],
      lastBlockNumber: Number(toBlock),
      time: Date.now(),
    };

    decodedLogs
      .filter(
        (event) =>
          event !== null &&
          (event.eventName === RoomStatus.ROOM_CLOSED ||
            event.eventName === RoomStatus.ROOM_JOINED ||
            event.eventName === 'PlayerRevealedMove' ||
            event.eventName === RoomStatus.ROOM_CREATED),
      )
      .forEach((item: any) => {
        if (item.eventName === RoomStatus.ROOM_CLOSED) {
          conclusion.removeFromActive.push(item.args.roomId);
          conclusion.removeFromSingle.push(item.args.roomId);
        }
        if (item.eventName === RoomStatus.ROOM_JOINED) {
          conclusion.addToActive.push(item.args.roomId);
        }
        if (item.eventName === 'PlayerRevealedMove') {
          conclusion.updateGames.push(item.args.roomId);
        }
        if (item.eventName === RoomStatus.ROOM_CREATED) {
          conclusion.addToActive.push(item.args.roomId);
        }
        if (item.eventName === RoomStatus.ROOM_JOINED) {
          conclusion.removeFromSingle.push(item.args.roomId);
        }
      });
    conclusion.removeFromActive = [...new Set(conclusion.removeFromActive)];
    conclusion.addToActive = [...new Set(conclusion.addToActive)];
    conclusion.updateGames = [...new Set(conclusion.updateGames)];
    conclusion.addToSingle = [...new Set(conclusion.addToSingle)];
    console.log(conclusion);
    return conclusion;
  } catch (error) {
    console.error('Error fetching or decoding logs:', error);
  }
};

export const useGamesStore = create<State & Actions>()(
  immer(
    devtools((set, get) => {
      let processing = false;
      return {
        ...initialState,
        startStore: async (provider: PublicClient) => {
          try {
            const currentBlock = await fetchBlockNumber(undefined);
            set({ lastBlockNumber: Number(currentBlock) });
            const lastRoomIdBig = await readRoomIdCounter(provider);
            const activeRoomCounterBig = await readActiveRoomCounter(provider);
            const lastRoomId = Number(lastRoomIdBig);
            const activeRoomCounter = Number(activeRoomCounterBig);

            if (lastRoomId > 0) {
              let lastFetched = Math.max(lastRoomId - 50, 0);
              const rooms = await readGetRoomsInfo(provider, lastFetched, lastRoomId);
              const activeRooms: RoomInfo[] = [];
              const playedRooms: RoomInfo[] = [];
              let activeSingle: RoomInfo[] = [];
              rooms.forEach((room) => {
                if (room.data.playerB !== NULL_ADDRESS) {
                  if (room.status === RoomStatusEnum.Closed) {
                    playedRooms.push(room);
                  } else if (room.status !== RoomStatusEnum.Closed) {
                    activeRooms.push(room);
                  }
                } else if (room.status !== RoomStatusEnum.Closed) {
                  activeSingle.push(room);
                }
              });
              set({
                activeRooms,
                playedRooms,
                activeSingle,
                lastRoomId,
                lastFetched,
                loading: false,
              });

              while (
                activeRooms.length + activeSingle.length < activeRoomCounter &&
                lastFetched > 1
              ) {
                const newLastFetched = Math.max(lastFetched - 50, 1);
                const additionalRooms = await readGetRoomsInfo(
                  provider,
                  newLastFetched,
                  lastFetched,
                );
                let activeSingle = get().activeSingle;
                additionalRooms.forEach((room) => {
                  if (room.data.playerB !== NULL_ADDRESS) {
                    if (room.status === RoomStatusEnum.Closed) {
                      playedRooms.push(room);
                    } else {
                      activeRooms.push(room);
                    }
                  } else if (room.status !== RoomStatusEnum.Closed) {
                    activeSingle.push(room);
                  }
                });

                lastFetched = newLastFetched;
                set({
                  activeRooms,
                  playedRooms,
                  activeSingle,
                  lastRoomId,
                  lastFetched,
                });

                if (activeRooms.length >= activeRoomCounter) break;
              }
            }
          } catch (error) {
            console.error('Error starting store:', error);
          }

          let intervalId = setInterval(async () => {
            if (processing) return;
            processing = true;
            try {
              let conclusion = await fetchRoomEvents(provider, BigInt(get().lastBlockNumber));
              if (conclusion) {
                set({
                  lastBlockNumber: conclusion.lastBlockNumber,
                  time: conclusion.time,
                });
                const {
                  removeFromActive,
                  addToActive,
                  updateGames,
                  addToSingle,
                  removeFromSingle,
                } = conclusion;
                const { activeRooms, playedRooms, activeSingle } = get();
                if (addToActive.length) {
                  const newActiveRooms = await Promise.all(
                    addToActive.map(async (roomId) => {
                      const room = await readGetRoomsInfo(provider, Number(roomId), Number(roomId));
                      return room[0];
                    }),
                  );
                  set({ activeRooms: [...newActiveRooms, ...activeRooms] });
                }
                if (addToSingle.length) {
                  const newActiveSingle = await Promise.all(
                    addToSingle.map(async (roomId) => {
                      const room = await readGetRoomsInfo(provider, Number(roomId), Number(roomId));
                      return room[0];
                    }),
                  );
                  set({ activeSingle: [...newActiveSingle, ...activeSingle] });
                }
                if (updateGames.length) {
                  const updatedGames = await Promise.all(
                    updateGames.map(async (roomId) => {
                      const room = await readGetRoomsInfo(provider, Number(roomId), Number(roomId));
                      return room[0];
                    }),
                  );
                  set({
                    activeRooms: activeRooms.map(
                      (room) =>
                        updatedGames.find(
                          (updatedRoom) => updatedRoom.data.roomId === room.data.roomId,
                        ) || room,
                    ),
                  });
                }
                if (removeFromActive.length) {
                  set({
                    playedRooms: [
                      ...activeRooms.filter((room) => removeFromActive.includes(room.data.roomId)),
                      ...playedRooms,
                    ],
                    activeRooms: activeRooms.filter(
                      (room) => !removeFromActive.includes(room.data.roomId),
                    ),
                  });
                }
                if (removeFromSingle.length) {
                  console.log(activeSingle);
                  set({
                    activeSingle: activeSingle.filter(
                      (room) => !removeFromSingle.includes(room.data.roomId),
                    ),
                  });
                  console.log(get().activeSingle);
                }
              }
            } catch (error) {
              console.error('Error processing room events:', error);
            } finally {
              processing = false;
            }
          }, 30000);
          set({ intervalId });
        },
        clearStore: () => {
          const { intervalId } = get();
          if (intervalId) {
            clearInterval(intervalId);
            set({ intervalId: null });
          }
          set(initialState);
        },
      };
    }),
  ),
);
