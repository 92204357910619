import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAccount, usePublicClient, useWalletClient } from 'wagmi';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { Switch } from 'shared/components/ui/switch/switch';
import { useBalance } from 'shared/hooks/useBalance';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useGlobalStore } from 'shared/store/globalStore';
import { useRoomStore } from 'shared/store/roomStore';
import { useStartGameStore } from 'shared/store/start-game-store';

export const JoinGameStatus = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState({
    allowance: false,
    joining: false,
  });
  const { address } = useAccount();
  const { selectedCurrency } = useCurrenciesStore();
  const { bet } = useRoomStore();
  const [fullAllowance, setFullAllowance] = useState(true);
  const invitedBy = useGlobalStore((state) => state.invitedBy);
  const { activeRoomId } = useParams();

  const {
    fetchCurrentAllowance,
    currentAllowance,
    approveAllowance,
    joinRoom,
    isApproving,
    isFetching,
  } = useStartGameStore();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  useEffect(() => {
    if (publicClient && selectedCurrency && address) {
      fetchCurrentAllowance(address, selectedCurrency, publicClient);
    }
  }, [publicClient, selectedCurrency, address]);

  const approveAllowanceHandler = async (fullAllowance: boolean, bet: number) => {
    if (!walletClient || !publicClient || !selectedCurrency) return;
    setLoading({
      allowance: true,
      joining: false,
    });
    try {
      await approveAllowance({
        amount: +bet,
        currency: selectedCurrency,
        userAddress: address as `0x${string}`,
        walletClient,
        publicClient,
        fullAllowance,
        joinGame: false,
      });
    } catch (e) {
    } finally {
      setLoading({
        allowance: false,
        joining: false,
      });
    }
  };

  const joinGameHandler = async () => {
    if (!walletClient || !publicClient || !activeRoomId) return;

    setLoading({
      allowance: false,
      joining: true,
    });
    try {
      await joinRoom({
        roomId: BigInt(activeRoomId),
        referrer: invitedBy,
        walletClient,
        publicClient,
        userAddress: address as `0x${string}`,
      });
    } catch (e) {
    } finally {
      setLoading({
        allowance: false,
        joining: false,
      });
    }
  };

  const { balance } = useBalance({
    currencyAddress: selectedCurrency!.address,
    walletAddress: address as `0x${string}`,
  });

  if (!selectedCurrency) return null;
  return (
    <div className="">
      <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
        {t('modals.joinGameModal.start')}
      </p>
      {Number(currentAllowance) <= +bet ? (
        <p className="text-[12px] md:text-[16px] text-white">
          {t('modals.joinGameModal.pressAuthorize')}
        </p>
      ) : (
        <p className="text-[12px] md:text-[16px] text-white">
          {t('modals.joinGameModal.pressStart')}
        </p>
      )}
      <AnimatedTransition show={Number(currentAllowance) <= +bet}>
        <div className=" pt-4 gap-2  flex items-center">
          <p className="text-[14px] text-[#C0C0FF] border-r-[1px] pr-3 mr-3 border-border">
            {t('modals.setBet.plan')}
          </p>
          <Switch
            className="ml-2"
            onCheckedChange={() => {
              setFullAllowance(!fullAllowance);
            }}
            checked={fullAllowance}
          />
        </div>
      </AnimatedTransition>
      <div className={'grid grid-cols-2 gap-4 mt-4'}>
        <PrimaryButton
          text={t('common.authorize')}
          disabled={Number(currentAllowance) >= +bet}
          onClick={() => {
            approveAllowanceHandler(fullAllowance, +bet);
          }}
          isLoading={loading.allowance}
        />
        <PrimaryButton
          text={'common.join'}
          disabled={
            Number(currentAllowance) < +bet || isApproving || isFetching || (balance ?? 0) < +bet
          }
          onClick={joinGameHandler}
          isLoading={loading.joining}
        />
      </div>
    </div>
  );
};
