export enum LoadersStateEnum {
  None,
  ChooseMove,
  MoveDone,
  Reveal,
  RevealDone,
  Sleep,
  LookingOpponent,
}

export enum FinishStateEnum {
  NoOneWin = 'NoOneWin',
  OpponentSurrender = 'OpponentSurrender',
  YouExitRoom = 'YouExitRoom',
  YouLost = 'YouLost',
  YouTechnicallyLost = 'YouTechnicallyLost',
  YouTechnicallyWin = 'YouTechnicallyWin',
  YouWin = 'YouWin',
}

export enum CardPlayerEnum {
  Player = 'Player',
  Opponent = 'Opponent',
  Connect = 'Connecting',
}
