import React from 'react';

import MainPageNetworkBtn from 'shared/components/ui/MainPageNetworkBtn/MainPageNetworkBtn';

import ArrowRightIcon from 'assets/arrow-right.svg?react';
import ConnectIcon from 'assets/connect.svg?react';
import NetworkIcon from 'assets/network.svg?react';
import JoystickIcon from 'assets/svg/joystick.svg?react';

interface Props {
  isConnected: boolean;
  className?: string;
}

const PlaySequence = ({ isConnected, className }: Props) => {
  return (
    <div
      className={`w-full flex justify-around items-center min-w-max	bg-one-more-blue border-border border-[1px] rounded-[16px]  p-4 sm:p-8
    ${className ?? ''}
    `}
    >
      <MainPageNetworkBtn
        active={!isConnected}
        svg={<ConnectIcon className="w-[28px] h-[28px] sm:w-[70px] sm:h-[70px]" />}
        labelYellow={'common.connect'}
        labelWhite={'common.wallet'}
      />
      <ArrowRightIcon className=" sm:w-[40px] sm:h-[30px]" />
      <MainPageNetworkBtn
        active={isConnected}
        svg={<NetworkIcon className="w-[28px] h-[28px] sm:w-[70px] sm:h-[70px]" />}
        labelYellow={'common.select'}
        labelWhite={'common.network'}
      />
      <ArrowRightIcon className=" sm:w-[40px] sm:h-[30px]" />
      <MainPageNetworkBtn
        active={false}
        svg={<JoystickIcon className="w-[40px] h-[40px] sm:w-[90px] sm:h-[90px]" />}
        labelYellow={'common.play'}
        labelWhite={'common.games'}
      />
    </div>
  );
};

export default PlaySequence;
