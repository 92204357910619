export const formatBet = (amount: number, decimeals: number) => {
  const betString = (amount / 10 ** decimeals).toString();
  const [, decimals] = betString.split('.');
  let formattedBet = '';
  const leadingZeros = decimals?.match(/^0+/)?.[0]?.length || 0;

  if (leadingZeros >= 3) {
    formattedBet = `0.0<span class="text-[16px]">${leadingZeros}</span>${
      decimals.slice(leadingZeros)[0]
    }`;
  } else {
    formattedBet = betString.toString();
  }
  if (+betString >= 1_000_000) {
    formattedBet = `${(+betString / 1_000_000).toFixed(1)}M`;
  } else if (+betString >= 1_000) {
    formattedBet = `${(+betString / 1_000).toFixed(1)}K`;
  }
  return formattedBet;
};
