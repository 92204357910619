import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { MintingEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import { useWriteRspContractCloseRoom } from 'shared/features/contract.abi';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { estimateGasForFunction } from 'shared/utils/estimateGas';

export const useCloseUnusedRoom = () => {
  const { chainId } = useAccount();
  const [localHash, setLocalHash] = useState<`0x${string}`>();
  const { data, writeContractAsync, error, isSuccess, isError } = useWriteRspContractCloseRoom();

  const { setMintingLoader, setMintingHash } = useGameStatusStore();
  const { setCloseHash, closeHash } = usePlayerGamesStore();
  useEffect(() => {
    if (isSuccess) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      // todo: check if need close hash
      setCloseHash(chainId!, data!, now);
      if (data) setMintingHash(data);
      console.log('user approved technical defeat', timeString);
    }
  }, [isSuccess, data]);

  const { activeRoomId } = useParams();
  const { setTransactionCallBack } = usePlayerGamesStore();

  const closeUnusedRoomHandler = () => {
    setMintingLoader(MintingEnum.TECHNICAL_DEFEAT);
    //todo: check if i need callback
    setTransactionCallBack(async () => {
      let gas = await estimateGasForFunction('closeRoom', [BigInt(activeRoomId || 0)]);
      writeContractAsync({
        args: [BigInt(activeRoomId || 0)],
        gas,
      });
    });
    try {
      writeContractAsync({
        args: [BigInt(activeRoomId || 0)],
      });
    } catch (e) {
    } finally {
    }
  };

  const {
    isSuccess: isSuccessCloseUnusedRoom,
    error: transactionError,
    isError: transactionIsError,
    limitExceed,
  } = useTransactionReceipt(localHash, closeHash?.[chainId!]?.date);

  useEffect(() => {
    if (isSuccessCloseUnusedRoom) {
      const now = new Date();
      setMintingLoader(MintingEnum.NONE);
      setMintingHash('');
      const timeString = now.toLocaleTimeString();
      console.log('Close unused room receipt', timeString);
      setCloseHash(chainId!, null);
      useRoomStore.getState().refetchRoom();
    }
  }, [isSuccessCloseUnusedRoom]);

  useEffect(() => {
    if (error || transactionIsError) {
      console.log('useCloseUnusedRoom', error);
      setCloseHash(chainId!, null);
    }
  }, [error, transactionIsError]);

  return {
    closeUnusedRoomHandler,
    isSuccess,
    technicalDefeatError: isError && transactionIsError,
  };
};
