import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import glare from 'assets/animation/buttons/glare-secondary.png';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  svg?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  childClassName?: string;
}

const SecondaryBtn = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { className, childClassName, text, svg, onClick, disabled, ...rest } = props;
  const { t } = useTranslation();
  return (
    <button
      ref={ref}
      className={`rounded-xl outline-none p-0.5 ${className ? className : ''} ${
        disabled ? 'bg-[#322E66]' : 'bg-secondary-border'
      }`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <div
        className={`group relative overflow-hidden rounded-[10px] inset-0 py-2.5 px-5 font-semibold text-[22px] ${
          disabled
            ? 'bg-[#100E34] shadow-primary-btn-disabled text-[#322E66]'
            : 'bg-secondary-btn shadow-secondary-btn text-[#FFECC0] transition-all ease-in-out duration-300 hover:bg-secondary-btn-hover active:shadow-secondary-btn-active'
        } ${childClassName ? childClassName! : ''}`}
      >
        <div
          className={`flex items-center justify-center text-[14px] sm:text-xl gap-1 transition-transform duration-300 ease-out ${
            disabled ? '' : 'group-active:scale-90'
          }`}
        >
          {!disabled ? (svg ?? '') : ''}
          {t(text ?? '')}
        </div>

        {!disabled && (
          <>
            <img
              src={glare}
              alt="glare effect"
              className={`absolute top-[-40px] left-[-100%] transition-all duration-700 ease-in-out group-hover:left-[100%]`}
            />
          </>
        )}
      </div>
    </button>
  );
});

export default SecondaryBtn;
