import { RoundWinnerOutcomes } from 'shared/constants/shared-enums';

import { RoomData, RoundData } from './room-store';

export function roomsAreEqual(a: RoomData, b: RoomData): boolean {
  return (
    a.roomId === b.roomId &&
    a.playerA.address === b.playerA.address &&
    a.playerB.address === b.playerB.address &&
    a.bet === b.bet &&
    a.token === b.token &&
    a.roomStatus === b.roomStatus &&
    a.winsTo === b.winsTo &&
    a.roundCounter === b.roundCounter &&
    a.closer === b.closer &&
    a.deadline === b.deadline &&
    a.winnerStatus === b.winnerStatus
  );
}

export function parseRoomData(rawRoom: any): RoomData {
  return {
    roomId: Number(rawRoom[0].roomId),
    playerA: {
      address: rawRoom[0].playerA ?? '',
      wins: 0n,
    },
    playerB: {
      address: rawRoom[0].playerB ?? '',
      wins: 0n,
    },
    bet: Number(rawRoom[0].bet),
    token: rawRoom[0].token ?? '',
    roomStatus: rawRoom[6],
    winsTo: Number(rawRoom[0].wins),
    roundCounter: Number(rawRoom[2]),
    closer: rawRoom[4],
    deadline: rawRoom[5],
    winnerStatus: rawRoom[7],
  };
}

export const parseRounds = (rounds: any): RoundData[] => {
  return rounds.map((round: any) => {
    return {
      playerA: {
        move: round.moveA,
        encrMove: round.encrMoveA,
      },
      playerB: {
        move: round.moveB,
        encrMove: round.encrMoveB,
      },
      winner: round.winner,
      stage: round.stage,
      roundStage: round.roundStage,
    };
  });
};

export const roundsAreEqual = (a: RoundData, b: RoundData): boolean => {
  return (
    a.playerA.move === b.playerA.move &&
    a.playerA.encrMove === b.playerA.encrMove &&
    a.playerB.move === b.playerB.move &&
    a.playerB.encrMove === b.playerB.encrMove &&
    a.winner === b.winner &&
    a.stage === b.stage &&
    a.roundStage === b.roundStage
  );
};

/**
 * Compares existing rounds and newly parsed rounds to decide if there's a change.
 *
 * We assume RPS-like logic where only the most recent round can change,
 * or a new round might be appended.
 */
export function shouldUpdateRounds(
  existingRounds: RoundData[] | null,
  parsedRounds: RoundData[],
): boolean {
  // 1) If `existingRounds` is null but we have some new rounds, or vice versa, that's a change.
  if (!existingRounds && parsedRounds.length > 0) {
    return true;
  }

  // If we do have existing rounds but the lengths differ => there's a new/fewer round(s).
  if (existingRounds && parsedRounds.length !== existingRounds.length) {
    return true;
  }

  // 2) If both arrays are non-empty and the same length, compare only the last round.
  if (existingRounds && parsedRounds.length > 0) {
    const lastIndex = parsedRounds.length - 1;
    if (!roundsAreEqual(parsedRounds[lastIndex], existingRounds[lastIndex])) {
      return true;
    }
  }

  // If none of the above conditions hit, there's no difference.
  return false;
}

export function scoreCounter(rounds: RoundData[]): { winsPlayerA: number; winsPlayerB: number } {
  let wins = {
    winsPlayerA: 0,
    winsPlayerB: 0,
  };
  rounds.forEach((round) => {
    if (round.winner === RoundWinnerOutcomes.PlayerA) {
      wins.winsPlayerA++;
    } else if (round.winner === RoundWinnerOutcomes.PlayerB) {
      wins.winsPlayerB++;
    }
  });
  return wins;
}
