import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAccount, usePublicClient } from 'wagmi';

import DesktopLayout from 'shared/components/layout/desktop-layout';
import { ChangeNetwork } from 'shared/components/sections/change-network/change-network';
import { ConnectWallet } from 'shared/components/sections/connect-wallet/connect-wallet';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { Spinner } from 'shared/components/ui/Loaders/spinner/spinner';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { ROUTES } from 'shared/constants';
import { NULL_ADDRESS } from 'shared/constants/constants';
import {
  MovesEnum,
  RoomStatusEnum,
  RoundWinnerOutcomes,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { useNewRoomStore } from 'shared/store/room-store';
import { Phase, useStreamStore } from 'shared/store/stream-store';

import ControllerIcon from 'assets/controller-brown.svg?react';
import floor from 'assets/png/floor.png';

import { HistoryMoves, MoveCards, PlayerCard, RoomBox, Score } from './components';
import { HistoryModal } from './components/history-moves/history-modal';
import PixiComponent from '@/pixi/PixiComponent';

export const StreamRoom = () => {
  const [isGuysAnimating, setIsGuysAnimating] = useState(false);
  const [playerACardsAnimating, setPlayerACardsAnimating] = useState(false);
  const [playerBCardsAnimating, setPlayerBCardsAnimating] = useState(false);
  const [playerAWonRound, setPlayerAWonRound] = useState(false);
  const [playerBWonRound, setPlayerBWonRound] = useState(false);
  const [roundDraw, setRoundDraw] = useState(false);
  const { isConnected, chainId } = useAccount();
  const { networkId } = useParams();
  const navigate = useNavigate();
  const [playerASelectedMove, setPlayerASelectedMove] = useState(MovesEnum.None);
  const [playerBSelectedMove, setPlayerBSelectedMove] = useState(MovesEnum.None);
  const [playerAWins, setPlayerAWins] = useState(0);
  const [playerBWins, setPlayerBWins] = useState(0);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [guysIndex, setGuysIndex] = useState(0);

  const { t } = useTranslation();

  const { startStopRoom, room, rounds, initialLoading, stopPolling } = useNewRoomStore();
  const { manageQueue, currentPhase, currentRound, queue, clearStore } = useStreamStore();
  const publicClient = usePublicClient();
  const { activeRoomId } = useParams();
  const isGameFinished = queue.length === 0 && room.roomStatus === RoomStatusEnum.Closed;

  useEffect(() => {
    if (initialLoading) {
      return;
    }
    if (isConnected && Number(networkId) === chainId) {
      let phase: Phase = 'break';
      if (
        !rounds?.[room?.roundCounter - 1]?.playerA?.encrMove &&
        !rounds?.[room?.roundCounter - 1]?.playerB?.encrMove
      ) {
        phase = 'playersThinking';
      } else if (
        (rounds?.[room?.roundCounter - 1]?.playerA?.encrMove &&
          !rounds?.[room?.roundCounter - 1]?.playerB?.encrMove) ||
        (rounds?.[room?.roundCounter - 1]?.playerB?.encrMove &&
          !rounds?.[room?.roundCounter - 1]?.playerA?.encrMove)
      ) {
        phase = 'onePlayerChooseMove';
      } else if (
        rounds?.[room?.roundCounter - 1]?.playerA?.move &&
        rounds?.[room?.roundCounter - 1]?.playerB?.move
      ) {
        phase = 'done';
      }
      manageQueue({ round: room.roundCounter, phase });
    }
  }, [room, rounds, isConnected, networkId, chainId]);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    if (activeRoomId && publicClient && Number(networkId) === chainId) {
      startStopRoom({ provider: publicClient, roomId: +activeRoomId });
    } else {
      startStopRoom({ provider: null, roomId: null });
    }
    return () => {
      stopPolling();
    };
  }, [activeRoomId, chainId, networkId]);

  const handleOpenHistoryModal = () => {
    setIsHistoryModalOpen(true);
  };

  const handleCloseHistoryModal = () => {
    setIsHistoryModalOpen(false);
  };

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    let timeout: ReturnType<typeof setTimeout>;

    if (!currentPhase) {
      return;
    }
    if (currentPhase === 'onePlayerChooseMove' || currentPhase === 'twoPlayersChooseMove') {
      setPlayerACardsAnimating(true);
      setPlayerBCardsAnimating(true);
    }

    if (currentPhase === 'playersMadeReveal') {
      const round = rounds?.[currentRound - 1];
      setPlayerACardsAnimating(false);
      setPlayerBCardsAnimating(false);
      setPlayerAWonRound(round?.winner === RoundWinnerOutcomes.PlayerA);
      setPlayerBWonRound(round?.winner === RoundWinnerOutcomes.PlayerB);
      setRoundDraw(round?.winner === RoundWinnerOutcomes.Draw);
      setPlayerASelectedMove(round?.playerA?.move || MovesEnum.None);
      setPlayerBSelectedMove(round?.playerB?.move || MovesEnum.None);
      let playerAWinsActual = 0;
      let playerBWinsActual = 0;
      const roundsToCount = rounds?.slice(0, currentRound);
      roundsToCount?.forEach((round) => {
        if (round.winner === 1) {
          playerAWinsActual++;
        }
        if (round.winner === 2) {
          playerBWinsActual++;
        }
      });
      setPlayerAWins(playerAWinsActual);
      setPlayerBWins(playerBWinsActual);
    }

    if (currentPhase === 'done' && queue.length !== 0 && queue.length !== 1) {
      setPlayerAWonRound(false);
      setPlayerBWonRound(false);
      setPlayerASelectedMove(MovesEnum.None);
      setPlayerBSelectedMove(MovesEnum.None);
    }

    if (!queue.length) {
      setPlayerACardsAnimating(false);
      setPlayerBCardsAnimating(false);
    }
  }, [isGuysAnimating, currentPhase, currentRound, rounds, queue]);

  const renderEndRoundPhrase = () => {
    if (isGameFinished) {
      return (
        <div className="absolute top-[150px] sm:top-[244px] lg:top-[300px] transform -translate-x-1/2 left-1/2 min-w-[420px] text-center">
          <div className="text-[28px] leading-[32px] sm:text-[64px] lg:text-[54px] sm:leading-[80px] font-[900] gradient-title-gold text-uppercase">
            {(room.winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
              room.winnerStatus === WinnerStatusEnum.TechnicalWinnerA) &&
              'Player 1 won'}
            {(room.winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
              room.winnerStatus === WinnerStatusEnum.TechnicalWinnerB) &&
              'Player 2 won'}
          </div>
          <div className="text-[14px] sm:text-[32px] lg:text-[28px] leading-[20px] sm:leading-[40px] lg:leading-[64px] text-[#fff] font-[900]">
            {room.winnerStatus === WinnerStatusEnum.TechnicalWinnerA
              ? 'Player 2 has surrendered'
              : room.winnerStatus === WinnerStatusEnum.TechnicalWinnerB
                ? 'Player 1 has surrendered'
                : ''}
          </div>
        </div>
      );
    }

    if (
      rounds?.[currentRound - 1]?.winner === RoundWinnerOutcomes.PlayerA &&
      currentPhase === 'done'
    ) {
      return (
        <div className="absolute top-[150px] sm:top-[244px] lg:top-[280px] transform -translate-x-1/2 left-1/2 min-w-[420px] text-center flex flex-col items-center">
          <div className="text-[28px] leading-[32px] sm:text-[64px] lg:text-[54px] sm:leading-[80px] font-[900] gradient-title-gold text-uppercase">
            Player 1
          </div>
          <div className="text-[14px] sm:text-[32px] lg:text-[28px] leading-[20px] sm:leading-[40px] lg:leading-[64px] text-[#fff] font-[900]">
            won this round
          </div>
        </div>
      );
    }

    if (
      rounds?.[currentRound - 1]?.winner === RoundWinnerOutcomes.PlayerB &&
      currentPhase === 'done'
    ) {
      return (
        <div className="absolute top-[150px] sm:top-[244px] lg:top-[280px] transform -translate-x-1/2 left-1/2 min-w-[420px] text-center flex flex-col items-center">
          <div className="text-[28px] leading-[32px] sm:text-[64px] g:text-[54px] sm:leading-[80px] font-[900] gradient-title-gold text-uppercase">
            Player 2
          </div>
          <div className="text-[14px] sm:text-[32px] lg:text-[28px] leading-[20px] sm:leading-[40px] lg:leading-[64px] text-white font-[900]">
            won this round
          </div>
        </div>
      );
    }

    if (
      rounds?.[currentRound - 1]?.winner === RoundWinnerOutcomes.Draw &&
      currentPhase === 'done'
    ) {
      return (
        <div className="absolute top-[150px] sm:top-[270px] lg:top-[300px] transform -translate-x-1/2 left-1/2 min-w-[420px] text-center flex flex-col items-center">
          <div className="text-[28px] leading-[32px] sm:text-[64px] lg:text-[60px] sm:leading-[80px] font-[900] gradient-title text-uppercase">
            Draw
          </div>
        </div>
      );
    }
  };

  if (!isConnected) {
    return <ConnectWallet title={'common.connectWallet'} description={'stream.connect'} />;
  }

  if (Number(networkId) !== chainId) {
    return <ChangeNetwork title={'stream.switch'} description={'stream.switchToWatch'} />;
  }

  if (initialLoading) {
    return (
      <DesktopLayout
        title={
          room.roomStatus !== RoomStatusEnum.None && (
            <>
              {room.roomStatus === RoomStatusEnum.Closed ? 'Game Replay' : 'Live Game'}
              {room.roomStatus !== RoomStatusEnum.Closed && (
                <div className="w-[16px] h-[16px] rounded-[50%] bg-[#0AF33D] animate-pulse" />
              )}
            </>
          )
        }
      >
        <div className="w-full h-full flex flex-grow items-center justify-center">
          <Spinner />
        </div>
      </DesktopLayout>
    );
  }

  if (
    !initialLoading &&
    room.playerA.address === NULL_ADDRESS &&
    room.playerB.address === NULL_ADDRESS
  ) {
    return (
      <DesktopLayout
        title={
          room.roomStatus !== RoomStatusEnum.None && (
            <>
              {room.roomStatus === RoomStatusEnum.Closed ? 'Game Replay' : 'Live Game'}
              {room.roomStatus !== RoomStatusEnum.Closed && (
                <div className="w-[16px] h-[16px] rounded-[50%] bg-[#0AF33D] animate-pulse" />
              )}
            </>
          )
        }
      >
        <div className="w-full h-full flex items-center justify-center  flex-grow">
          <div className="text-white text-8xl font-bold mb-[96px]">404</div>
        </div>
      </DesktopLayout>
    );
  }

  return (
    <DesktopLayout
      title={
        room.roomStatus !== RoomStatusEnum.None && (
          <>
            {room.roomStatus === RoomStatusEnum.Closed ? 'Game Replay' : 'Live Game'}
            {room.roomStatus !== RoomStatusEnum.Closed && (
              <div className="w-[16px] h-[16px] rounded-[50%] bg-[#0AF33D] animate-pulse" />
            )}
          </>
        )
      }
      className={`overflow-hidden`}
    >
      <div className="absolute w-full h-[89%] left-0 z-10 flex flex-col mt-[100px] ">
        <PixiComponent />
      </div>

      <div className="relative  flex flex-row items-stretch justify-between max-w-[1920px] max-h-[1080px] lg:min-h-[800px] lg:w-full h-full">
        <div className="relative w-full">
          <div
            className="animate-appear-with-blur relative flex flex-row items-stretch justify-between gap-[12px] max-w-[1920px] p-[8px] sm:p-[20px] lg:p-[0] max-h-[1080px] lg:min-h-[800px] md:w-full lg:h-full
                lg:py-4 rounded-[24px] mx-[16px] h-fit
                border lg:border-[0] border-border transition bg-opacity-30 bg-background lg:bg-[transparent]
              "
          >
            <div className="order-1 sm:max-w-[630px] sm:max-h-[890px] lg:w-full z-30 animate-appear-with-blur flex justify-center lg:mt-[32px] h-fit">
              <PlayerCard
                position="order-1"
                name="Player 1"
                wins={playerBWins}
                playerAddress={room.playerA.address}
                isWinner={
                  isGameFinished &&
                  (room.winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
                    room.winnerStatus === WinnerStatusEnum.TechnicalWinnerA)
                }
                isLooser={
                  isGameFinished &&
                  (room.winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
                    room.winnerStatus === WinnerStatusEnum.TechnicalWinnerB)
                }
                winsTo={room.winsTo}
              />
            </div>
            <div className="z-30 flex flex-col order-2 animate-appear h-fit relative">
              <Score playerAWins={playerAWins} playerBWins={playerBWins} />
              <HistoryMoves
                currentRound={currentRound}
                onOpenHistoryModal={handleOpenHistoryModal}
              />

              <AnimatedTransition show={currentPhase === 'done' || isGameFinished}>
                <>{renderEndRoundPhrase()}</>
              </AnimatedTransition>
            </div>
            <div className="order-3 sm:max-w-[630px] sm:max-h-[890px] lg:w-full z-30 animate-appear-with-blur  flex justify-center lg:mt-[32px] h-fit">
              {room.playerB.address !== NULL_ADDRESS ? (
                <PlayerCard
                  position="order-3"
                  name="Player 2"
                  wins={playerAWins}
                  playerAddress={room.playerB.address}
                  isWinner={
                    isGameFinished &&
                    (room.winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
                      room.winnerStatus === WinnerStatusEnum.TechnicalWinnerB)
                  }
                  isLooser={
                    isGameFinished &&
                    (room.winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
                      room.winnerStatus === WinnerStatusEnum.TechnicalWinnerA)
                  }
                  winsTo={room.winsTo}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="absolute top-[134px] sm:top-[400px] lg:top-[340px] left-[80px] sm:left-[120px] lg:left-[350px] z-[100] animate-slide-in-from-left-without-blur w-[50px] h-[50px]">
          <MoveCards
            position="left"
            isAnimating={playerACardsAnimating}
            isRoundFinished={playerAWonRound || playerBWonRound}
            selectedMove={playerASelectedMove}
            isWinner={playerAWonRound}
            roundDraw={roundDraw}
            currentPhase={currentPhase}
            isGameFinished={isGameFinished}
          />
        </div>

        {room.playerB.address !== NULL_ADDRESS ? (
          <div className="absolute top-[134px] sm:top-[400px] lg:top-[340px] right-[80px] sm:right-[160px] lg:right-[380px] z-[100] animate-slide-in-from-right-without-blur w-[50px] h-[50px]">
            <MoveCards
              position="right"
              isAnimating={playerBCardsAnimating}
              isRoundFinished={playerAWonRound || playerBWonRound}
              selectedMove={playerBSelectedMove}
              isWinner={playerBWonRound}
              roundDraw={roundDraw}
              currentPhase={currentPhase}
              isGameFinished={isGameFinished}
            />
          </div>
        ) : null}
      </div>
      <div className="bg-grape flex justify-center items-center flex-col p-4 fixed bottom-0 left-0 w-full border-t-[1px] border-[#272D63] lg:hidden">
        <PrimaryButton
          onClick={() => navigate(ROUTES.LOBBY)}
          text="pages.stream.playGame"
          svg={<ControllerIcon className="w-5 h-5 sm:w-6 sm:h-6" />}
          isAnimated
          className="w-full"
        />
      </div>
      {room.bet ? (
        <RoomBox
          token={room.token}
          bet={room.bet ? room.bet * 2 : null}
          animationsTriggered={true}
        />
      ) : null}
      <img
        src={floor}
        alt="floor"
        className="absolute bottom-[110px] lg:bottom-[0] left-0 right-0 z-0 h-[20%] w-full"
      />
      <HistoryModal
        isOpen={isHistoryModalOpen}
        onClose={handleCloseHistoryModal}
        currentRound={currentRound}
      />
    </DesktopLayout>
  );
};
