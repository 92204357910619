import React from 'react';

import { Header } from 'shared/components/sections/header/header';

interface LayoutProps {
  children: React.ReactNode;
  title?: React.ReactNode | string;
  className?: string;
}

const DesktopLayout = ({ children, title, className }: LayoutProps) => {
  return (
    <div
      className={`relative w-full h-full min-h-[100vh] max-h-[100vh] max-w-[1920px] mx-auto  px-8 xlg:px-[64px] flex flex-col ${className}`}
    >
      <Header title={title} className="mb-12 pt-[32px]" />
      {children}
    </div>
  );
};

export default DesktopLayout;
