// Define the character's state.
import Deferred from '../utils/Deferred';
import { Spine, TrackEntry } from '@esotericsoftware/spine-pixi-v8';
import { Container } from 'pixi.js';

import { AnimationConfig, animationMap, event_end } from './ConfigHero';

// import { Debug } from '../utils/debug';

interface CharacterState {
  walk: boolean;
  idle: boolean;
  shake: boolean;
  show_rock: boolean;
  show_paper: boolean;
  show_scissors: boolean;
}
export default class SpineHero {
  playingLabel: string | null = null;
  animDeferred?: Deferred<void>;

  state: CharacterState;

  view: Container;
  directionalView: Container;

  spine: Spine;
  isInit: Boolean;

  constructor() {
    this.state = {
      walk: true,
      idle: false,
      shake: false,
      show_rock: false,
      show_paper: false,
      show_scissors: false,
    };

    this.view = new Container();
    this.directionalView = new Container();

    this.spine = Spine.from({
      skeleton: '/game/spine/hero.json',
      atlas: '/game/spine/hero.atlas',
    });
    this.isInit = false;
    this.directionalView.addChild(this.spine);

    this.view.addChild(this.directionalView);
    this.view.visible = false;
    this.view.visible = false;
    this.spine.state.data.defaultMix = 0.18;

    this.spine.state.addListener({
      complete: (trackEntry: TrackEntry) => {
        /* Debug.log(
          `Completed animation ${trackEntry.animation.name} , defaultMix=${this.spine.state.data.defaultMix}`,
        ); */
        // Debug.log(`complete = ${trackEntry.animation.name}`);
        if (trackEntry.trackIndex === 0 && !trackEntry.loop) {
          this.onAnimationEnd(trackEntry.animation.name);
          this.animDeferred?.resolve(trackEntry.animation.name);
          this.playingLabel = null;
        }
      },
      // start: (entry) => Debug.log(`Started animation ${entry.animation.name}`),
      /*   interrupt: (entry) => {
        Debug.log(`Interrupted animation ${entry.animation.name}`);
        this.animDeferred?.resolve();
        this.playingLabel = null;
      }, */
      //   end: (entry) => Debug.log(`Ended animation ${entry.animation.name}`),
      //   dispose: (entry) => Debug.log(`Disposed animation ${entry.animation.name}`),
    });
  }

  playAnimation({ name, loop = false, trackIndex = 0, timeScale = 1 }: AnimationConfig): void {
    if (this.currentAnimationName === name) return;
    // Debug.log(`complete = ${name}`);
    const trackEntry = this.spine.state.setAnimation(0, name, loop);
    if (trackEntry) {
      trackEntry.timeScale = timeScale;
    }
  }

  addAnimation({ name, loop = false, trackIndex = 0, timeScale = 1 }: AnimationConfig): void {
    // if (this.currentAnimationName === name) return;
    const trackEntry = this.spine.state.addAnimation(0, name, loop);
    if (trackEntry) {
      trackEntry.timeScale = timeScale;
    }
  }

  isSpawning(): boolean {
    return this.isAnimationPlaying(animationMap.walk);
  }

  isAnimationPlaying({ name }: AnimationConfig): boolean {
    return this.currentAnimationName === name && !this.spine.state.getCurrent(0)?.isComplete();
  }

  get currentAnimationName(): string | undefined {
    return this.spine.state.getCurrent(0)?.animation.name;
  }

  private onAnimationEnd(animationName: string): void {
    console.log(`Animation "${animationName}" ended.`);
    this.view.emit(event_end, animationName);
    //if (animationName === 'anims/idel') {
    if (!this.isInit) {
      this.isInit = true;
      this.playAnimation(animationMap.idle);
    }
    //}
  }

  get direction(): number {
    return this.directionalView.scale.x > 0 ? 1 : -1;
  }

  set direction(value: number) {
    this.directionalView.scale.x =
      value < 0 ? -this.directionalView.scale.x : this.directionalView.scale.x;
  }

  set scale(value: number) {
    this.directionalView.scale.x = value;
    this.directionalView.scale.y = value;
  }

  async play(name: string, loop = false) {
    if (this.playingLabel) {
      await this.animDeferred?.promise;
    }
    this.spine.state.setAnimation(0, name, loop);
    this.playingLabel = name;
    this.animDeferred = new Deferred();
    return this.animDeferred.promise;
  }

  async playSequence(animations: string[], loopLast = false) {
    if (this.playingLabel) {
      await this.animDeferred?.promise;
    }
    for (let i = 0; i < animations.length; i++) {
      const loop = i === animations.length - 1 ? loopLast : false;
      await this.play(animations[i], loop);
    }
  }

  hasSkin(name: string) {
    return this.spine.skeleton.data.skins.find((skin) => skin.name === name);
  }

  setSkin(skin: string, resetPose = true) {
    if (!this.hasSkin(skin)) {
      throw new Error(`Skin ${skin} not found in ${this.spine.skeleton.data.name}`);
    }
    this.spine.skeleton.setSkinByName(skin);
    if (resetPose) this.spine.skeleton.setSlotsToSetupPose();
  }

  get skin() {
    return this.spine.skeleton.skin?.name;
  }

  updateDelta(delta: number) {
    this.spine.update(delta);
  }
  spawn(): void {
    this.playAnimation(animationMap.walk);
    this.addAnimation(animationMap.idle);
  }
  update(): void {
    if (this.isAnimationPlaying(animationMap.walk)) return;

    if (this.state.shake) this.playAnimation(animationMap.shake);
    else if (this.state.show_rock) this.playAnimation(animationMap.show_rock);
    else if (this.state.show_paper) this.playAnimation(animationMap.show_paper);
    else if (this.state.show_scissors) this.playAnimation(animationMap.show_scissors);
    else if (this.state.walk) {
      if (!this.isAnimationPlaying(animationMap.walk)) {
        this.playAnimation(animationMap.walk);
        if (!this.view.visible) {
          // this.view.visible = true;
          // this.view.visible = true;
        }
      }
    } else if (this.state.idle) this.playAnimation(animationMap.idle);
  }
}
