import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount, usePublicClient } from 'wagmi';

import { GameStatusEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';
import { NoTableImage } from 'modules/lobby-page/components/live-games-section/no-table-image';

import Dropdown from 'shared/components/ui/DropDown/DropDown';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { SearchBarMobile } from 'shared/components/ui/SearchBar/SearchBarMobile';
import { TimeAgo } from 'shared/components/ui/time-ago/time-ago';
import { ModalTypeEnum } from 'shared/constants/modalEnums';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useGamesStore } from 'shared/store/games-store';
import { RoomInfo } from 'shared/types/app-types';

import ArrowIcon from 'assets/arrow.svg?react';
import ClockSmallIcon from 'assets/clock-small.svg?react';
import ClockIcon from 'assets/clock.svg?react';
import ControllerIcon from 'assets/controller-brown.svg?react';
import CurrencySmallIcon from 'assets/currency-small.svg?react';
import CurrencyIcon from 'assets/currency.svg?react';
import FilterSmallIcon from 'assets/filter-small.svg?react';
import FilterIcon from 'assets/filter.svg?react';
import PersonIcon from 'assets/person.svg?react';

import { LiveGameItemMobile } from './live-game-item-mobile';

interface SortingConfig {
  key: 'roomId' | 'amount';
  order: 'asc' | 'desc';
}

const chains = {
  1: 'ETH',
  137: 'Polygon',
  42161: 'Arbitrum',
  8453: 'Base',
};

// todo filters and search

const tableTitleStyles =
  'text-[#F7F7F780] text-[10px] sm:text-[20px] font-medium flex gap-2 items-center';
const tableSubTitleStyles = 'text-[#F7F7F7BF] text-[12px] sm:text-[20px] font-semibold';
const tableColumnStyles = 'flex flex-col';
const sectionStyles =
  'relative overflow-hidden z-10 w-full bg-[linear-gradient(0deg,#080540,#080540),radial-gradient(50%_50%_at_50%_50%,rgba(0,11,255,0.3)_0%,rgba(1,0,29,0.3)_100%)] border-[1px] lg:border-[3px] border-[#202066] rounded-[8px] lg:rounded-[16px] p-[10px] lg:px-[24px] lg:py-[20px] mt-[12px]';
const sectionContainerStyles = 'grid grid-cols-[1.2fr_0.8fr_0.8fr_1fr] lg:gap-8 relative';
const sectionCurrencyFilterStyles =
  'text-white/75 flex gap-2 items-center text-[10px] sm:text-[20px] relative';
const sectionRoomsListStyles =
  'flex flex-col gap-2 sm:gap-4 overflow-scroll mt-[8px] lg:mt-[20px] no-scrollbar overflow-x-hidden  md:pr-[20px] relative';
const sectionTitleStyles = 'font-bold text-[20px] lg:text-[52px] gradient-title';

interface Props {
  openCreateGame: () => void;
  joinRoomHandler: (roomId: number) => void;
}

export const LiveGamesSectionMobile = ({ openCreateGame, joinRoomHandler }: Props) => {
  const [currencyFilter, setCurrencyFilter] = useState<string>('All');
  const [isCurrencyFilterOpen, setIsCurrencyFilterOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [sortingConfig, setSortingConfig] = useState<SortingConfig>({
    key: 'roomId',
    order: 'desc',
  });
  let { chainId, address } = useAccount();
  let { startStore, activeSingle, activeRooms, clearStore, lastBlockNumber, time } =
    useGamesStore();
  const publicClient = usePublicClient();

  const [myGames, setMyGames] = useState<RoomInfo[]>([]);
  useEffect(() => {
    if (publicClient) {
      clearStore();
      startStore(publicClient);
    }
    return () => {
      clearStore();
    };
  }, [publicClient, chainId]);
  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);
  useEffect(() => {
    if (activeRooms.length) {
      let sortedRooms = activeRooms.filter(
        (room) =>
          room.data.playerA?.toLowerCase() === address?.toLowerCase() ||
          room.data.playerB?.toLowerCase() === address?.toLowerCase(),
      );
      setMyGames(sortedRooms);
    }
  }, [activeRooms]);

  const { setGameStatus } = useGameStatusStore();
  const { t } = useTranslation();
  const { availableCurrencies, currencyForSelect } = useCurrenciesStore();
  const isTablet = useMediaQuery('(min-width: 768px)');

  const handleToggleCurrencyFilter = () => {
    setIsCurrencyFilterOpen(!isCurrencyFilterOpen);
  };

  const handleChangeCurrencyFilter = (value: string) => {
    setCurrencyFilter(value);
  };

  // const handleResetRooms = () => {
  //   setGameStatus(ModalTypeEnum.SET_BET, false);
  // };

  const handleSort = (key: 'roomId' | 'amount') => {
    setSortingConfig((prevConfig) => ({
      key,
      order: prevConfig.key === key && prevConfig.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const renderGamesList = () => {
    let sortedRooms = [...activeSingle].sort((a, b) => {
      if (a.data.playerA?.toLowerCase() === address?.toLowerCase()) return -1;
      if (b.data.playerA?.toLowerCase() === address?.toLowerCase()) return 1;
      return 0;
    });
    if (currencyFilter !== 'All') {
      sortedRooms = sortedRooms.filter(
        (room) =>
          room.data.token.toLowerCase() ===
          availableCurrencies
            .find((currency) => currency.symbol === currencyFilter)
            ?.address.toLowerCase(),
      );
    }

    return sortedRooms.map((room) => (
      <LiveGameItemMobile
        key={`${room.data.roomId.toString()}_${room.data.playerA}`}
        roomId={room.data.roomId}
        address={room.data.playerA as `0x${string}`}
        amount={Number(room.data.bet)}
        currency={availableCurrencies.find(
          (currency) => currency.address.toLowerCase() === room.data.token.toLowerCase(),
        )}
        // onJoinRoomCallback={handleResetRooms}
        ourGame={room.data.playerA?.toLowerCase() === address?.toLowerCase()}
        joinRoomHandler={joinRoomHandler}
      />
    ));
  };

  const renderMyGamesList = () => {
    if (myGames.length === 0) return null;
    return myGames
      .filter((room) => {
        if (currencyFilter === `All`) return true;
        return (
          room.data.token.toLowerCase() ===
          availableCurrencies
            .find((currency) => currency.symbol === currencyFilter)
            ?.address.toLowerCase()
        );
      })
      .map((room, index) => (
        <LiveGameItemMobile
          key={`${room.data.roomId.toString()}_${room.data.playerA}`}
          roomId={room.data.roomId}
          address={room.data.playerA as `0x${string}`}
          amount={Number(room.data.bet)}
          currency={availableCurrencies.find(
            (currency) => currency.address.toLowerCase() === room.data.token.toLowerCase(),
          )}
          // onJoinRoomCallback={handleResetRooms}
          ourGame
        />
      ));
  };

  return (
    <div className="w-full max-w-[1184px]  relative z-10 mt-6">
      <div className="px-4">
        <div className={sectionContainerStyles}>
          <div className={`${tableColumnStyles} ${searchOpen ? 'hidden' : ''}`}>
            <span
              className={`${tableTitleStyles} cursor-pointer`}
              onClick={() => handleSort('roomId')}
            >
              {t('pages.liveGamesSection.createdBy')}
              <PersonIcon className="w-4 h-4" />
            </span>
            <span className={tableSubTitleStyles}>{t('pages.liveGamesSection.roomId')}</span>
          </div>
          {/* <div className={`${tableColumnStyles} col-span-2`}>
              <span className={`${tableTitleStyles} cursor-pointer`} onClick={() => handleSort('roomId')}>Date created <ClockIcon /></span>
              <span className={tableSubTitleStyles}>
                {sortingConfig.key === 'roomId' ?
                (sortingConfig.order === 'asc' ? 'Oldest Games' : 'Newest Games')
                : 'Newest Games'}
              </span>
            </div> */}
          {/*<div*/}
          {/*  className={`${tableColumnStyles}  ${searchOpen ? 'hidden' : ''}`}*/}
          {/*>*/}
          {/*  <span*/}
          {/*    className={`${tableTitleStyles} cursor-pointer`}*/}
          {/*    onClick={() => handleSort('amount')}*/}
          {/*  >*/}
          {/*    {t('pages.liveGamesSection.bet')}*/}
          {/*    {isTablet ? <FilterIcon /> : <FilterSmallIcon />}*/}
          {/*  </span>*/}
          {/*  <span className={tableSubTitleStyles}>*/}
          {/*    {sortingConfig.key === 'amount'*/}
          {/*      ? sortingConfig.order === 'asc'*/}
          {/*        ? t('pages.liveGamesSection.lowToHighMob')*/}
          {/*        : t('pages.liveGamesSection.lowToHighMob')*/}
          {/*      : t('pages.liveGamesSection.lowToHighMob')}*/}
          {/*  </span>*/}
          {/*</div>*/}
          <div className={`${tableColumnStyles} ${searchOpen ? 'hidden' : ''}`}>
            <span className={tableTitleStyles}>
              {t('pages.liveGamesSection.currency')}
              {isTablet ? <CurrencyIcon /> : <CurrencySmallIcon />}
            </span>
            <div onClick={handleToggleCurrencyFilter} className={sectionCurrencyFilterStyles}>
              {currencyFilter}
              <ArrowIcon
                className={`${
                  isCurrencyFilterOpen ? 'transform rotate-180' : ''
                } transition ease-in-out duration-500`}
              />

              <Dropdown
                open={isCurrencyFilterOpen}
                list={[{ value: 'All', label: 'All' }, ...currencyForSelect]}
                onClick={(option) => handleChangeCurrencyFilter(option.label)}
                active={currencyFilter}
                position={{ top: 30, left: 0 }}
              />
            </div>
          </div>
          {/*<div className={searchOpen ? 'col-span-4' : ''}>*/}
          {/*  <SearchBarMobile*/}
          {/*    setSearch={setSearchValue}*/}
          {/*    searchOpen={searchOpen}*/}
          {/*    setSearchOpen={setSearchOpen}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>

        <div className={sectionRoomsListStyles}>
          {(activeSingle.length === 0 && myGames.length === 0) || noSearchResults ? (
            <div className="w-full h-[80%] relative overflow-hidden min-h-[400px] border border-border rounded-[8px]">
              <NoTableImage noSearchResults={noSearchResults} />
            </div>
          ) : (
            <>
              {renderMyGamesList()} {renderGamesList()}
            </>
          )}
        </div>
      </div>
      <div className="bg-grape flex justify-center items-center flex-col p-4 fixed bottom-0 w-full">
        <PrimaryButton
          text={'pages.liveGamesSection.create'}
          svg={<ControllerIcon className="w-6 h-6" />}
          onClick={openCreateGame}
          className="w-full mb-3"
          isAnimated
        />
        <div className="text-[8px] text-block-text flex gap-2">
          {!!lastBlockNumber && (
            <span>
              {chainId && chains[chainId as keyof typeof chains]} block:{' '}
              {+lastBlockNumber.toString()}
            </span>
          )}
          <span>|</span>
          <TimeAgo time={time} />
        </div>
      </div>
    </div>
  );
};
