import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { useSwitchChain } from 'wagmi';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import GridBackground from 'shared/components/ui/GridBackground/GridBackground';
import { chainIcons } from 'shared/config';
import { ROUTES } from 'shared/constants';

import ArrowDown from 'assets/arrow-down.svg?react';

export const ChainsSwitcher = forwardRef<HTMLDivElement>((props, ref) => {
  const { chains, switchChainAsync, isError, error } = useSwitchChain();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      console.log(error.message);
      Sentry.captureException(error);
    }
  }, [isError]);

  const chainSwitchHandler = async (chainId: number) => {
    await switchChainAsync({ chainId });
    navigate(ROUTES.LOBBY);
  };

  if (!chains) return null;

  return (
    <div ref={ref} className={'w-full items-center xlg:items-start flex flex-col gap-4'}>
      <div className="hidden sm:block gradient-title font-bold text-[14px] sm:text-[40px] leading-none">
        {t('chainSwitcher.title')}
      </div>
      <div className="hidden  sm:block gradient-title  text-[24px]">
        {t('chainSwitcher.select')}
      </div>
      <div className="flex w-full gap-2 align-center">
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex grow items-center justify-between bg-move-icon-content p-2.5 sm:p-[36px] rounded-xl sm:rounded-[24px] border-[1px] border-white"
        >
          <div>
            <div className="flex gap-1.5 mb-2 items-center">
              <img src={chainIcons?.[chains[0].id]} className="mr-1.5 w-6 h-6 sm:w-11 sm:h-11" />
              <p className="text-white font-semibold sm:font-medium text-[12px] sm:text-[28px] ">
                {chains[0].name}
              </p>
            </div>
            <p className="text-light-grape text-[8px] sm:text-[16px] leading-[12px] sm:pt-4 sm:leading-[20px] text-left">
              {t(`chains.${chains[0].id}`)}
            </p>
          </div>

          <ArrowDown className="text-white w-6 sm:min-w-[32px]" />
        </button>
      </div>

      <button
        className={`rounded-[12px] sm:rounded-[16px] 
      bg-primary-btn active:bg-primary-active hover:bg-primary-btn-hover w-full
      `}
        onClick={() => chainSwitchHandler(chains[0].id)}
      >
        <div
          className={`rounded-[12px] sm:rounded-[16px] m-[1px] py-4 sm:py-6 px-[15px] sm:px-[35px] h-full flex items-center justify-center $bg-primary-btn-inner text-white
         font-semibold text-[14px] sm:text-[32px] bg-primary-btn-inner leading-none`}
        >
          {t('common.play')}
        </div>
      </button>

      <AnimatedTransition show={isModalOpen}>
        <div className={'bg-[rgba(0,0,0,0.5)] absolute top-0 bottom-0 left-0 right-0'}>
          <div
            className={
              'border-[2px] sm:border-[4px] border-border rounded-2xl sm:rounded-[32px] mt-[72px] mx-4 sm:mx-auto max-w-[1110px]'
            }
          >
            <GridBackground
              className={
                'rounded-2xl sm:rounded-[32px] p-3 sm:p-8 flex flex-col sm:grid grid-cols-2 sm:gap-4 '
              }
            >
              <div className="gradient-title font-bold text-[14px] sm:text-[28px] mb-3 col-span-2">
                {t('chainSwitcher.title')}
              </div>
              {chains.map((chain) => {
                return (
                  <button
                    key={chain.id}
                    className={` border-[1px] sm:border-2  w-full rounded-xl sm:rounded-3xl p-2.5 sm:p-5 mb-2
                        hover:border-white hover:bg-move-icon-content
                        bg-chain-gradient border-border
                    }
                    `}
                    onClick={() => chainSwitchHandler(chain.id)}
                  >
                    <div className="flex items-center gap-2 sm:gap-3 mb-[6px]">
                      {chainIcons?.[chain.id] && (
                        <img
                          src={chainIcons[chain.id]}
                          className="w-8 h-8 sm:w-[56px] sm:h-[56px]"
                        />
                      )}

                      <p className="text-white text-[14px] sm:text-[24px] font-semibold">
                        {chain.name}
                      </p>
                      {chain.id === 42161 && (
                        <div className="bg-orange-gradient rounded-md sm:rounded-2xl px-2.5 py-1  ml-auto border-[1px] sm:border-2 border-orange-btn">
                          <p className="text-orange-btn text-[12px] sm:text-[20px] font-semibold">
                            {t('common.popular')}
                          </p>
                        </div>
                      )}
                    </div>
                    <p className="text-light-grape text-[8px] sm:text-[16px] leading-[12px] sm:pt-4 sm:leading-[20px] text-left">
                      {t(`chains.${chain.id}`)}
                    </p>
                  </button>
                );
              })}
            </GridBackground>
          </div>
        </div>
      </AnimatedTransition>
    </div>
  );
});

ChainsSwitcher.displayName = 'ChainsSwitcher';
