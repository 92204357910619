import { useEffect, useState } from 'react';

function useLocalStorageCheck() {
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    const testKey = 'localStorageTest';
    const testValue = 'test';
    try {
      localStorage.setItem(testKey, testValue);
      const retrievedValue = localStorage.getItem(testKey);
      localStorage.removeItem(testKey);
      if (retrievedValue === testValue) {
        setIsAvailable(true);
      } else {
        setIsAvailable(false);
      }
    } catch (error) {
      setIsAvailable(false);
    }
  }, []);

  return isAvailable;
}

export default useLocalStorageCheck;
