import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface State {
  loaderStartTime: Record<string, Date | string | null>;
  waitYourMoveTime: Record<string, Date | string | null>;
  waitYourMoveShowTimer: Record<string, Date | string | null>;
  waitOpponentMoveShowTimer: Record<string, Date | string | null>;
  waitYourRevealShowTimer: Record<string, Date | string | null>;
  waitOpponentRevealShowTimer: Record<string, Date | string | null>;
}

export interface Actions {
  setLoaderStartTime: (key: string, date: Date | string | null) => void;
  setWaitYourMoveShowTimer: (key: string, date: Date | string | null) => void;
  setWaitOpponentMoveShowTimer: (key: string, date: Date | string | null) => void;
  setWaitYourRevealShowTimer: (key: string, date: Date | string | null) => void;
  setWaitOpponentRevealShowTimer: (key: string, date: Date | string | null) => void;
  deleteAllTimersForThisKey: (key: string) => void;
}

const initialState: State = {
  loaderStartTime: {},
  waitYourMoveTime: {},
  waitYourMoveShowTimer: {},
  waitOpponentMoveShowTimer: {},
  waitYourRevealShowTimer: {},
  waitOpponentRevealShowTimer: {},
};

export const useTimersStore = create<State & Actions>()(
  persist(
    immer((set) => ({
      ...initialState,
      setLoaderStartTime: (key: string, date: Date | string | null) =>
        set((state) => {
          if (date === null && !state.loaderStartTime.hasOwnProperty(key)) {
            return state;
          }
          return {
            ...state,
            loaderStartTime: {
              ...state.loaderStartTime,
              [key]: date,
            },
          };
        }),
      setWaitYourMoveShowTimer: (key, waitYourMoveShowTimer) =>
        set((state) => {
          if (waitYourMoveShowTimer === null && !state.waitYourMoveShowTimer.hasOwnProperty(key)) {
            return state;
          }
          return {
            ...state,
            waitYourMoveShowTimer: {
              ...state.waitYourMoveShowTimer,
              [key]: waitYourMoveShowTimer,
            },
          };
        }),
      setWaitOpponentMoveShowTimer: (key: string, date: Date | string | null) =>
        set((state) => {
          if (date === null && !state.waitOpponentMoveShowTimer.hasOwnProperty(key)) {
            return state;
          }
          return {
            ...state,
            waitOpponentMoveShowTimer: {
              ...state.waitOpponentMoveShowTimer,
              [key]: date,
            },
          };
        }),
      setWaitYourRevealShowTimer: (key: string, date: Date | string | null) =>
        set((state) => {
          if (date === null && !state.waitYourRevealShowTimer.hasOwnProperty(key)) {
            return state;
          }
          return {
            ...state,
            waitYourRevealShowTimer: {
              ...state.waitYourRevealShowTimer,
              [key]: date,
            },
          };
        }),
      setWaitOpponentRevealShowTimer: (key: string, date: Date | string | null) =>
        set((state) => {
          if (date === null && !state.waitOpponentRevealShowTimer.hasOwnProperty(key)) {
            return state;
          }
          return {
            ...state,
            waitOpponentRevealShowTimer: {
              ...state.waitOpponentRevealShowTimer,
              [key]: date,
            },
          };
        }),
      deleteAllTimersForThisKey: (keyPrefix: string) =>
        set((state) => {
          if (state.loaderStartTime && typeof state.loaderStartTime === 'object') {
            Object.keys(state.loaderStartTime).forEach((key) => {
              if (key.startsWith(keyPrefix)) {
                delete state.loaderStartTime[key];
              }
            });
          }
          if (state.waitYourMoveTime && typeof state.waitYourMoveTime === 'object') {
            Object.keys(state.waitYourMoveTime).forEach((key) => {
              if (key.startsWith(keyPrefix)) {
                delete state.waitYourMoveTime[key];
              }
            });
          }
          if (state.waitYourMoveShowTimer && typeof state.waitYourMoveShowTimer === 'object') {
            Object.keys(state.waitYourMoveShowTimer).forEach((key) => {
              if (key.startsWith(keyPrefix)) {
                delete state.waitYourMoveShowTimer[key];
              }
            });
          }
          if (
            state.waitOpponentMoveShowTimer &&
            typeof state.waitOpponentMoveShowTimer === 'object'
          ) {
            Object.keys(state.waitOpponentMoveShowTimer).forEach((key) => {
              if (key.startsWith(keyPrefix)) {
                delete state.waitOpponentMoveShowTimer[key];
              }
            });
          }
          if (state.waitYourRevealShowTimer && typeof state.waitYourRevealShowTimer === 'object') {
            Object.keys(state.waitYourRevealShowTimer).forEach((key) => {
              if (key.startsWith(keyPrefix)) {
                delete state.waitYourRevealShowTimer[key];
              }
            });
          }
          if (
            state.waitOpponentRevealShowTimer &&
            typeof state.waitOpponentRevealShowTimer === 'object'
          ) {
            Object.keys(state.waitOpponentRevealShowTimer).forEach((key) => {
              if (key.startsWith(keyPrefix)) {
                delete state.waitOpponentRevealShowTimer[key];
              }
            });
          }
        }),
    })),
    {
      name: 'timers-store',
    },
  ),
);
