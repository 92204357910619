import { FinishStateEnum } from 'modules/Room/constants/enums';

export const stateMapping = {
  [FinishStateEnum.NoOneWin]: {
    color: 'bg-orange',
    texts: ['gameFinishViews.noOneWin'],
  },
  [FinishStateEnum.OpponentSurrender]: {
    color: 'bg-green',
    texts: ['gameFinishViews.opponentSurrender'],
  },
  [FinishStateEnum.YouExitRoom]: {
    color: 'bg-orange',
    texts: ['gameFinishViews.youExitRoom'],
  },
  [FinishStateEnum.YouLost]: {
    color: 'bg-red',
    texts: ['gameFinishViews.unlucky'],
  },
  [FinishStateEnum.YouTechnicallyLost]: {
    color: 'bg-red',
    texts: ['gameFinishViews.didntMakeMove', 'gameFinishViews.toStartNewGame'],
  },
  [FinishStateEnum.YouTechnicallyWin]: {
    color: 'bg-green',
    texts: ['gameFinishViews.opponentDidntApprove', 'gameFinishViews.start'],
  },
  [FinishStateEnum.YouWin]: {
    color: 'bg-green',
    texts: ['gameFinishViews.madeIt'],
  },
};
