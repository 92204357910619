import { estimateGas } from '@wagmi/core';
import { encodeFunctionData } from 'viem';

import { config } from 'shared/config';
import contractAbi from 'shared/contract/contract.abi.json';

export const estimateGasForFunction = async (
  functionName: string,
  args: any[],
): Promise<bigint> => {
  const data = encodeFunctionData({
    abi: contractAbi,
    functionName,
    args,
  });

  try {
    const estimatedGas = await estimateGas(config, {
      to: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
      data,
    });
    const gasWithBuffer = (estimatedGas * 12n) / 10n;
    return gasWithBuffer;
  } catch (error) {
    console.error('Error estimating gas:', error);
    return 6000000n;
  }
};
