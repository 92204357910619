import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { useReveal } from 'modules/Room/Hooks/useReveal';
import { useTimerLogic } from 'modules/Room/game-status/components/play-game-statuses/hooks/use-timer-logic';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import DigitalClock from 'shared/components/ui/DigitalClock/DigitalClock';
import MintingLoader from 'shared/components/ui/Loaders/MintingLoader/MintingLoader';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { technicalDefeatMessages, waitYourRevealMessages } from 'shared/constants/text-loaders';
import { SHOW_REVEAL_BTN } from 'shared/constants/timers';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { epochToLocalUTC } from 'shared/utils/time';

import { useRevealStatusText } from './use-reveal-status-text';

export const WaitYourRevealStatus = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [technicalDefeatError, setTechnicalDefeatError] = useState(false);
  const [showRevealBtn, setShowRevealBtn] = useState(false);
  const { revealGameStatus, setRevealGameStatusError } = useGameStatusStore();
  const { isSuccess, closeUnusedRoomHandler, onExpire } = useTimerLogic({
    selector: 'player-card-host',
    gameStatusName: 'waitYourReveal',
    setShowTimer,
    setShowCloseButton,
    setTechnicalDefeatError,
  });

  useEffect(() => {
    return () => {
      setRevealGameStatusError({ error: false, reject: false });
    };
  }, [setRevealGameStatusError]);
  useEffect(() => {
    setTimeout(() => {
      setShowRevealBtn(true);
    }, SHOW_REVEAL_BTN);
  }, []);
  const { getTextComponent } = useRevealStatusText(
    showTimer,
    showCloseButton,
    technicalDefeatError,
  );
  const { chainId } = useAccount();
  const { closeHash, playMoves } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const { address } = useAccount();
  const { roundCounter, closerRoom } = useRoomStore();
  const { writeReveal } = useReveal();

  return (
    <>
      {getTextComponent()}
      <AnimatedTransition show={showRevealBtn}>
        <div className="flex justify-center">
          <PrimaryButton text={'common.reveal'} onClick={writeReveal} className={'mt-[20px]'} />
        </div>
      </AnimatedTransition>

      <AnimatedTransition show={showCloseButton && !isSuccess}>
        <div className="flex justify-center mt-[20px]">
          <PrimaryButton text={'common.technicalDefeat'} onClick={closeUnusedRoomHandler} />
        </div>
      </AnimatedTransition>
      <AnimatedTransition show={showTimer && !showCloseButton}>
        <div className="w-full flex justify-center mt-[24px]">
          <DigitalClock
            key={(epochToLocalUTC(closerRoom?.deadline) as Date).toString()}
            expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
            className="w-fit"
            onExpire={onExpire}
          />
        </div>
      </AnimatedTransition>
    </>
  );
};
