export const LANGUAGES = {
  en: 'en',
  // hi: 'hi',
  // uk: 'uk',
};
// export const LANGUAGES_LIST = [LANGUAGES.en, LANGUAGES.hi, LANGUAGES.uk];
export const LANGUAGES_LIST = [LANGUAGES.en];

export const LANGUAGE_FALLBACK = LANGUAGES.en;

export const LANGUAGES_TEXT = {
  [LANGUAGES.en]: 'English',
  // [LANGUAGES.hi]: 'Hindi',
  // [LANGUAGES.uk]: 'Ukrainian',
};
