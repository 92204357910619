import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { coinIcons } from 'shared/constants';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { debounce } from 'shared/utils/debounce';

import boxFull from 'assets/png/box-full.png';
import boxHalf from 'assets/png/box-half.png';
import info from 'assets/png/info.png';

type CoinSymbol = keyof typeof coinIcons;

interface RoomBoxProps {
  setBoxPosition: (position: DOMRect) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  animationsTriggered: boolean;
}

export const RoomBox = ({ setBoxPosition, containerRef, animationsTriggered }: RoomBoxProps) => {
  const [boxImage, setBoxImage] = useState(boxHalf);
  const [betText, setBetText] = useState('');
  const [fontSize, setFontSize] = useState(30);
  const [showTip, setShowTip] = useState(false);
  const [rawBet, setRawBet] = useState<number | null>(null);
  const { activeRoomId } = useParams();
  const { selectedCurrency } = useCurrenciesStore();

  const boxRef = useRef<HTMLImageElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const betTextRef = useRef<HTMLParagraphElement>(null);
  const { bet, playerB, roomStatus } = useRoomStore();

  // box image
  useEffect(() => {
    if (activeRoomId && playerB.address === NULL_ADDRESS) {
      setBoxImage(boxHalf);
    } else {
      setBoxImage(boxFull);
    }
  }, [activeRoomId, playerB, roomStatus]);
  // bet text
  useEffect(() => {
    if (bet) {
      let rawBet = bet;
      if (playerB.address !== NULL_ADDRESS) {
        rawBet = bet * 2;
      }
      setRawBet(rawBet);
      const betString = rawBet.toString();
      const [, decimals] = betString.split('.');
      let formattedBet = '';
      const leadingZeros = decimals?.match(/^0+/)?.[0]?.length || 0;

      if (leadingZeros >= 3) {
        formattedBet = `0.0<span class="text-[16px]">${leadingZeros}</span>${
          decimals.slice(leadingZeros)[0]
        }`;
      } else {
        formattedBet = rawBet.toString();
      }
      if (rawBet >= 1_000_000) {
        formattedBet = `${(rawBet / 1_000_000).toFixed(1)}M`;
      } else if (rawBet >= 1_000) {
        formattedBet = `${(rawBet / 1_000).toFixed(1)}K`;
      }

      setBetText(`${formattedBet}`);
    }
  }, [bet, selectedCurrency, playerB]);

  const updateBoxPosition = () => {
    if (boxRef.current) {
      setBoxPosition(boxRef.current.getBoundingClientRect());
    }
  };
  const updateBoxContainerPosition = () => {
    const container = containerRef.current;
    const box = boxRef.current;

    if (container && box) {
      const containerRect = container.getBoundingClientRect();
      const distanceFromBottom = window.innerHeight - containerRect.bottom;
      box.style.bottom = `${distanceFromBottom + 26}px`;
      updateBoxPosition();
    }
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      updateBoxContainerPosition();
    }, 100);

    debouncedHandleResize();
    window.addEventListener('resize', debouncedHandleResize);

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [playerB, activeRoomId]);

  const getCoinIcon = (symbol: CoinSymbol | undefined) => {
    if (!symbol) return null;

    return <img src={coinIcons[symbol]} className="w-6" />;
  };
  const isMobileDevice = useMemo(
    () => /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    [],
  );
  return (
    <div
      ref={boxRef}
      className={classNames(
        'absolute z-50 left-1/2 transform -translate-x-1/2 bottom-[95px] z-1 w-[25%] hd:max-w-[350px] blur-4 sm:max-w-[280px]',
        animationsTriggered ? 'animate-slide-in-from-bottom' : 'animate-fast-appear',
      )}
    >
      <img src={boxImage} alt="box" onLoad={updateBoxContainerPosition} />
      <div
        ref={textContainerRef}
        className="absolute z-50 text-white text-center top-[69%] left-[9%] w-[45%] h-[18%] font-bold  flex items-center justify-center whitespace-nowrap"
      >
        {betText && (
          <div className="flex gap-2" style={{ fontSize: `${fontSize}px` }} ref={betTextRef}>
            <img
              src={info}
              alt="info"
              onMouseEnter={!isMobileDevice ? () => setShowTip(true) : undefined}
              onClick={() => setShowTip(!showTip)}
              onMouseLeave={!isMobileDevice ? () => setShowTip(false) : undefined}
              className="w-4 h-4 z-50 absolute top-1 right-1 cursor-pointer pointer-events-auto"
            />
            <AnimatedTransition asFragment={true} show={showTip}>
              <div
                className={`absolute top-[-20%]
             left-full
             box-border`}
              >
                <div className="bg-border border border-gray-border font-medium text-[18px] relative rounded-[8px] flex flex-col items-start px-[16px] sm:py-[12px] py-[5px] z-50">
                  <p className="text-white  ">
                    K <span className="opacity-60 ">- 1000</span>
                  </p>
                  <p className="text-white ">
                    M <span className="opacity-60 ">- 1000</span>
                  </p>
                  <p className="text-white ">
                    0.0<span className="text-[12px]">3</span>1{' '}
                    <span className="opacity-60 ">- 0.0001</span>
                  </p>
                  <p className="text-white border-t border-gray-border w-full text-start pt-1 mt-1">
                    Bet
                    <span className="opacity-60 "> - {rawBet}</span>
                  </p>
                </div>
              </div>
            </AnimatedTransition>
            <p className="font-black" dangerouslySetInnerHTML={{ __html: betText }}></p>
            {getCoinIcon(selectedCurrency?.symbol as CoinSymbol | undefined)}
          </div>
        )}
      </div>
    </div>
  );
};
