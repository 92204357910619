import ArrowIcon from 'assets/arrow-down.svg?react';

import './history-moves.scss';

interface HistoryMovesProps {
  currentRound: number;
  onOpenHistoryModal: () => void;
}

export const HistoryMoves = ({ currentRound, onOpenHistoryModal }: HistoryMovesProps) => {
  return (
    <div className="flex flex-col items-center mt-[6px] lg:mt-[18px]">
      <div className="text-[#4E6CDE] text-[12px] sm:text-[32px] lg:text-[28px] font-semibold rounded-[8px] lg:rounded-[16px] lg:border-[1.3px] lg:border-[#202066] lg:bg-[#01001D4D] flex flex-col leading-[36px]">
        <div className="h-[26px] sm:h-[76px] lg:h-auto flex items-center gap-[8px] lg:gap-[18px] px-[16px] sm:px-[39px] lg:px-[36px] lg:pt-[20px] rounded-[8px] border-[1.3px] border-[#202066] bg-[#01001D4D] lg:bg-[transparent] lg:border-[0]">
          Round {currentRound}
          {/* <div className='w-[3px] h-[12px] lg:h-[36px] bg-[#272D63]' />
          10:00 */}
        </div>
        <div className="w-full h-[1px] bg-[#202066] my-[12px] hidden lg:flex" />
        <div
          className="flex justify-center items-center gap-[8px] text-[#FFFFFF99] font-normal text-[12px] sm:text-[30px] lg:text-[20px] leading-[24px] lg:pb-[20px] cursor-pointer mt-[6px] sm:mt-[12px] lg:mt-[0]"
          onClick={onOpenHistoryModal}
        >
          Show History <ArrowIcon className="w-[12px] h-[12px]" />
        </div>
      </div>
    </div>
  );
};
