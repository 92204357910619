import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { GameStatusEnum, MintingEnum } from 'modules/Room/game-status/constants/game-status-enum';

interface State {
  gameStatus: GameStatusEnum;
  gameBetGameStatus: {
    reject: boolean;
    error: boolean;
  };
  revealGameStatus: {
    error: boolean;
    reject: boolean;
  };
  playMoveGameStatus: {
    reject: boolean;
    error: boolean;
  };
  notEnoughGameStatus: {
    continue: boolean;
    zeroBalance: boolean;
  };
  setRevealGameStatusError: ({ reject, error }: { reject: boolean; error: boolean }) => void;
  setPlayMoveGameStatus: ({ reject, error }: { reject: boolean; error: boolean }) => void;
  setGameStatus: (GameStatus: GameStatusEnum) => void;
  resetGameStatus: () => void;
  resetStore: () => void;
  setNotEnoughModalContinue: (flag: boolean) => void;
  setNotEnoughModalZeroBalance: (flag: boolean) => void;
  setGameBetGameStatus: ({ reject, error }: { reject: boolean; error: boolean }) => void;
  mintingLoader: MintingEnum;
  mintingHash: `0x${string}` | '';
  setMintingLoader: (mintingLoader: MintingEnum) => void;
  setMintingHash: (mintingHash: `0x${string}` | '') => void;
}

const initialState: State = {
  gameStatus: GameStatusEnum.NONE,
  mintingLoader: MintingEnum.NONE,
  gameBetGameStatus: {
    reject: false,
    error: false,
  },
  playMoveGameStatus: {
    reject: false,
    error: false,
  },
  revealGameStatus: {
    error: false,
    reject: false,
  },
  notEnoughGameStatus: {
    continue: false,
    zeroBalance: true,
  },
  setRevealGameStatusError: () => {},
  setPlayMoveGameStatus: () => {},
  setGameStatus: () => {},
  resetGameStatus: () => {},
  resetStore: () => {},
  setNotEnoughModalContinue: () => {},
  setNotEnoughModalZeroBalance: () => {},
  setGameBetGameStatus: () => {},
  mintingHash: '',
  setMintingLoader: () => {},
  setMintingHash: () => {},
};

export const useGameStatusStore = create<State>()(
  immer(
    devtools((set) => ({
      ...initialState,
      setGameStatus: (GameStatus) =>
        set((state) => ({
          gameStatus: GameStatus,
        })),
      setGameBetGameStatus: ({ reject, error }) =>
        set(() => ({
          gameBetGameStatus: {
            reject,
            error,
          },
        })),
      resetGameStatus: () =>
        set(() => ({
          gameStatus: GameStatusEnum.NONE,
        })),
      setRevealGameStatusError: ({ error, reject }) =>
        set((state) => ({
          revealGameStatus: {
            ...state.revealGameStatus,
            error,
            reject,
          },
        })),
      setPlayMoveGameStatus: ({ error, reject }) =>
        set(() => ({
          playMoveGameStatus: {
            error,
            reject,
          },
        })),
      setNotEnoughModalContinue: (continueFlag) =>
        set((state) => ({
          notEnoughGameStatus: {
            ...state.notEnoughGameStatus,
            continue: continueFlag,
          },
        })),
      setNotEnoughModalZeroBalance: (zeroBalanceFlag) =>
        set((state) => ({
          notEnoughGameStatus: {
            ...state.notEnoughGameStatus,
            zeroBalance: zeroBalanceFlag,
          },
        })),
      resetStore: () => set(initialState),
      setMintingLoader: (mintingLoader) =>
        set(() => ({
          mintingLoader,
        })),
      setMintingHash: (mintingHash) =>
        set(() => ({
          mintingHash,
        })),
    })),
  ),
);
