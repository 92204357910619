import React from 'react';

import './MoveIcon.scss';

interface Props {
  clickHandler: () => void;
  active: boolean;
  imgSrc: string;
  alt: string;
  text: string;
  disabledHover?: boolean;
  hide?: boolean;
}

const MoveIcon = ({ clickHandler, active, disabledHover, hide, text, imgSrc, alt }: Props) => {
  return (
    <div
      className={`transition-all bg-move-icon-border items-center justify-center flex cursor-pointer  rounded-[8px] sm:rounded-[24px] h-[55px] w-[55px] sm:h-[120px] sm:w-[90px] 2xl:h-[160px] 2xl:w-[120px] ${
        active ? ' scale-125  bg-yellow p-[2px] sm:p-[6px]' : 'p-[1px] sm:p-[2px]'
      } ${disabledHover ? 'pointer-events-none ' : 'sm:hover:shadow-play-btn'} ${
        hide ? 'hidden' : ''
      }`}
      onClick={clickHandler}
    >
      <div
        className={`transition-all w-full h-full flex justify-center flex-col items-center rounded-[8px] sm:rounded-[24px] ${
          active ? 'bg-move-icon-content-active' : 'bg-move-icon-content'
        }`}
      >
        <img className="h-[27px] sm:h-[50px]" src={imgSrc} alt={alt} />
        <p className="text-white text-[10px] sm:text-[20px]">{text}</p>
      </div>
    </div>
  );
};

export default MoveIcon;
