import { ReactNode } from 'react';

import './GridBackground.css';

interface Props {
  children: ReactNode;
  className?: string;
}

const GridBackground = ({ children, className }: Props) => {
  return (
    <div className={` w-full h-full relative grid-background ${className ? className : ''}`}>
      {children}
    </div>
  );
};

export default GridBackground;
