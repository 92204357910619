import React, { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { useTimer } from 'react-timer-hook';

import useMediaQuery from 'shared/hooks/useMediaQuery';

import './DigitalClock.scss';

interface Props {
  className?: string;
  expiryTimestamp: Date | null;
  onExpire?: () => void;
}

const DigitalClock = forwardRef<HTMLDivElement, Props>(
  ({ className, expiryTimestamp, onExpire }: Props, ref) => {
    const { seconds, minutes } = useTimer({
      expiryTimestamp: new Date(expiryTimestamp as Date), // 30 minutes
      onExpire,
    });
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    const isMobile = useMediaQuery('(max-width: 768px)');

    if (!expiryTimestamp) return null;
    if (isMobile) {
      const element = document.querySelector('.mobile-timer');
      if (!element) return null;
      return createPortal(
        <div className="clock py-[5px] px-[18px] mr-2 border border-border rounded-[6px] flex items-center cursor-pointer">
          {formattedMinutes} : {formattedSeconds}
        </div>,
        element,
      );
    }
    return (
      <div
        ref={ref}
        className={`p-[2px] rounded-[24px] bg-gradient-to-b from-white to-clock ${className}`}
      >
        <div
          className={`px-6 py-2.5 2xl:py-5 rounded-[24px] bg-gradient-to-b from-violet to-[#172178] min-w-[105px]`}
        >
          <p className="timer text-[48px] leading-none flex">
            <span className=" flex justify-start">{formattedMinutes}</span>:
            <span className="flex justify-end">{formattedSeconds}</span>
          </p>
        </div>
      </div>
    );
  },
);

export default DigitalClock;
