import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useChainId, useConnect } from 'wagmi';

import { ROUTES } from 'shared/constants';

import wallet from 'assets/png/wallet.png';

interface ConnectWalletProps {
  title: string;
  description: string;
}

export const ConnectWallet = ({ title, description }: ConnectWalletProps) => {
  const { t } = useTranslation();
  const { connectors, connect } = useConnect();
  const chainId = useChainId();
  return (
    <div className="w-full h-[100vh] flex justify-center items-center overflow-hidden relative">
      <div className="p-6 bg-modal-gradient flex flex-col gap-5 items-center border-[2px] border-border rounded-[24px]">
        <img src={wallet} alt="wallet" className="w-[150px] h-[150px]" />
        <p className="gradient-title-gold text-2xl capitalize font-bold leading-none">{t(title)}</p>
        <p className="text-sm text-white max-w-[280px] text-center opacity-70">{t(description)}</p>

        <div className="w-full h-[1px] bg-border" />
        <div className="flex gap-4">
          {connectors.map((connector) => (
            <button key={connector.name} onClick={() => connect({ connector, chainId })}>
              <img
                src={connector.icon}
                alt={connector.name}
                className="w-8 h-8 sm:w-[56px] sm:h-[56px]"
              />
            </button>
          ))}
          {connectors.length === 0 && (
            <Link className="text-sm text-blue text-center " to={ROUTES.HOME}>
              {t('common.noWallet')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
