import { Container, Sprite } from 'pixi.js';

export function centerObjectsByWindow(...toCenter: Container[]) {
  const center = (obj: Container) => {
    obj.x = window.innerWidth / 2;
    obj.y = window.innerHeight >= 1200 ? 600 : window.innerHeight / 2;

    if (obj instanceof Sprite) {
      obj.anchor.set(0.5, 0);
    }
  };
  toCenter.forEach(center);
}

export function centerObjects(toCenter: Container[], w: number, h: number) {
  const center = (obj: Container) => {
    if (obj instanceof Sprite) {
      obj.anchor.set(0.5, 0.5);
      obj.x = w / 2;
      obj.y = h >= 1200 ? 600 : h / 2;
    } else {
      obj.x = w / 2 - obj.width / 2;
      obj.y = h >= 1200 ? 600 - obj.height / 2 : h / 2 - obj.height / 2;
    }
  };
  toCenter.forEach(center);
}

export function wait(seconds: number) {
  return new Promise<void>((res) => setTimeout(res, seconds * 1000));
}

export async function after(seconds: number, callback: (...args: unknown[]) => unknown) {
  await wait(seconds);
  return callback();
}

export function getEntries<T extends object>(obj: T) {
  return Object.entries(obj) as Entries<T>;
}
