import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import 'shared/i18n/i18n';
import 'shared/utils/setntry-instrument.js';
import { Web3WagmiConfig } from 'shared/web3/components/web3-wagmi-config/Web3WagmiConfig';

import App from './app/App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <StrictMode>
    <Web3WagmiConfig>
      <Router>
        <App />
      </Router>
    </Web3WagmiConfig>
  </StrictMode>,
);
