import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { useAccount } from 'wagmi';

import { MintingEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import { RoomStatusEnum, RoundStagesEnum } from 'shared/constants/shared-enums';
import { useWriteRspContractReveal } from 'shared/features/contract.abi';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { WriteError } from 'shared/types/app-types';
import { estimateGasForFunction } from 'shared/utils/estimateGas';

export const useReveal = () => {
  const [revealHashLocal, setRevealHashLocal] = useState<`0x${string}` | undefined>();
  const { playMoves } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const { roundCounter, refetchRoom, roundStage, roomStatus } = useRoomStore();
  const { setRevealGameStatusError } = useGameStatusStore();
  const { setRevealHash } = usePlayerGamesStore();
  const placingReveal = useRef(false);
  const { setTransactionCallBack } = usePlayerGamesStore();

  const { data, writeContractAsync, error: revealError, isSuccess } = useWriteRspContractReveal();
  const { setMintingLoader, setMintingHash, setGameStatus } = useGameStatusStore();

  useEffect(() => {
    if (isSuccess) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('user approved reveal', timeString);
      setRevealHash(activeRoomId! + address! + chainId + roundCounter, data!, now);
      setMintingLoader(MintingEnum.YOUR_REVEAL);
      setRevealHashLocal(data);
      if (data) setMintingHash(data);
      refetchRoom();
    }
  }, [isSuccess, setMintingLoader, refetchRoom]);
  useEffect(() => {
    if (
      !revealHashLocal &&
      roundStage === RoundStagesEnum.RevealMove &&
      roomStatus === RoomStatusEnum.ReadyForReveal
    ) {
      if (playMoves[activeRoomId! + address! + chainId + roundCounter]?.revealHash?.hash) {
        setRevealHashLocal(
          playMoves[activeRoomId! + address! + chainId + roundCounter].revealHash!.hash,
        );
        setMintingLoader(MintingEnum.NONE);
        setMintingHash('');
      }
    }
  }, [revealError, setMintingLoader, setRevealHashLocal]);

  const {
    isSuccess: isSuccessReveal,
    error: transactionError,
    limitExceed,
  } = useTransactionReceipt(
    revealHashLocal,
    playMoves?.[activeRoomId! + address + chainId + roundCounter]?.revealHash?.date,
  );

  useEffect(() => {
    if (isSuccessReveal) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('success reveal', timeString);
      refetchRoom();
      setRevealGameStatusError({ error: false, reject: false });
      placingReveal.current = false;
      setMintingLoader(MintingEnum.NONE);
      setMintingHash('');
    }
  }, [isSuccessReveal, roundCounter]);

  useEffect(() => {
    if (revealError || transactionError) {
      console.log('reveal error');
      if (revealError) {
        Sentry.captureException(revealError);
        const typedError = revealError as WriteError | undefined;
        if (typedError?.cause?.code) {
          setRevealGameStatusError({
            reject: typedError.cause.code === 4001,
            error: typedError.cause.code !== 4001,
          });
        } else {
          setRevealGameStatusError({
            reject: false,
            error: true,
          });
        }
      }
      setRevealHashLocal(undefined);
      setRevealHash(activeRoomId! + address! + chainId + roundCounter, null);
      if (transactionError) Sentry.captureException(transactionError);
      placingReveal.current = false;
    }
  }, [revealError, transactionError]);
  const writeReveal = useCallback(async () => {
    if (placingReveal.current) return;
    if (!activeRoomId) return;
    if (!playMoves?.[activeRoomId! + address + chainId + roundCounter]) return;
    if (!playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move) return;
    if (!playMoves?.[activeRoomId! + address + chainId + roundCounter]?.salt) return;

    try {
      placingReveal.current = true;

      setTransactionCallBack(async () => {
        let gas = await estimateGasForFunction('reveal', [
          BigInt(activeRoomId || 0n),
          roundCounter || 0,
          playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move,
          playMoves?.[activeRoomId! + address + chainId + roundCounter]?.salt as string,
        ]);
        writeContractAsync({
          args: [
            BigInt(activeRoomId || 0n),
            roundCounter || 0,
            playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move,
            playMoves?.[activeRoomId! + address + chainId + roundCounter]?.salt as string,
          ],
          gas,
        });
      });
      let gas = await estimateGasForFunction('reveal', [
        BigInt(activeRoomId || 0n),
        roundCounter || 0,
        playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move,
        playMoves?.[activeRoomId! + address + chainId + roundCounter]?.salt as string,
      ]);
      await writeContractAsync({
        args: [
          BigInt(activeRoomId || 0n),
          roundCounter || 0,
          playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move,
          playMoves?.[activeRoomId! + address + chainId + roundCounter]?.salt as string,
        ],
        gas,
      });
    } catch (error) {
      console.log(error);
    } finally {
      placingReveal.current = false;
    }
  }, [
    activeRoomId,
    playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move,
    playMoves?.[activeRoomId! + address + chainId + roundCounter]?.salt,
    writeContractAsync,
  ]);

  return { writeReveal };
};
