import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Transition } from '@headlessui/react';

import { useGetHistoryMoves } from 'modules/Room/Hooks/useGetHistoryMoves';
import { Hash } from 'modules/Room/features/Hash/Hash';

import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { useRoomStore } from 'shared/store/roomStore';

import ArrowDown from 'assets/arrow-down.svg?react';

import HistoryMove from './HistoryMove';
import './HistoryMoves.scss';

interface HistoryMovesProps {
  boxPosition?: DOMRect | null;
}

const HistoryMoves = ({ boxPosition }: HistoryMovesProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const { roundCounter } = useRoomStore();
  const gameHistory = useGetHistoryMoves();
  const { activeRoomId } = useParams();
  const { roomStatus } = useRoomStore();
  const [lastRoundIndex, setLastRoundIndex] = useState(1);
  const [isShow, setIsShow] = useState(false);
  const { someoneElseGame } = useRoomStore();

  useEffect(() => {
    const updateHeight = () => {
      if (!boxPosition || !containerRef.current) return;
      const containerPosition = containerRef.current.getBoundingClientRect();
      let height = boxPosition.top - containerPosition.top - 20;
      if (window.innerWidth >= 1920) {
        height = height > 470 ? 470 : height;
      } else {
        height = height > 320 ? 320 : height;
      }
      containerRef.current.style.height = `${height}px`;
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [boxPosition]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (roundCounter) {
        if (screenWidth >= 1920 && roundCounter > 5) {
          setHasScrollbar(true);
        } else if (roundCounter >= 4) {
          setHasScrollbar(true);
        } else {
          setHasScrollbar(false);
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [roundCounter]);

  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed) {
      setLastRoundIndex(0);
    } else {
      setLastRoundIndex(1);
    }
  }, [roomStatus]);

  return (
    <div className="flex flex-col items-center">
      <h4 className="text-blue-text font-xl font-medium mb-1">{t('common.history')}</h4>

      <div className="border border-dark-border rounded-[7px] w-full bg-dark-blue relative overflow-hidden">
        <Transition
          show={
            !!(activeRoomId && !someoneElseGame && roomStatus !== RoomStatusEnum.Closed && isShow)
          }
          enter="transition-transform duration-1000"
          enterFrom="transform translate-y-full opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition-transform duration-1000"
          leaveFrom="transform translate-y-0"
          leaveTo="transform translate-y-[130%]"
          as={Fragment}
        >
          <Hash setIsShow={setIsShow} />
        </Transition>
        <div
          style={{ background: 'rgba(255, 255, 255, 0.03)' }}
          className="flex w-full rounded-t-[7px] py-[10px] uppercase text-[14px] text-gray font-light mb-[14px]"
        >
          <span className="w-[33%] flex justify-center">{t('common.you')}</span>
          <span className="w-[33%] flex justify-center">{t('common.round')}</span>
          <span className="w-[33%] flex justify-center">{t('common.opponent')}</span>
        </div>
        <div
          ref={containerRef}
          className={`sm:h-[320px]  hd:max-h-[470px] overflow-auto history ${
            hasScrollbar ? 'mr-[7px]' : ''
          }`}
        >
          {activeRoomId &&
            gameHistory.map((round, index) => (
              <HistoryMove
                key={round.encrMoveA + index}
                round={round}
                gameCount={gameHistory.length - index}
                lastRound={index === lastRoundIndex}
              />
            ))}
          <div
            className="absolute bottom-0 w-full h-[120px] pointer-events-none"
            style={{
              background: 'linear-gradient(360deg, #0C093D 19.76%, rgba(12, 9, 61, 0) 100%)',
            }}
          />

          {activeRoomId && !someoneElseGame && roomStatus !== RoomStatusEnum.Closed && (
            <button
              onClick={() => setIsShow(true)}
              className="mb-2 opacity-40 flex items-center text-[16px] hd:text-[20px] text-[#FFFFFF] absolute bottom-0 left-1/2 transform -translate-x-1/2"
            >
              {t('hash.show')} <ArrowDown className="ml-2 rotate-180" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryMoves;
