import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalWithFullScreenBg from 'shared/components/ui/Modal/ModalWithFullScreenBg';
import TextWithLeftColorLine from 'shared/components/ui/TextWithLeftColorLine/TextWithLeftColorLine';
import { ModalPositionEnum } from 'shared/constants/modalEnums';

interface Props {
  open: boolean;
}

const NotWorkingLocalStorageModal = ({ open }: Props) => {
  const { t } = useTranslation();
  return (
    <ModalWithFullScreenBg
      open={open}
      position={ModalPositionEnum.CENTER}
      className="bg-background bg-opacity-75"
    >
      <div className="w-[592px] p-[20px] sm:p-[30px]">
        <TextWithLeftColorLine colorClass="bg-red">
          <p className="text-white text-[12px] sm:text-[16px] mb-[20px] text-justify">
            {t('modals.notWorkingLocalStorage.oops')}
          </p>
          <p className="text-white text-[12px] sm:text-[16px] text-justify">
            {t('modals.notWorkingLocalStorage.tryAnother')}
          </p>
          <p className="text-white text-[12px] sm:text-[16px] text-justify">
            {t('modals.notWorkingLocalStorage.discord')}
          </p>
        </TextWithLeftColorLine>
      </div>
    </ModalWithFullScreenBg>
  );
};

export default NotWorkingLocalStorageModal;
