import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface State {
  lobby: {
    createGameOpen: boolean;
  };
}

interface Actions {
  setLobbyCreateGameOpen: (open: boolean) => void;
}

const initialState: State = {
  lobby: {
    createGameOpen: false,
  },
};

export const useUIStore = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      ...initialState,
      setLobbyCreateGameOpen: (open) => {
        set((state) => {
          state.lobby.createGameOpen = open;
        });
      },
    })),
    { name: 'uiStore' },
  ),
);
