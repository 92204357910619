import * as React from 'react';

import { Connector } from 'wagmi';

export function ConnectorButton({
  connector,
  onClick,
}: {
  connector: Connector;
  onClick: () => void;
}) {
  const [ready, setReady] = React.useState(false);
  React.useEffect(() => {
    if (connector.provider) {
      (async () => {
        const provider = await connector.getProvider();
        setReady(!!provider);
      })();
    } else {
      setReady(true);
    }
  }, [connector, setReady]);

  return (
    <button
      className={`flex ${
        !!connector.getProvider ? 'flex-col' : 'flex-row'
      } text-white justify-center sm:justify-start items-center py-2 px-1 sm:p-4 gap-1 sm:gap-4 text-[10px] sm:text-[24px] bg-move-icon-content rounded-lg sm:rounded-3xl border-[1px] border-border`}
      disabled={!ready}
      onClick={onClick}
      type="button"
    >
      {connector.icon && (
        <img
          src={connector.icon}
          alt={connector.name}
          className="w-8 h-8 sm:w-[56px] sm:h-[56px]"
        />
      )}
      {connector.name}
    </button>
  );
}
