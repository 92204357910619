import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTimerLogic } from 'modules/Room/game-status/components/play-game-statuses/hooks/use-timer-logic';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import DigitalClock from 'shared/components/ui/DigitalClock/DigitalClock';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useRoomStore } from 'shared/store/roomStore';
import { epochToLocalUTC } from 'shared/utils/time';

export const WaitOpponentRevealStatus = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [technicalDefeatError, setTechnicalDefeatError] = useState(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { closerRoom } = useRoomStore();

  const { isSuccess, closeUnusedRoomHandler, onExpire } = useTimerLogic({
    selector: isMobile ? '.player-card-host' : '.player-card-opponent',
    gameStatusName: 'waitOpponentReveal',
    setShowTimer,
    setShowCloseButton,
    setTechnicalDefeatError,
  });

  return (
    <>
      <AnimatedTransition show={!showTimer && !showCloseButton && !technicalDefeatError}>
        <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
          {t('modals.waitOpponentReveal.youMadeMove')}
        </p>
        <p className="text-white text-[10px] sm:text-[16px]  text-justify">
          {t('modals.waitOpponentReveal.waiting')}
        </p>
      </AnimatedTransition>
      <AnimatedTransition show={showTimer}>
        <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
          {t('modals.waitOpponentReveal.youMadeMove')}
        </p>
        <p className="text-white text-[10px] sm:text-[16px] mb-[10px] text-justify">
          {t('modals.waitOpponentReveal.waiting')}
        </p>
        <p className="text-white text-[10px] sm:text-[16px] text-justify">
          {t('modals.waitOpponentReveal.patience')}
        </p>
      </AnimatedTransition>
      <AnimatedTransition show={showCloseButton}>
        <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
          {t('modals.waitOpponentReveal.gameOver')}
        </p>
        <p className="text-white text-[10px] sm:text-[16px] text-justify">
          {t('modals.waitOpponentReveal.technicalDefeatExplanation')}
        </p>
      </AnimatedTransition>
      <AnimatedTransition show={technicalDefeatError}>
        <p className="text-[14px] sm:text-[24px] mb-[10px] text-red font-bold">
          {t('modals.waitOpponentReveal.techDefeatError')}
        </p>
        <p className="text-white text-[10px] sm:text-[16px] text-justify">
          {t('modals.waitOpponentReveal.techDefeatErrorExplanation')}
        </p>
      </AnimatedTransition>

      <AnimatedTransition show={showCloseButton && !isSuccess}>
        <div className="flex justify-center mt-[20px]">
          <PrimaryButton text={'common.technicalDefeat'} onClick={closeUnusedRoomHandler} />
        </div>
      </AnimatedTransition>
      <AnimatedTransition show={showTimer && !showCloseButton}>
        <div className="w-full flex justify-center mt-[24px]">
          <DigitalClock
            key={(epochToLocalUTC(closerRoom?.deadline) as Date).toString()}
            expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
            className="w-fit"
            onExpire={onExpire}
          />
        </div>
      </AnimatedTransition>
    </>
  );
};
