import type { PublicClient, WalletClient } from 'viem';
import create from 'zustand';

import {
  approveHandler,
  createRoom,
  getAllowance,
  joinRoom,
} from 'shared/viem-functions/start-game';

let fullNumber = BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');

interface AllowanceStore {
  currentAllowance: number;
  txHash: `0x${string}` | null;
  isFetching: boolean;
  isApproving: boolean;
  isStarting: boolean;
  isJoining: boolean;
  error: string | null;
  fetchCurrentAllowance: (
    ownerAddress: `0x${string}`,
    currency: { address: `0x${string}`; decimals: number },
    provider: PublicClient,
  ) => Promise<void>;
  approveAllowance: (params: {
    amount: number;
    currency: { address: `0x${string}`; decimals: number };
    userAddress: `0x${string}`;
    walletClient: WalletClient;
    publicClient: PublicClient;
    fullAllowance: boolean;
    joinGame: boolean;
  }) => Promise<void>;
  startGame: (params: {
    wins: number;
    currency: `0x${string}`;
    betAmount: bigint;
    playerB: `0x${string}`;
    referrer: `0x${string}`;
    walletClient: WalletClient;
    publicClient: PublicClient;
    userAddress: `0x${string}`;
  }) => Promise<number | undefined>;
  joinRoom: (params: {
    roomId: bigint;
    referrer: `0x${string}`;
    walletClient: WalletClient;
    publicClient: PublicClient;
    userAddress: `0x${string}`;
  }) => Promise<void>;
}

interface LogWithTopics {
  topics: string[];
}

export const useStartGameStore = create<AllowanceStore>((set, get) => ({
  currentAllowance: 0,
  txHash: null,
  isFetching: false,
  isApproving: false,
  isStarting: false,
  isJoining: false,
  error: null,
  fetchCurrentAllowance: async (ownerAddress, currency, provider) => {
    set({ isFetching: true, error: null });
    try {
      const allowance = await getAllowance({
        ownerAddress,
        tokenAddress: currency.address,
        provider,
      });
      set({ currentAllowance: Number(allowance) / 10 ** currency.decimals });
    } catch (err: any) {
      console.error('Error fetching allowance:', err);
      set({ error: err.message || 'Error fetching allowance' });
    } finally {
      set({ isFetching: false });
    }
  },
  approveAllowance: async ({
    amount,
    currency,
    userAddress,
    walletClient,
    publicClient,
    fullAllowance,
    joinGame,
  }) => {
    if (!walletClient || !publicClient) return;
    set({ isApproving: true, error: null });
    try {
      if (get().currentAllowance === 0) {
        try {
          const txHash = await approveHandler({
            amount: fullAllowance ? fullNumber : BigInt(amount * 10 ** currency.decimals),
            functionName: 'approve',
            currencyAddress: currency.address,
            userAddress,
            walletClient,
            publicClient,
          });
          set({ txHash });
        } catch (error) {
          console.error('Error in approve (allowance 0):', error);
          throw error;
        }
      } else if (amount > get().currentAllowance) {
        const amountToIncrease = amount - get().currentAllowance;
        try {
          const txHash = await approveHandler({
            amount: fullAllowance ? fullNumber : BigInt(amountToIncrease * 10 ** currency.decimals),
            functionName: 'increaseAllowance',
            currencyAddress: currency.address,
            userAddress,
            walletClient,
            publicClient,
          });
          set({ txHash });
        } catch (error) {
          let txHash = await approveHandler({
            amount: 0n,
            functionName: 'approve',
            currencyAddress: currency.address,
            userAddress,
            walletClient,
            publicClient,
          });
          set({ txHash });
          txHash = await approveHandler({
            amount: fullAllowance ? fullNumber : BigInt(amount * 10 ** currency.decimals),
            functionName: 'approve',
            currencyAddress: currency.address,
            userAddress,
            walletClient,
            publicClient,
          });
          set({ txHash });
        }
      }
    } catch (err: any) {
      console.error('Error approving allowance:', err);
      set({ error: err.message || 'Error approving allowance' });
    } finally {
      if (get().txHash) {
        try {
          const receipt = await publicClient.waitForTransactionReceipt({
            hash: get().txHash!,
          });
          if (receipt.status !== 'success') {
            console.error('Transaction failed:', receipt);
          }
        } catch (waitError) {
          console.error('Error waiting for transaction receipt:', waitError);
        } finally {
          if (!joinGame) {
            await get().fetchCurrentAllowance(userAddress, currency, publicClient);
          }

          set({ isApproving: false, txHash: null });
        }
      } else {
        console.error('No transaction hash available.');
        set({ isApproving: false });
      }
    }
  },
  startGame: async ({
    wins,
    currency,
    betAmount,
    playerB,
    referrer,
    walletClient,
    publicClient,
    userAddress,
  }) => {
    if (!walletClient || !publicClient) return;
    set({ isStarting: true, error: null });
    try {
      const txHash = await createRoom({
        wins,
        currency,
        betAmount,
        playerB,
        referrer,
        walletClient,
        publicClient,
        userAddress,
      });
      set({ txHash });
    } catch (err: any) {
      console.error('Error starting game:', err);
      set({ error: err.message || 'Error starting game' });
    } finally {
      if (get().txHash) {
        try {
          const receipt = await publicClient.waitForTransactionReceipt({
            hash: get().txHash!,
          });
          const logs = (receipt?.logs as unknown as LogWithTopics[]) ?? [];
          const roomCreatedLogs = logs.filter(
            (log) =>
              log?.topics?.[0] ===
              '0x28b80f5ae6f5608b077d8b1643d65d8324aad5b952fec8b9ae8fddb6de866565',
          );
          const hexString = roomCreatedLogs?.[0]?.topics?.[1]?.slice(2) ?? '0';
          const parsed = parseInt(hexString, 16);
          console.log('this is parsed room id', parsed);
          if (receipt.status !== 'success') {
            console.error('Start game transaction failed:', receipt);
          }
          return parsed;
        } catch (waitError) {
          console.error('Error waiting for start game transaction receipt:', waitError);
        } finally {
          set({ isStarting: false, txHash: null });
        }
      } else {
        console.error('No transaction hash available for startGame.');
        set({ isStarting: false });
      }
    }
  },
  joinRoom: async ({ roomId, referrer, walletClient, publicClient, userAddress }) => {
    if (!walletClient || !publicClient) return;
    set({ isJoining: true, error: null });
    try {
      const txHash = await joinRoom({
        roomId,
        referrer,
        walletClient,
        publicClient,
        userAddress,
      });
      set({ txHash });
    } catch (err: any) {
      console.error('Error joining room:', err);
      set({ error: err.message || 'Error joining room' });
      throw err;
    } finally {
      if (get().txHash) {
        try {
          const receipt = await publicClient.waitForTransactionReceipt({
            hash: get().txHash!,
          });
          if (receipt.status !== 'success') {
            console.error('Join room transaction failed:', receipt);
          }
        } catch (waitError) {
          console.error('Error waiting for join room transaction receipt:', waitError);
          throw waitError;
        } finally {
          set({ isJoining: false, txHash: null });
        }
      } else {
        console.error('No transaction hash available for joinRoom.');
        set({ isJoining: false });
      }
    }
  },
}));
