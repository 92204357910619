import React, { Fragment } from 'react';

import { Transition } from '@headlessui/react';

interface Props {
  show: boolean;
  children: React.ReactNode;
  asFragment?: boolean;
  className?: string;
  appear?: boolean;
}

const AnimatedTransition = ({
  show,
  children,
  asFragment = false,
  appear = false,
  className,
  ...props
}: Props) => {
  return (
    <Transition
      show={show}
      enter="transition-all ease-in-out duration-500"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-content"
      leave="transition-all ease-in-out duration-100"
      leaveFrom="opacity-100 max-h-[100vh]"
      leaveTo="opacity-0 max-h-0"
      as={asFragment ? Fragment : undefined}
      appear={appear}
      className={className ?? ''}
      {...props}
    >
      {children}
    </Transition>
  );
};

export default AnimatedTransition;
