import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import { useAccount } from 'wagmi';

import { useMoveHandler } from 'modules/Room/Hooks/useMoveHandler';
import { NULL_MOVE } from 'modules/Room/constants/constants';
import { CardPlayerEnum, LoadersStateEnum } from 'modules/Room/constants/enums';
import { RockPaperScissors } from 'modules/Room/features/RockPaperScissors/RockPaperScissors';
import { GameStatusEnum, MintingEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { GameStatus } from 'modules/Room/game-status/game-statuses';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { Avatar } from 'shared/components/ui/Avatar/Avatar';
import GuysLoader from 'shared/components/ui/Loaders/GuysLoader/GuysLoader';
import MintingLoader from 'shared/components/ui/Loaders/MintingLoader/MintingLoader';
import RockScissorsPaperLoader from 'shared/components/ui/Loaders/RockScissorsPaperLoader/RockScissorsPaperLoader';
import WaitingMan from 'shared/components/ui/Loaders/WaitingMan/WaitingMan';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { MovesEnum, RoomStatusEnum, WinnerStatusEnum } from 'shared/constants/shared-enums';
import {
  lookingForOpponent,
  madeMoveMessages,
  madeRevealMessages,
} from 'shared/constants/text-loaders';
import {
  takeMoneyBackMessages,
  technicalDefeatMessages,
  waitYourMoveMessages,
  waitYourRevealMessages,
} from 'shared/constants/text-loaders';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';

import ZIcon from 'assets/animation/sleep/Z.svg?react';
import HeartEmpty from 'assets/heart-black.svg?react';
import Heart from 'assets/heart-filled.svg?react';
import defeat from 'assets/png/defeat.png';
import victory from 'assets/png/victory.png';

import './PlayerCard.css';

interface Props {
  playerAddress: `0x${string}`;
  position: string;
  winsTo: number;
}

export const PlayerCard: FC<Props> = ({ playerAddress, position, winsTo }) => {
  const { activeRoomId } = useParams();
  const [iAmWinner, setIAmWinner] = useState<boolean>(false);
  const [iAmLooser, setIAmLooser] = useState<boolean>(false);
  const [amIHost, setAmIHost] = useState<boolean>(false);
  const [loadersState, setLoadersState] = useState<LoadersStateEnum>(LoadersStateEnum.None);

  const { address, chainId } = useAccount();

  useEffect(() => {
    setAmIHost(playerAddress.toLowerCase() === address?.toLowerCase());
  }, [playerAddress, address]);
  const [isShowEndGameAnimation, setIsShowEndGameAnimation] = useState<boolean>(false);
  const {
    amIPlayerA,
    someoneElseGame,
    roundCounter,
    playerA,
    playerB,
    roomStatus,
    winnerStatus,
    roundMoves,
  } = useRoomStore();

  const { gameStatus, resetGameStatus, mintingLoader, mintingHash } = useGameStatusStore();
  let sleepTimeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const clearCurrentTimeout = () => {
      if (sleepTimeoutRef.current) {
        clearTimeout(sleepTimeoutRef.current);
      }
    };

    const setPlayerLoaderState = (opponentMove: `0x${string}`) => {
      if (opponentMove === NULL_MOVE) {
        setLoadersState(LoadersStateEnum.ChooseMove);
      } else {
        setLoadersState(LoadersStateEnum.MoveDone);
      }
    };

    const setRevealLoaderState = (opponentEncrMove: MovesEnum) => {
      if (opponentEncrMove === MovesEnum.None) {
        setLoadersState(LoadersStateEnum.Reveal);
      } else {
        setLoadersState(LoadersStateEnum.RevealDone);
      }
    };

    clearCurrentTimeout();

    if (
      gameStatus === GameStatusEnum.WAIT_YOUR_MOVE ||
      gameStatus === GameStatusEnum.WAIT_YOUR_APPROVE
    ) {
      if (amIPlayerA) {
        setPlayerLoaderState(roundMoves.playerB.encrMove);
      } else {
        setPlayerLoaderState(roundMoves.playerA.encrMove);
      }
    } else if (
      gameStatus === GameStatusEnum.WAIT_OPPONENT_MOVE ||
      gameStatus === GameStatusEnum.WAIT_OPPONENT_REVEAL
    ) {
      setLoadersState(LoadersStateEnum.None);
      sleepTimeoutRef.current = setTimeout(() => {
        setLoadersState(LoadersStateEnum.Sleep);
      }, 40000);
    } else if (gameStatus === GameStatusEnum.WAIT_YOUR_REVEAL) {
      if (amIPlayerA) {
        setRevealLoaderState(roundMoves.playerB.move);
      } else {
        setRevealLoaderState(roundMoves.playerA.move);
      }
    } else if (!amIHost && roomStatus === RoomStatusEnum.Open) {
      setLoadersState(LoadersStateEnum.LookingOpponent);
    } else {
      setLoadersState(LoadersStateEnum.None);
    }
    return clearCurrentTimeout;
  }, [gameStatus, amIPlayerA, roundMoves, roomStatus, amIHost]);

  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed) {
      if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerA
      ) {
        setIAmWinner(playerAddress === playerA.address);
        setIAmLooser(playerAddress === playerB.address);
        if (amIHost) {
          resetGameStatus();
          setIsShowEndGameAnimation(true);
        }
      } else if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerB
      ) {
        setIAmWinner(playerAddress === playerB.address);
        setIAmLooser(playerAddress === playerA.address);
        if (amIHost) {
          resetGameStatus();
          setIsShowEndGameAnimation(true);
        }
      }
    }
  }, [winnerStatus, roomStatus, playerA, playerB, playerAddress, amIHost]);

  useEffect(() => {
    if (!activeRoomId || !winnerStatus) {
      setIAmWinner(false);
      setIAmLooser(false);
    }
  }, [activeRoomId, winnerStatus]);

  const { t } = useTranslation();

  const { playMoves } = usePlayerGamesStore();

  const selectMoveHandler = useMoveHandler();
  const playerName = () => {
    if (someoneElseGame) {
      if (playerB.address === playerAddress) {
        return 'common.playerB';
      } else {
        return 'common.playerA';
      }
    } else if (amIHost) {
      return 'common.you';
    } else if (!activeRoomId && playerAddress === NULL_ADDRESS) {
      return 'common.noOpponent';
    } else if (playerAddress === NULL_ADDRESS) {
      return 'common.connecting';
    } else {
      return 'common.opponent';
    }
  };

  const showAnimation = () => {
    if (roomStatus === RoomStatusEnum.Closed) return false;
    if (mintingLoader !== MintingEnum.NONE) return true;
    if (!amIHost && loadersState === LoadersStateEnum.ChooseMove) return true;
    if (!amIHost && loadersState === LoadersStateEnum.Reveal) return true;
    if (
      !amIHost &&
      loadersState === LoadersStateEnum.LookingOpponent &&
      playerA.address === address?.toLowerCase()
    )
      return true;
    if (
      !amIHost &&
      (loadersState === LoadersStateEnum.MoveDone || loadersState === LoadersStateEnum.RevealDone)
    )
      return true;
    return false;
  };

  const renderAnimation = () => {
    if (!amIHost && loadersState === LoadersStateEnum.ChooseMove)
      return (
        <RockScissorsPaperLoader
          messages={['common.pickingHand']}
          className={'mt-10 xlg:mt-0 flex justify-center items-center px-5'}
        />
      );
    if (!amIHost && loadersState === LoadersStateEnum.Reveal)
      return (
        <MintingLoader
          messages={['common.revealing']}
          className={'mt-10 xlg:mt-0 flex justify-center items-center px-5'}
        />
      );
    if (
      !amIHost &&
      (loadersState === LoadersStateEnum.MoveDone || loadersState === LoadersStateEnum.RevealDone)
    )
      return (
        <WaitingMan
          messages={
            loadersState === LoadersStateEnum.MoveDone ? madeMoveMessages : madeRevealMessages
          }
          className={'mt-10 xlg:mt-0 flex justify-center items-center px-5'}
        />
      );
    if (!amIHost && loadersState === LoadersStateEnum.LookingOpponent)
      return <GuysLoader className="w-full mx-auto " messages={lookingForOpponent} />;
    if (amIHost && mintingLoader === MintingEnum.TECHNICAL_DEFEAT) {
      return <MintingLoader hash={mintingHash} messages={technicalDefeatMessages} />;
    }
    if (amIHost && mintingLoader === MintingEnum.YOUR_MOVE) {
      return (
        <MintingLoader
          className={'mt-10 xlg:mt-0 flex justify-center items-center px-5'}
          hash={mintingHash}
          messages={waitYourMoveMessages}
        />
      );
    }
    if (amIHost && mintingLoader === MintingEnum.YOUR_REVEAL) {
      return (
        <MintingLoader
          className={'mt-10 xlg:mt-0 flex justify-center items-center px-5'}
          hash={mintingHash}
          messages={waitYourRevealMessages}
        />
      );
    }
    if (amIHost && mintingLoader === MintingEnum.TAKE_MONEY_BACK) {
      return (
        <MintingLoader
          className={'mt-10 xlg:mt-0 flex justify-center items-center px-5'}
          hash={mintingHash}
          messages={takeMoneyBackMessages}
        />
      );
    }
  };
  const renderGameResultImage = () => {
    if (!amIHost || !isShowEndGameAnimation) {
      return null;
    }

    setTimeout(() => {
      setIsShowEndGameAnimation(false);
    }, 6000);

    if (!amIPlayerA) {
      if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerB
      ) {
        return (
          <img
            src={victory}
            alt="victory image"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
          />
        );
      }

      if (
        winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
        winnerStatus === WinnerStatusEnum.TechnicalWinnerA
      ) {
        return (
          <img
            src={defeat}
            alt="defeat image"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
          />
        );
      }
    }

    if (
      winnerStatus === WinnerStatusEnum.WinnerPlayerA ||
      winnerStatus === WinnerStatusEnum.TechnicalWinnerA
    ) {
      return (
        <img
          src={victory}
          alt="victory image"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
        />
      );
    }

    if (
      winnerStatus === WinnerStatusEnum.WinnerPlayerB ||
      winnerStatus === WinnerStatusEnum.TechnicalWinnerB
    ) {
      return (
        <img
          src={defeat}
          alt="defeat image"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
        />
      );
    }

    return null;
  };

  const getRemainingWins = () => {
    let wins = winsTo ?? 3;
    if (someoneElseGame) {
      if (playerName() === 'common.playerA') {
        return wins - Number(playerB.wins);
      } else if (playerName() === 'common.playerB' && playerB.address !== NULL_ADDRESS) {
        return wins - Number(playerA.wins);
      }
    } else if (amIHost) {
      return wins - Number(amIPlayerA ? playerB.wins : playerA.wins);
    } else if (activeRoomId && playerB.address !== NULL_ADDRESS) {
      return wins - Number(amIPlayerA ? playerA.wins : playerB.wins);
    }
    return null;
  };
  const remainingWins = getRemainingWins();
  return (
    <div
      className={`
        flex flex-col w-full h-full items-center py-4 rounded-[22px] 
        border border-border transition bg-opacity-30 bg-background 
         relative watch-game-card 
        ${amIHost ? 'player-card-host' : 'player-card-opponent'}
        ${iAmWinner ? 'player-card-victory' : ''}
        ${iAmLooser ? 'player-card-lose' : ''}
       backdrop-blur-xl
      `}
      style={{
        background: 'rgba(5, 4, 57, 0.6)',
      }}
    >
      <div className="flex flex-col items-center">
        <div className="flex flex-row mb-[10px]">
          {playerAddress !== NULL_ADDRESS ? (
            <Avatar
              userAddress={playerAddress}
              className={`w-[64px] h-[64px] 2xl:w-[97px] 2xl:h-[97px]`}
            />
          ) : (
            <div className="w-[97px] h-[97px] flex justify-center items-center rounded-[12px] bg-deep-blue">
              <span className="text-blue text-[58px]">?</span>
            </div>
          )}
        </div>
        <AnimatedTransition show={!amIHost && loadersState === LoadersStateEnum.Sleep}>
          <div className="absolute top-[16px] left-1/2 transform -translate-x-1/2 z-10">
            <ZIcon className="zIcon absolute top-5 left-10" />
            <ZIcon className="zIcon absolute top-5 left-10" />
            <ZIcon className="zIcon absolute top-5 left-10" />
          </div>
        </AnimatedTransition>

        <div className="background-dark-background border border-border rounded-lg px-[32px] py-[3px]">
          <span
            className="text-yellow text-[20px]"
            style={{ opacity: playerAddress === NULL_ADDRESS ? 0 : 1 }}
          >
            {playerAddress.substring(0, 6)}......
            {playerAddress.substring(playerAddress.length - 4)}
          </span>
        </div>

        <div className="background-dark-background border border-border rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg px-[32px] py-[3px] border-t-0">
          <span className="text-gray text-[14px]">{t(playerName())}</span>
        </div>
        <div
          className={classNames(
            'flex gap-4 text-white mt-4',
            remainingWins !== null ? 'animate-fast-appear' : '',
            position === 'order-3' ? 'transform scale-x-[-1]' : '',
          )}
        >
          {remainingWins !== null &&
            Array.from({ length: remainingWins }).map((_, index) => (
              <Heart key={`heart-${index}`} className="w-[44px] h-[40px] hd:w-[66px] hd:h-[60px]" />
            ))}
          {remainingWins !== null &&
            Array.from({ length: winsTo - remainingWins }).map((_, index) => (
              <HeartEmpty
                key={`heart-empty-${index}`}
                className="w-[44px] h-[40px] hd:w-[66px] hd:h-[60px]"
              />
            ))}
        </div>
        {renderGameResultImage()}
      </div>
      <AnimatedTransition
        show={showAnimation()}
        className={`hd:absolute hd:top-1/2 hd:left-1/2 hd:transform hd:-translate-x-1/2 hd:-translate-y-1/2`}
      >
        {renderAnimation()}
      </AnimatedTransition>
      <GameStatus
        player={
          amIHost
            ? CardPlayerEnum.Player
            : playerAddress === NULL_ADDRESS
              ? CardPlayerEnum.Connect
              : CardPlayerEnum.Opponent
        }
        isShowEndGameAnimation={isShowEndGameAnimation || mintingLoader !== MintingEnum.NONE}
      />
      <AnimatedTransition
        show={
          Boolean(
            amIHost &&
              activeRoomId &&
              playerB.address !== NULL_ADDRESS &&
              roomStatus !== RoomStatusEnum.Closed &&
              roomStatus !== RoomStatusEnum.Open,
          ) || false
        }
        asFragment
      >
        <div className="self-end mt-auto flex items-center justify-around w-full">
          <RockPaperScissors
            move={playMoves?.[activeRoomId! + address + chainId + roundCounter]?.move}
            selectMove={selectMoveHandler}
          />
        </div>
      </AnimatedTransition>
    </div>
  );
};
