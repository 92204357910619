import { Debug } from '../utils/debug';
import { Assets } from 'pixi.js';

type Asset = {
  name: string;
  url: string;
  ext: string;
  category: string;
  group: string;
};

export default class AssetLoader {
  private assetFileUrls = this.importAssetFiles();

  manifest: Asset[];

  constructor() {
    this.manifest = this.generateManifest();
  }

  importAssetFiles() {
    const assetFiles = import.meta.glob('/public/**/*.*');

    return Object.keys(assetFiles);
  }

  /**
   * Load assets for a specific group.
   * @param group - The group name to load assets for.
   * @param onProgress - Optional progress callback.
   * @returns A promise that resolves when all assets are loaded.
   */
  async loadAssetsGroup(group: string, onProgress?: (progress: number) => void) {
    const sceneAssets = this.manifest.filter((asset) => asset.group === group);

    // Add assets to the loader
    for (const asset of sceneAssets) {
      if (!Assets.cache.has(asset.name)) {
        Assets.add({ alias: asset.name, src: asset.url });
      }
    }

    // Load assets
    const resources = await Assets.load(
      sceneAssets.map((asset) => {
        Debug.log(`Loading asset: name=${asset.name}, url=${asset.url}`);
        return asset.name;
      }),
      onProgress,
    );

    Debug.log('✅ Loaded assets group', group, resources);

    return resources;
  }
  /**
   * Load Spine assets for a specific group.
   * Spine assets typically include `.json`, `.atlas`, and texture files.
   * @param group - The group name to load Spine assets for.
   * @param onProgress - Optional progress callback.
   * @returns A promise that resolves when all Spine assets are loaded.
   */
  async loadSpineAssetsGroup(group: string, onProgress?: (progress: number) => void) {
    const spineAssets = this.manifest.filter(
      (asset) => asset.group === group && asset.category === 'spine',
    );

    // Add Spine assets to the loader
    for (const asset of spineAssets) {
      if (!Assets.cache.has(asset.name)) {
        Assets.add({ alias: asset.name, src: asset.url });
      }
    }
    //TODO: asset load manifest
    await Assets.load([
      {
        alias: 'objSkeleton',
        src: '/game/spine/hero.json',
      },
      {
        alias: 'objAtlas',
        src: '/game/spine/hero.atlas',
      },
    ]);
    // Load Spine assets
    const resources = await Assets.load(
      spineAssets.map((asset) => {
        Debug.log(`Loading Spine asset: name=${asset.name}, url=${asset.url}`);
        return { alias: asset.name, src: asset.url };
      }),
      onProgress,
    );

    Debug.log('✅ Loaded Spine assets group', group, resources);

    return resources;
  }

  /**
   * Generate the asset manifest from the imported asset files.
   * @returns The generated asset manifest.
   */
  generateManifest() {
    const assetsManifest: Asset[] = [];
    const assetPathRegexp =
      /public\/(?<group>[\w.-]+)\/(?<category>[\w.-]+)\/(?<name>[\w.-]+)\.(?<ext>\w+)$/;

    this.assetFileUrls.forEach((assetPath) => {
      const match = assetPathRegexp.exec(assetPath);

      if (!match || !match.groups) {
        Debug.error(`Invalid asset path: ${assetPath}, should match ${assetPathRegexp}`);
        return;
      }

      const { group, category, name, ext } = match.groups;

      // Skip image files in the spine or spritesheets category
      if (category === 'spritesheets' && ext !== 'json') {
        return;
      }

      if (category === 'spine' && ext !== 'json' && ext !== 'skel') {
        return;
      }

      assetsManifest.push({
        group,
        category,
        name,
        ext,
        url: assetPath.replace(/.*public/, ''),
      });
    });

    return assetsManifest;
  }
}
