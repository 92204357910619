import React, { useEffect, useState } from 'react';

import { useAccount } from 'wagmi';

import { useGlobalStore } from 'shared/store/globalStore';

export const useShowSignModal = () => {
  const [showSignModal, setShowSignModal] = useState(false);
  const { jwt, jwtValidUntil } = useGlobalStore();
  const { address } = useAccount();
  useEffect(() => {
    const now = new Date().getTime();
    if (
      (address && jwtValidUntil[address] && now > jwtValidUntil[address]) ||
      (address && !jwt[address])
    ) {
      setShowSignModal(true);
    } else {
      setShowSignModal(false);
    }
  }, [jwt, address, jwtValidUntil]);
  return { showSignModal };
};
