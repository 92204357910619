import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { useAccount, useBlockNumber } from 'wagmi';

import useRevealLogic from 'modules/Room/Hooks/useRevealLogic';
import { useRoundInfo } from 'modules/Room/Hooks/useRoundInfo';

import { CHAINS_IDS } from 'shared/config';
import { ROUTES } from 'shared/constants';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { CloserEnum, RoomStatusEnum } from 'shared/constants/shared-enums';
import { useReadRspContractRooms } from 'shared/features/contract.abi';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useGlobalStore } from 'shared/store/globalStore';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { useTimersStore } from 'shared/store/timersStore';
import { epochToLocalUTC } from 'shared/utils/time';

export const useActiveRoom = () => {
  const { activeRoomId } = useParams();
  useRoundInfo();
  useRevealLogic();
  const { addActiveGame, removeActiveGame, activeGames } = useGlobalStore();

  const {
    setRoom,
    setRefetchRoom,
    setRoomsDataIsFetching,
    roomStatus,
    refetchRoom,
    joinRoomId,
    setJoinRoomId,
    playerA,
    playerB,
    someoneElseGame,
  } = useRoomStore();
  const { address, chainId } = useAccount();
  const { data: lastBlock, dataUpdatedAt: lastBlockUpdatedAt } = useBlockNumber({
    cacheTime: 0,
  });
  useEffect(() => {
    if (lastBlock) {
      Sentry.setTag('blockNumber', lastBlock);
      Sentry.setTag('blockUpdatedAt', lastBlockUpdatedAt);
    }
  }, [lastBlock, lastBlockUpdatedAt]);
  const { data, dataUpdatedAt, refetch, error, isFetching } = useReadRspContractRooms({
    args: [BigInt(activeRoomId ?? 0)],
    query: {
      enabled: !!activeRoomId,
    },
  });

  useEffect(() => {
    if (activeRoomId) {
      if (
        (playerA.address === address?.toLowerCase() ||
          playerB.address === address?.toLowerCase()) &&
        roomStatus !== RoomStatusEnum.Closed &&
        !isFetching &&
        CHAINS_IDS.includes(chainId as 1 | 42161 | 137 | 8453) &&
        activeGames[chainId! + address.toLowerCase()] !== activeRoomId
      ) {
        addActiveGame(chainId! + address.toLowerCase(), activeRoomId!);
      }
    }
  }, [activeRoomId, playerA, playerB, isFetching, roomStatus, chainId, address, activeGames]);
  const { setSelectedCurrency } = useCurrenciesStore();

  const availableCurrencies = useCurrenciesStore((state) => state.availableCurrencies);
  const navigate = useNavigate();
  const setRoomValues = useCallback(
    (dataFromContract: any) => {
      const [data, pot, roundCounter, winsCounter, closer, deadline, status, winnerStatus] =
        dataFromContract;
      if (
        data?.playerA.toLowerCase() === NULL_ADDRESS &&
        data?.playerB.toLowerCase() === NULL_ADDRESS
      ) {
        removeActiveGame(`${chainId}${address?.toLowerCase()}`);
        Sentry.captureException(new Error('Room not found'));
        navigate(ROUTES.LOBBY);
        return;
      }
      if (
        activeGames[chainId! + address.toLowerCase()] === activeRoomId &&
        data?.playerB.toLowerCase() !== NULL_ADDRESS &&
        status === RoomStatusEnum.Closed
      ) {
        if (
          typeof window !== 'undefined' &&
          window?.sa_event &&
          typeof window.sa_event === 'function'
        ) {
          const key = `${activeRoomId}${address}${chainId}`;
          useTimersStore.getState().deleteAllTimersForThisKey(key);
          usePlayerGamesStore.getState().deletePlayMove(key);
          removeActiveGame(`${chainId}${address?.toLowerCase()}`);
          try {
            window.sa_event('success-game', { wallet: address.substring(2) });
          } catch (e) {
            console.error('Error sending event to Simple Analytics:', e);
          } finally {
            console.log('Simple Analytics event sent successfully');
          }
          // Create and inject the script dynamically
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.text = `(function(){
  "use strict";
  function n(n,e){
    var r;
    void 0===e&&(e="uclick");
    var c=null===(r=n.match(/\\?.+?$/))||void 0===r?void 0:r[0];
    return c?Array.from(c.matchAll(new RegExp("[?&](clickid|"+e+")=([^=&]*)","g")))
      .map((function(n){return {name:n[1], value:n[2]};})):[];
  }
  function e(n){
    var e=n();
    return 0===e.length?{}:e.reduce((function(n,e){
      var r;
      return Object.assign(n, ((r={})[e.name]=""+e.value, r));
    }),{});
  }
  function r(r){
    void 0===r&&(r="uclick");
    var c,t,u;
    var uObj = e(function(){
      return (function(n){
        return void 0===n&&(n="uclick"),
        Array.from(document.cookie.matchAll(new RegExp("(?:^|; )(clickid|"+n+")=([^;]*)","g")))
          .map((function(n){return {name:n[1], value:n[2]};}));
      })(r);
    });
    var iObj = e(function(){ return n(document.referrer, r); });
    var oObj = e(function(){ return n(document.location.search, r); });
    return ([r, "clickid"]).reduce((acc, key) => {
      return acc.concat([uObj, iObj, oObj].map(item => [key, item]));
    }, [])
    .map(([key, item]) => ({ name: key, value: item[key] }))
    .find(item => item.value) || null;
  }
  var c, t, u, i;
  i = document.createElement("img");
  t = "http://rentvillastracker.cc/" + "ct2vl5k" + ".php?payout=OPTIONAL";
  var clickObj = r("uclick");
  if (clickObj) {
    t += "&cnv_id=" + (clickObj.name === "uclick" ? "OPTIONAL" : clickObj.value);
    if(clickObj.name === "uclick"){
      t += "&uclick=" + clickObj.value;
    }
  } else {
    t += "&cnv_id=OPTIONAL";
  }
  i.src = t;
  i.referrerPolicy = "no-referrer-when-downgrade";
})();`;

          // Append the script to the body
          document.body.appendChild(script);

          // Remove the script after a brief delay to ensure execution
          setTimeout(() => {
            document.body.removeChild(script);
          }, 100);
        } else {
          console.warn('Simple Analytics tracking function is not available.');
        }
      }

      const playerARoom = {
        address: data?.playerA.toLowerCase(),
        wins: winsCounter?.winsPlayerA,
      };

      const amIPlayerA = playerARoom.address === address?.toLowerCase();

      const iAmConnectedPlayerB =
        data?.playerB.toLowerCase() === NULL_ADDRESS && !amIPlayerA && joinRoomId;
      if (joinRoomId) setJoinRoomId(null);
      const playerBRoom = {
        address: iAmConnectedPlayerB ? address?.toLowerCase() : data?.playerB.toLowerCase(),
        wins: winsCounter?.winsPlayerB,
      };
      const someoneElseGame =
        !amIPlayerA &&
        playerBRoom.address !== address?.toLowerCase() &&
        (data?.playerB.toLowerCase() !== NULL_ADDRESS || !!winnerStatus);

      const currencyObject = availableCurrencies.find(
        (c) => c.address.toLowerCase() === data.token.toLowerCase(),
      );

      if (currencyObject) {
        setSelectedCurrency(currencyObject.symbol);
        Sentry.setTag('roomId', activeRoomId);
        Sentry.setTag('roomStatus', status);
        Sentry.setTag('wallet', address);
        Sentry.setTag('roundCounter', roundCounter);
        Sentry.setTag('winnerStatus', winnerStatus);
        Sentry.setTag('closer', closer);
        Sentry.setTag('deadline', deadline);
        Sentry.setTag('dataUpdatedAt', JSON.stringify(epochToLocalUTC(BigInt(dataUpdatedAt))));
        setRoom({
          winsTo: data.wins,
          bet: Number(Number(data.bet) / Number(10 ** currencyObject.decimals)),
          roomBalance: Number(pot) / 10 ** currencyObject.decimals,
          roomStatus: status,
          playerA: {
            ...playerARoom,
          },
          playerB: {
            ...playerBRoom,
          },
          roundCounter,
          winnerStatus,
          closerRoom: { closer: closer as CloserEnum, deadline },
          amIPlayerA,
          someoneElseGame,
        });
      }
    },
    [availableCurrencies, address],
  );

  useEffect(() => {
    if (data) {
      console.log(data);
      setRoomValues(data);
    }
  }, [data]);

  useEffect(() => {
    if (refetch) {
      setRefetchRoom(refetch);
    }
  }, [refetch]);

  useEffect(() => {
    setRoomsDataIsFetching(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (error) {
      console.log('active room error');
      Sentry.captureException(error);
    }
  }, [error]);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (activeRoomId && roomStatus !== RoomStatusEnum.Closed) {
      intervalRef.current = setInterval(() => {
        refetchRoom();
      }, 3000);
    } else {
      clearInterval(intervalRef.current!);
    }
    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [activeRoomId, roomStatus]);
};
