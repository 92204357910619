import { KeyMap } from '../components/ConfigHero';
import { Debug } from '../utils/debug';

import { MovesEnum } from 'shared/constants/shared-enums';
import { RoomData, RoundData } from 'shared/store/room-store';

// Interface for the key state
interface KeyState {
  set: boolean;
  timestamp: number;
}

interface ControllerState {
  walk_A: KeyState;
  walk_B: KeyState;
  shake_A: KeyState;
  shake_B: KeyState;
  //A
  idle_A: KeyState;
  show_rock_A: KeyState;
  show_paper_A: KeyState;
  show_scissors_A: KeyState;
  //B
  idle_B: KeyState;
  show_rock_B: KeyState;
  show_paper_B: KeyState;
  show_scissors_B: KeyState;
}

export default class Controller {
  keys: ControllerState;

  constructor() {
    this.keys = {
      //A
      walk_A: { set: false, timestamp: 0 },
      shake_A: { set: false, timestamp: 0 },
      idle_A: { set: false, timestamp: 0 },
      show_rock_A: { set: false, timestamp: 0 },
      show_paper_A: { set: false, timestamp: 0 },
      show_scissors_A: { set: false, timestamp: 0 },
      //B
      walk_B: { set: false, timestamp: 0 },
      shake_B: { set: false, timestamp: 0 },
      idle_B: { set: false, timestamp: 0 },
      show_rock_B: { set: false, timestamp: 0 },
      show_paper_B: { set: false, timestamp: 0 },
      show_scissors_B: { set: false, timestamp: 0 },
    };

    // window.addEventListener('keydown', (event) => this.keydownHandler(event));
    // window.addEventListener('keyup', (event) => this.keyupHandler(event));
  }

  clear(isAll: boolean = true, isA: boolean = true) {
    const objA = {
      walk_A: { set: false, timestamp: 0 },
      shake_A: { set: false, timestamp: 0 },
      idle_A: { set: false, timestamp: 0 },
      show_rock_A: { set: false, timestamp: 0 },
      show_paper_A: { set: false, timestamp: 0 },
      show_scissors_A: { set: false, timestamp: 0 },
    };

    const objB = {
      walk_B: { set: false, timestamp: 0 },
      shake_B: { set: false, timestamp: 0 },
      idle_B: { set: false, timestamp: 0 },
      show_rock_B: { set: false, timestamp: 0 },
      show_paper_B: { set: false, timestamp: 0 },
      show_scissors_B: { set: false, timestamp: 0 },
    };

    this.keys = Object.assign(this.keys, isAll ? Object.assign(objA, objB) : isA ? objA : objB);

    Debug.log(` clean = isAll=${isAll}, isA=${isA}`);
  }

  update(phase: string, round: number, rounds: RoundData, room: RoomData, queue: Array) {
    switch (phase) {
      case 'playersThinking':
        if (round === 1) {
          // this.playSequence(true,[this.animations.anim_walk.name,this.animations.anim_idle.name],true);
          // this.playSequence(false,[this.animations.anim_walk.name,this.animations.anim_idle.name],true);
        }
        this.clear();
        this.setKeyStateByHero(KeyMap.IDLE_A, true);
        this.setKeyStateByHero(KeyMap.IDLE_B, true);
        break;

      case 'playerAWonRound':
        this.clear();
        this.setKeyStateByHero(KeyMap.IDLE_A, true);
        this.setKeyStateByHero(KeyMap.IDLE_B, true);
        break;

      case 'onePlayerChooseMove':
        this.clear();
        this.setKeyStateByHero(KeyMap.SHAKE_A, true);
        this.setKeyStateByHero(KeyMap.SHAKE_B, true);

        // this.setKeyStateByHero(KeyMap.shake_A,true);
        // this.playSequence(true,[this.animations.anim_shake.name,this.animations.anim_idle.name],true);

        break;

      case 'twoPlayersChooseMove':
        this.clear();
        this.setKeyStateByHero(KeyMap.SHAKE_A, true);
        this.setKeyStateByHero(KeyMap.SHAKE_B, true);
        // this.playSequence(false,[this.animations.anim_shake.name,this.animations.anim_idle.name],true);
        break;

      case 'playersMadeReveal':
        // eslint-disable-next-line no-case-declarations
        const _round = rounds?.[round - 1];
        // const isPlayerAwin = _round?.winner === RoundWinnerOutcomes.PlayerA;
        // const isPlayerBwin = _round?.winner === RoundWinnerOutcomes.PlayerB;
        //_round?.playerA?.move
        let animItem_finA = KeyMap.IDLE_A;
        let animItem_finB = KeyMap.IDLE_B;

        this.clear();

        if (_round?.playerA?.move || MovesEnum.None) {
          switch (_round?.playerA?.move) {
            case MovesEnum.Paper:
              animItem_finA = KeyMap.SHOW_PAPER_A;
              break;
            case MovesEnum.Rock:
              animItem_finA = KeyMap.SHOW_ROCK_A;
              break;
            case MovesEnum.Scissors:
              animItem_finA = KeyMap.SHOW_SCISSORS_A;
              break;
            default:
              return null;
          }
          this.setKeyStateByHero(animItem_finA, true);

          //  this.playSequence(true,[this.animations.anim_shake.name,animItem_fin.name],false);
          // this.playAnimation(false, animItem_finA);
        }

        if (_round?.playerB?.move || MovesEnum.None) {
          switch (_round?.playerB?.move) {
            case MovesEnum.Paper:
              animItem_finB = KeyMap.SHOW_PAPER_B;
              break;
            case MovesEnum.Rock:
              animItem_finB = KeyMap.SHOW_ROCK_B;
              break;
            case MovesEnum.Scissors:
              animItem_finB = KeyMap.SHOW_SCISSORS_B;
              break;
            default:
              return null;
          }
          this.setKeyStateByHero(animItem_finB, true);

          //  this.playSequence(false,[this.animations.anim_shake.name, animItem_fin.name],true);
          //  this.playAnimation(false, animItem_finB);
        }
        // Debug.log(`🖥️ data playerA.move:${ _round?.playerA?.move}, playerB.move:${ _round?.playerB?.move}, animItem_finA =${JSON.stringify(animItem_finA)}, animItem_finB =${JSON.stringify(animItem_finB)}`);

        break;

      //   case 'onePlayerChooseMove':
      // break;
      default:
        // this.playAnimation(true, this.animations.anim_idle);
        // this.playAnimation(false, this.animations.anim_idle);
        break;
    }

    if (phase === 'done' /*  && queue.length !== 0 && queue.length !== 1 */) {
      // this.clear();
      // this.setKeyStateByHero(KeyMap.WALK_A, true);
      // this.setKeyStateByHero(KeyMap.WALK_B, true);
      // this.playAnimation(true, this.animations.anim_idle);
      // this.playAnimation(false, this.animations.anim_idle);
    }
  }

  playAnimation(keyName: string, newSetValue: boolean = true): void {
    this.setKeyStateByHero(keyName, newSetValue);
  }

  setKeyStateByHero(keyMapName: string, newSetValue: boolean, newTimestamp: number = 0): void {
    const now = Date.now();
    // Optimized key update
    if (this.keys[keyMapName]) {
      this.keys[keyMapName].set = newSetValue;
      this.keys[keyMapName].timestamp = now; // or newTimestamp
    } else {
      console.warn(`Key "${keyMapName}" does not exist in the keys object.`);
    }
  }

  /* 
    keydownHandler(event: KeyboardEvent): void {
        const key = keyMap[event.code];

        if (!key) return;

        const now = Date.now();

        this.keys[key].doubleTap = this.keys[key].doubleTap || now - this.keys[key].timestamp < 300;

        this.keys[key].pressed = true;
    }

    keyupHandler(event: KeyboardEvent): void {
        const key = keyMap[event.code];

        if (!key) return;

        const now = Date.now();

        this.keys[key].pressed = false;

        if (this.keys[key].doubleTap) this.keys[key].doubleTap = false;
        else this.keys[key].timestamp = now;
    } */
}
