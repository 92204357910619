import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { CardPlayerEnum } from 'modules/Room/constants/enums';
import { GameStatusEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import { NULL_ADDRESS } from 'shared/constants/constants';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { NO_PLAYER_FOUND } from 'shared/constants/timers';
import { Timer } from 'shared/constants/types';
import { useBalance } from 'shared/hooks/useBalance';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { useTimersStore } from 'shared/store/timersStore';

import { JoinGameStatus, NoOneJoinStatus } from './statuses';

interface InitGameStatusesProps {
  player?: CardPlayerEnum;
}

export const InitGameStatuses = ({ player }: InitGameStatusesProps) => {
  const timerRef = useRef<Timer | null>(null);
  const { roomStatus, roomsDataIsFetching, playerB, playerA } = useRoomStore();
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const { resetGameStatus, gameStatus, setGameStatus } = useGameStatusStore();
  const { loaderStartTime, setLoaderStartTime } = useTimersStore();
  const { selectedCurrency } = useCurrenciesStore();
  const { balance } = useBalance({
    currencyAddress: selectedCurrency?.address ?? undefined,
    walletAddress: address as `0x${string}`,
  });
  const isMobile = useMediaQuery('(max-width: 1200px)');

  useEffect(() => {
    if (
      activeRoomId &&
      ![RoomStatusEnum.ReadyForGame, RoomStatusEnum.ReadyForReveal, RoomStatusEnum.Closed].includes(
        roomStatus,
      ) &&
      !roomsDataIsFetching &&
      playerB?.address === NULL_ADDRESS &&
      playerA?.address === address?.toLowerCase()
    ) {
      if (!loaderStartTime?.[activeRoomId + address + chainId]) {
        setLoaderStartTime(activeRoomId + address + chainId, new Date());
        resetGameStatus();
        timerRef.current = setTimeout(() => {
          setGameStatus(GameStatusEnum.NO_ONE_JOIN);
        }, NO_PLAYER_FOUND);
      } else {
        const currentTime = new Date();
        const value = loaderStartTime?.[activeRoomId! + address + chainId];
        const date = value ? new Date(value) : null;
        let diff;
        if (date) diff = currentTime.getTime() - date.getTime();
        if (diff && diff > NO_PLAYER_FOUND) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.NO_ONE_JOIN);
        } else if (diff) {
          resetGameStatus();
          timerRef.current = setTimeout(() => {
            setGameStatus(GameStatusEnum.NO_ONE_JOIN);
          }, NO_PLAYER_FOUND - diff);
        }
      }
    }

    if (
      [RoomStatusEnum.ReadyForGame, RoomStatusEnum.ReadyForReveal, RoomStatusEnum.Closed].includes(
        roomStatus,
      ) &&
      loaderStartTime?.[activeRoomId! + address + chainId]
    ) {
      setLoaderStartTime(activeRoomId! + address + chainId, null);
    }
    return () => clearTimeout(timerRef.current as Timer);
  }, [selectedCurrency, balance, activeRoomId, roomStatus, roomsDataIsFetching, isMobile]);

  if (gameStatus === GameStatusEnum.NO_ONE_JOIN && (player === CardPlayerEnum.Player || !player)) {
    return <NoOneJoinStatus />;
  }

  if (
    playerB.address === NULL_ADDRESS &&
    playerA.address !== address?.toLowerCase() &&
    roomStatus === RoomStatusEnum.Open &&
    (player === CardPlayerEnum.Connect || !player)
  ) {
    return <JoinGameStatus />;
  }

  return null;
};
