import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { selectOption } from 'shared/components/ui/Select/Select';

interface Props {
  open: boolean;
  list: {
    label: string;
    value: string;
    svg?: React.ReactNode;
  }[];
  onClick: (option: selectOption) => void;
  position: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
}

const MobileDropDown = forwardRef<HTMLDivElement, Props>(
  ({ open, list, onClick, position }, ref) => {
    const { t } = useTranslation();
    return (
      <div
        ref={ref}
        className={`rounded-[4px] border border-border bg-blue-menu absolute z-50 w-[196px] transition-all duration-300 ease-in-out
        ${
          open
            ? 'opacity-100 transform translate-y-0 pointer-events-auto'
            : 'opacity-0 transform -translate-y-2 pointer-events-none'
        }`}
        style={{ ...position }}
      >
        <ul className="text-white flex flex-col items-center text-[16px] cursor-pointer">
          {list?.length > 0 &&
            list.map((item, index) => (
              <li
                className={`w-[174px] py-[12px] flex items-center ${
                  index !== list.length - 1 ? 'border-b border-border' : ''
                }`}
                key={index}
                onClick={() => onClick(item)}
              >
                {t(item.label)} {item.svg}
              </li>
            ))}
        </ul>
      </div>
    );
  },
);

export default MobileDropDown;
