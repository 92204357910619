import { useSwitchChain } from 'wagmi';
import { useAccount } from 'wagmi';

import { selectOption } from 'shared/components/ui/Select/Select';
import { useRoomStore } from 'shared/store/roomStore';

export const useSwitchChainHandler = () => {
  const { switchChainAsync } = useSwitchChain();
  const { address } = useAccount();

  return async (option: selectOption) => {
    await switchChainAsync({ chainId: option.value as number });
    useRoomStore.getState().resetRoom(address?.toLowerCase() as `0x${string}`);
  };
};
