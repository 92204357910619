import { FC, useMemo } from 'react';

import { minidenticon } from 'minidenticons';

import { PlayerType } from 'shared/types/app-types';

interface Props {
  userAddress: PlayerType['address'];
  className?: string;
}

export const Avatar: FC<Props> = ({ userAddress, className = 'w-8 h-8' }) => {
  const svgURI = useMemo(
    () => 'data:image/svg+xml;utf8,' + encodeURIComponent(minidenticon(userAddress)),
    [userAddress],
  );
  return (
    <div className={`flex flex-col rounded-[12px] bg-primary-light ${className}`}>
      <img src={svgURI} alt="Avatar" className="w-full h-full" />
    </div>
  );
};
