import React from 'react';

import glare from 'assets/animation/buttons/glare-secondary.png';
import BurgerIcon from 'assets/burger.svg?react';

import { Overlay } from './overlay';

interface Props {
  className?: string;
}

export const BurgerMenu = ({ className }: Props) => {
  const [open, setOpen] = React.useState(false);
  const clickHandler = () => {
    setOpen(!open);
  };
  return (
    <>
      <button onClick={clickHandler} className="rounded-xl outline-none p-0.5 bg-secondary-border">
        <div
          className={`${
            className ?? ''
          } group relative overflow-hidden rounded-[10px] inset-0 p-2.5 bg-secondary-btn shadow-secondary-btn transition-all ease-in-out duration-300 hover:bg-secondary-btn-hover active:shadow-secondary-btn-active`}
        >
          <BurgerIcon className="w-6 h-6 group-active:scale-90" />
          <img
            src={glare}
            alt="glare effect"
            className={`absolute w-10 h-20 top-[-15px] left-[-100%] transition-all duration-700 ease-in-out group-hover:left-[100%]`}
          />
        </div>
      </button>
      <Overlay open={open} setOpen={setOpen} />
    </>
  );
};
