import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { CardPlayerEnum } from 'modules/Room/constants/enums';
import { GameStatusEnum, MintingEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import { NULL_ADDRESS, NULL_MOVE } from 'shared/constants/constants';
import {
  CloserEnum,
  MovesEnum,
  RoomStatusEnum,
  RoundStagesEnum,
} from 'shared/constants/shared-enums';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';
import { useRoomStore } from 'shared/store/roomStore';

import {
  WaitOpponentMoveStatus,
  WaitOpponentRevealStatus,
  WaitYourApproveMoveStatus,
  WaitYourMoveStatus,
  WaitYourRevealStatus,
} from './statuses';

interface PlayGameStatusesProps {
  player?: CardPlayerEnum;
}

// todo: check how it goes when joining in a room, when connecting in a room, not lobby
export const PlayGameStatuses = ({ player }: PlayGameStatusesProps) => {
  const { gameStatus, setGameStatus, resetGameStatus, setMintingHash, setMintingLoader } =
    useGameStatusStore();
  const {
    playerA,
    playerB,
    roundStage,
    roomStatus,
    closerRoom,
    roundMoves,
    amIPlayerA,
    winnerStatus,
    someoneElseGame,
    showHash,
  } = useRoomStore();
  const { activeRoomId } = useParams();
  const { address } = useAccount();
  useEffect(() => {
    if (
      activeRoomId &&
      playerA.address !== NULL_ADDRESS &&
      playerA.address !== address?.toLowerCase() &&
      playerB.address === NULL_ADDRESS &&
      roomStatus !== RoomStatusEnum.Closed
    ) {
      console.log('join game');
      resetGameStatus();
      setGameStatus(GameStatusEnum.JOIN_GAME);
    } else if (
      (roomStatus === RoomStatusEnum.ReadyForGame ||
        roomStatus === RoomStatusEnum.ReadyForReveal) &&
      activeRoomId
    ) {
      if (
        (roundStage === RoundStagesEnum.None || roundStage === RoundStagesEnum.InitGame) &&
        roomStatus === RoomStatusEnum.ReadyForGame
      ) {
        console.log('round stage', roundStage);
        console.log('start game');
        if (
          !(gameStatus === GameStatusEnum.WAIT_YOUR_MOVE) &&
          !(gameStatus === GameStatusEnum.WAIT_YOUR_APPROVE)
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_YOUR_MOVE);
          setMintingHash('');
          setMintingLoader(MintingEnum.NONE);
        }
      } else if (
        roundStage === RoundStagesEnum.AddMove &&
        roomStatus === RoomStatusEnum.ReadyForGame
      ) {
        console.log('round stage', roundStage);
        console.log('one player made a move');
        if (
          (amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerA &&
            roundMoves.playerB.encrMove === NULL_MOVE) ||
          (!amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerB &&
            roundMoves.playerA.encrMove === NULL_MOVE)
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_OPPONENT_MOVE);
          setMintingHash('');
          setMintingLoader(MintingEnum.NONE);
        } else if (
          ((amIPlayerA && roundMoves.playerA.encrMove === NULL_MOVE) ||
            (!amIPlayerA && roundMoves.playerB.encrMove === NULL_MOVE)) &&
          !(gameStatus === GameStatusEnum.WAIT_YOUR_MOVE) &&
          !(gameStatus === GameStatusEnum.WAIT_YOUR_APPROVE)
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_YOUR_MOVE);
          setMintingHash('');
          setMintingLoader(MintingEnum.NONE);
        }
      } else if (
        roundStage === RoundStagesEnum.RevealMove &&
        roomStatus === RoomStatusEnum.ReadyForReveal
      ) {
        console.log('round stage', roundStage);
        console.log('no one make reveal or one make reveal');
        if (
          (roundMoves.playerA.move === MovesEnum.None &&
            roundMoves.playerB.move === MovesEnum.None) ||
          (amIPlayerA && roundMoves.playerA.move === MovesEnum.None) ||
          (!amIPlayerA && roundMoves.playerB.move === MovesEnum.None)
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_YOUR_REVEAL);
          setMintingHash('');
          setMintingLoader(MintingEnum.NONE);
        } else if (
          (amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerA &&
            roundMoves.playerB.move === MovesEnum.None) ||
          (!amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerB &&
            roundMoves.playerA.move === MovesEnum.None &&
            !(gameStatus === GameStatusEnum.WAIT_OPPONENT_REVEAL))
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_OPPONENT_REVEAL);
          setMintingHash('');
          setMintingLoader(MintingEnum.NONE);
        }
      }
    }
  }, [
    playerA,
    playerB,
    address,
    roomStatus,
    closerRoom,
    roundStage,
    roundMoves,
    amIPlayerA,
    activeRoomId,
    winnerStatus,
    someoneElseGame,
    setMintingHash,
    setMintingLoader,
  ]);

  const { showSignModal } = useShowSignModal();
  const renderStatus = () => {
    if (
      gameStatus === GameStatusEnum.WAIT_YOUR_MOVE &&
      !showSignModal &&
      !showHash &&
      (player === CardPlayerEnum.Player || !player)
    ) {
      return <WaitYourMoveStatus />;
    }

    if (
      gameStatus === GameStatusEnum.WAIT_OPPONENT_MOVE &&
      !showSignModal &&
      !showHash &&
      (player === CardPlayerEnum.Opponent || !player)
    ) {
      return <WaitOpponentMoveStatus />;
    }

    if (
      gameStatus === GameStatusEnum.WAIT_YOUR_REVEAL &&
      !showSignModal &&
      !showHash &&
      (player === CardPlayerEnum.Player || !player)
    ) {
      return <WaitYourRevealStatus />;
    }

    if (
      gameStatus === GameStatusEnum.WAIT_OPPONENT_REVEAL &&
      !showSignModal &&
      !showHash &&
      (player === CardPlayerEnum.Opponent || !player)
    ) {
      return <WaitOpponentRevealStatus />;
    }

    if (
      gameStatus === GameStatusEnum.WAIT_YOUR_APPROVE &&
      !showSignModal &&
      !showHash &&
      (player === CardPlayerEnum.Player || !player)
    ) {
      return <WaitYourApproveMoveStatus />;
    }

    return null;
  };

  return <>{renderStatus()}</>;
};
