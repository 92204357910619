import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { useAccount } from 'wagmi';

import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import { ROUTES } from 'shared/constants';
import { useWriteRspContractJoinRoom } from 'shared/features/contract.abi';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { WriteError } from 'shared/types/app-types';
import { estimateGasForFunction } from 'shared/utils/estimateGas';

const useJoinGame = () => {
  const { chainId } = useAccount();
  const [localHash, setLocalHash] = useState<`0x${string}`>();
  const { setJoinHash, joinHash } = usePlayerGamesStore();
  const { joinRoomId, setIsGameSearching, setJoinRoomId } = useRoomStore();
  const { setGameStatus, resetGameStatus, setGameBetGameStatus } = useGameStatusStore();
  const { setTransactionCallBack } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const {
    writeContractAsync,
    error,
    isSuccess,
    data: joinRoomData,
  } = useWriteRspContractJoinRoom();

  useEffect(() => {
    if (isSuccess) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('user approved join', timeString);
      setJoinHash(chainId!, joinRoomData!, now);
      setLocalHash(joinRoomData!);
    }
  }, [isSuccess]);
  const {
    isSuccess: isSuccessJoin,
    isError,
    error: transactionError,
    limitExceed,
  } = useTransactionReceipt(localHash, joinHash?.[chainId!]?.date);

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccessJoin && joinRoomId) {
      if (!activeRoomId) {
        navigate(`${ROUTES.ROOM}/${chainId}/${Number(joinRoomId)}`);
        setJoinHash(chainId!, null, undefined);
        setLocalHash(undefined);
      }
      resetGameStatus();
      setGameBetGameStatus({ reject: false, error: false });
      setJoinHash(chainId!, null);
      setIsGameSearching(false);
      setJoinRoomId(null);
    } else if (isSuccessJoin && !activeRoomId) {
      window.location.reload();
      setJoinHash(chainId!, null);
    }
  }, [isSuccessJoin]);

  useEffect(() => {
    if (error || isError || transactionError) {
      console.log('join room error');
      console.log(error);
      console.log(transactionError);
      if (error) {
        const typedError = error as WriteError | undefined;
        if (typedError?.cause?.code) {
          if (typedError.cause.code !== 4001) {
            Sentry.captureException(error);
          }
          setGameBetGameStatus({
            reject: typedError.cause.code === 4001,
            error: typedError.cause.code !== 4001,
          });
        } else {
          setGameBetGameStatus({
            reject: false,
            error: true,
          });
        }
      }
      if (transactionError) Sentry.captureException(transactionError);
      setJoinRoomId(null);
      setIsGameSearching(false);
      resetGameStatus();
    }
  }, [error, isError, transactionError]);
  return async (args: any) => {
    try {
      const gas = await estimateGasForFunction('joinRoom', args.args);
      await writeContractAsync({ ...args, gas });
      setTransactionCallBack(async () => {
        const gas = await estimateGasForFunction('joinRoom', args);
        writeContractAsync({ ...args, gas });
      });
      return 'Success!';
    } catch (error) {
      console.log('writeContract error', error);
      resetGameStatus();
      setGameBetGameStatus({ reject: false, error: true });
      Sentry.captureException(error);
      navigate(ROUTES.LOBBY);
      return error;
    }
  };
};

export default useJoinGame;
