import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import GridBackground from 'shared/components/ui/GridBackground/GridBackground';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';

type ErrorBoundaryState = {
  hasError: boolean;
};

type ErrorBoundaryProps = {
  children: ReactNode;
  navigate: (path: string) => void; // Add navigate to the props
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <GridBackground className="fixed top-0 left-0 right-0 bottom-0 h-screen">
          <div className="flex flex-col items-center justify-center h-full">
            <h1 className="text-2xl text-white font-bold mb-5">Something went wrong.</h1>
            <PrimaryButton
              onClick={() => {
                window.location.reload();
              }}
              text={'Reload'}
            />
          </div>
        </GridBackground>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryWithNavigate = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  return <ErrorBoundary navigate={navigate}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWithNavigate;
