export interface AnimationConfig {
  name: string;
  loop?: boolean;
  timeScale?: number;
  trackIndex?: number;
}

export const animationMap: { [key: string]: AnimationConfig } = {
  idle: {
    name: 'anims/idel',
    loop: true,
    trackIndex: 0,
    timeScale: 1.8,
  },
  walk: {
    name: 'anims/walk',
    loop: false,
    trackIndex: 0,
    timeScale: 1.5,
  },
  shake: {
    name: 'anims/shake',
    loop: true,
    trackIndex: 0,
    timeScale: 0.8,
  },
  show_rock: {
    name: 'anims/show_rock',
    loop: false,
    trackIndex: 0,
    timeScale: 1,
  },
  show_paper: {
    name: 'anims/show_paper',
    loop: false,
    trackIndex: 0,
    timeScale: 1,
  },
  show_scissors: {
    name: 'anims/show_scissors',
    loop: false,
    trackIndex: 0,
    timeScale: 1,
  },
  /*
    jump: {
        name: 'jump',
        timeScale: 1.5,
    },
    */
};

export const KeyBoardMap: { [key: string]: string } = {
  Space: 'space',
  KeyW: 'up',
  ArrowUp: 'up',
  KeyA: 'left',
  ArrowLeft: 'left',
  KeyS: 'down',
  ArrowDown: 'down',
  KeyD: 'right',
  ArrowRight: 'right',
};

export const KeyMap: { [key: string]: string } = {
  //A
  WALK_A: 'walk_A',
  SHAKE_A: 'shake_A',
  IDLE_A: 'idle_A',
  SHOW_ROCK_A: 'show_rock_A',
  SHOW_PAPER_A: 'show_paper_A',
  SHOW_SCISSORS_A: 'show_scissors_A',
  //B
  WALK_B: 'walk_B',
  SHAKE_B: 'shake_B',
  IDLE_B: 'idle_B',
  SHOW_ROCK_B: 'show_rock_B',
  SHOW_PAPER_B: 'show_paper_B',
  SHOW_SCISSORS_B: 'show_scissors_B',
};
export const KeyMapA: { [key: string]: string } = {
  WALK_A: 'walk_A',
  SHAKE_A: 'shake_A',
  IDLE_A: 'idle_A',
  SHOW_ROCK_A: 'show_rock_A',
  SHOW_PAPER_A: 'show_paper_A',
  SHOW_SCISSORS_A: 'show_scissors_A',
};
export const KeyMapB: { [key: string]: string } = {
  WALK_B: 'walk_B',
  SHAKE_B: 'shake_B',
  IDLE_B: 'idle_B',
  SHOW_ROCK_B: 'show_rock_B',
  SHOW_PAPER_B: 'show_paper_B',
  SHOW_SCISSORS_B: 'show_scissors_B',
};

export const event_end: string = 'event_anim_end';
