import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { useAccount } from 'wagmi';

import useInitGame from 'modules/Room/Hooks/useInitGame';
import useJoinGame from 'modules/Room/Hooks/useJoinGame';

import { ROUTES } from 'shared/constants';
import { NULL_ADDRESS, RANGE_OF_GAME_REQUEST, TOTAL_WINS_TO } from 'shared/constants/constants';
import {
  useReadRspContractGetRoomsInfo,
  useReadRspContractRoomIdCounter,
} from 'shared/features/contract.abi';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useGlobalStore } from 'shared/store/globalStore';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';

export const useFindGames = () => {
  const { bet } = useRoomStore();
  const [joinRoomId, setJoinRoomId] = useState<bigint | null>(null);
  const [startNewGame, setStartNewGame] = useState(false);
  const [fromRoomId, setFromRoomId] = useState<bigint | null>(null);
  const createRoomRef = useRef(false);
  const navigate = useNavigate();
  const {
    playerB,
    setIsGameSearching,
    isGameSearching,
    joinRoomId: joinRoomIdStore,
    setRefetchLastRoomId,
  } = useRoomStore();
  const invitedBy = useGlobalStore((state) => state.invitedBy);
  const { address, chainId } = useAccount();

  const { selectedCurrency } = useCurrenciesStore();

  const { joinHash, startHash } = usePlayerGamesStore();

  const betInTokenAmount = selectedCurrency?.decimals
    ? BigInt(Math.floor(bet * 10 ** selectedCurrency!.decimals))
    : 0n;

  const {
    data: lastRoomId,
    refetch: refetchLastRoomId,
    isSuccess: lastRoomIdSuccess,
    isError: lastRoomIdError,
    isFetching: lastRoomIdIsFetching,
  } = useReadRspContractRoomIdCounter();

  useEffect(() => {
    setRefetchLastRoomId(refetchLastRoomId);
  }, []);
  useEffect(() => {
    if (lastRoomId) {
      setFromRoomId(
        lastRoomId - BigInt(RANGE_OF_GAME_REQUEST) < 0n
          ? 0n
          : lastRoomId - BigInt(RANGE_OF_GAME_REQUEST),
      );
    }
  }, [lastRoomId]);
  const { data: roomsInfo, error } = useReadRspContractGetRoomsInfo({
    args: [fromRoomId ?? 0n, lastRoomId ?? 0n],
    query: {
      enabled: typeof fromRoomId === 'bigint',
    },
  });

  const writeJoinRoom = useJoinGame();
  const writeInitGame = useInitGame();

  const handleWriteInitGame = () => {
    if (createRoomRef.current || (chainId && joinHash[chainId])) return;
    setIsGameSearching(false);
    console.log('init room');
    navigate(`${ROUTES.ROOM}/${chainId}`);
    createRoomRef.current = true;
    try {
      writeInitGame({
        args: [
          Number(TOTAL_WINS_TO),
          selectedCurrency!.address,
          betInTokenAmount,
          NULL_ADDRESS,
          invitedBy,
        ],
      });
    } catch (error) {
      console.log('write init game error', error);
    }
  };

  const joinOrCreateRoom = useCallback(() => {
    if (playerB.address === NULL_ADDRESS) {
      console.log('startNewGame');
      handleWriteInitGame();
      createRoomRef.current = false;
    }
  }, [roomsInfo, selectedCurrency, betInTokenAmount, address, playerB, bet]);

  useEffect(() => {
    setJoinRoomId(joinRoomIdStore);
  }, [joinRoomIdStore]);

  useEffect(() => {
    if (
      roomsInfo &&
      isGameSearching &&
      lastRoomIdSuccess &&
      !lastRoomIdIsFetching &&
      !createRoomRef.current &&
      !joinRoomIdStore
    ) {
      joinOrCreateRoom();
    }
  }, [roomsInfo, isGameSearching, joinRoomId, lastRoomIdSuccess, lastRoomIdIsFetching]);

  useEffect(() => {
    if (error) {
      console.log('find games error', error);
      Sentry.captureException(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      !startNewGame &&
      joinRoomId !== null &&
      !createRoomRef.current &&
      chainId &&
      !startHash[chainId]
    ) {
      console.log('Write join room', joinRoomId);
      useRoomStore.getState().setJoinRoomId(joinRoomId);
      writeJoinRoom({ args: [joinRoomId, invitedBy] });
    }
  }, [joinRoomId, startNewGame, startHash, chainId]);
};
