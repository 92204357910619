import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { Chain } from 'viem';
import { useAccount, useAccountEffect } from 'wagmi';

import ConnectPage from 'modules/ConnectPage/page/ConnectPage';
import { useFindGames } from 'modules/Room/Hooks/useFindGames';
import { Room } from 'modules/Room/pages/Room';
import { ExploreGames } from 'modules/explore-games/pages/explore-games';
import { LobbyPage } from 'modules/lobby-page/pages';
import { StreamRoom } from 'modules/stream-room-page';

import ErrorBoundary from 'shared/components/sections/ErrorBoundary/ErrorBoundary';
import GridBackground from 'shared/components/ui/GridBackground/GridBackground';
import { ROUTES } from 'shared/constants';
import { useGetURLRef } from 'shared/hooks/useGetURLRef';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { getAvailableCurrencies } from 'shared/web3';

import './base.css';

const App = () => {
  const { address, chainId, chain } = useAccount();
  useEffect(() => {
    if (address) {
      Sentry.setUser({
        id: address.toString(),
        chainId: chainId,
        buildVersion: process.env.APP_VERSION,
      });
    }
  }, [address, chainId]);
  useGetURLRef();
  const resetRoomStore = useRoomStore((state) => state.resetStore);
  useAccountEffect({
    onConnect(data) {
      const alreadyConnected = sessionStorage.getItem('walletConnected');
      if (!alreadyConnected) {
        const startWithZeroX = data.address.startsWith('0x');
        const walletWithoutPrefix = startWithZeroX ? data.address.substring(2) : data.address;
        if (
          typeof window !== 'undefined' &&
          window?.sa_event &&
          typeof window.sa_event === 'function'
        ) {
          window.sa_event('connect-wallet', { wallet: walletWithoutPrefix });
        } else {
          console.warn('Simple Analytics tracking function is not available.');
        }
      }

      sessionStorage.setItem('walletConnected', 'true');
    },
    onDisconnect() {
      resetRoomStore();
      window.location.reload();
    },
  });

  const { setAvailableCurrencies, setCurrenciesForSelect, setSelectedCurrency, userBets } =
    useCurrenciesStore();
  useEffect(() => {
    if (!chain) return;
    const currencies = getAvailableCurrencies(chain as Chain).map((c) => ({
      ...c,
      address: (c.address as string).toLowerCase() as `0x${string}`,
      chainId: Number(c.chainId),
      decimals: Number(c.decimals),
      symbol: String(c.symbol),
      bet: Array.isArray(c.bet) ? c.bet.map(String) : [String(c.bet)],
      minBet: Number(c.minBet),
    }));

    const currencyForSelect = currencies.map((currency) => ({
      value: String(currency.symbol),
      label: String(currency.symbol),
      address: currency.address,
    }));
    setCurrenciesForSelect(currencyForSelect);
    setAvailableCurrencies(currencies);
    let betKey;
    if (chainId && userBets && userBets[chainId]) {
      betKey = Object.keys(userBets[chainId])[0];
    }
    if (betKey) {
      setSelectedCurrency(betKey);
    } else {
      setSelectedCurrency(currencies[0].symbol);
    }
  }, [chain]);
  useFindGames();
  return (
    <GridBackground>
      <Suspense fallback={<div className="h-screen w-full bg-dark-background" />}>
        <ErrorBoundary>
          <Routes>
            <Route element={<ConnectPage />} path={ROUTES.HOME} />
            <Route element={<Room />} path={`${ROUTES.ROOM}/:networkId/:activeRoomId?`} />
            <Route element={<LobbyPage />} path={ROUTES.LOBBY} />
            <Route element={<ExploreGames />} path={ROUTES.EXPLORE_GAMES} />
            <Route element={<StreamRoom />} path={`${ROUTES.STREAM}/:networkId/:activeRoomId`} />
            <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </GridBackground>
  );
};

export default App;
