import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAccount, useSwitchChain } from 'wagmi';

import { useSwitchChainHandler } from 'modules/Room/Hooks/useSwitchHandler';

import { BurgerMenu } from 'shared/components/sections/burger-menu';
import Dropdown from 'shared/components/ui/DropDown/DropDown';
import { chainIcons } from 'shared/config';

import ArrowIcon from 'assets/arrow.svg?react';

import './MobileRoomMenu.scss';

const MobileRoomMenu = () => {
  const [networkOpen, setNetworkOpen] = useState(false);
  const { chain, chainId } = useAccount();
  const { chains, isSuccess } = useSwitchChain();

  const chainRef = useRef<HTMLButtonElement>(null);
  const chainsList = useMemo(
    () =>
      chains.map((chain) => ({
        label: chain.name,
        value: chain.id,
      })),
    [chains],
  );

  const switchChainHandler = useSwitchChainHandler();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (chainRef.current && !chainRef.current.contains(event.target as Node)) {
        setNetworkOpen(false);
      }
    };

    if (networkOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [networkOpen]);

  return createPortal(
    <div className="flex w-full justify-between items-center absolute top-[12px] px-[14px] z-[55]">
      <div className="flex mobile-timer">
        <button
          className="py-[5px] px-[18px] mr-3 border border-border rounded-[6px] flex items-center cursor-pointer"
          onClick={() => setNetworkOpen(!networkOpen)}
          ref={chainRef}
        >
          {chain && chainIcons?.[chain?.id] && (
            <img src={chainIcons?.[chain?.id]} className="mr-2.5 w-6 h-6" />
          )}
          <ArrowIcon
            className={`${
              networkOpen ? 'transform rotate-180' : ''
            } transition ease-in-out duration-500`}
          />
        </button>
        <Dropdown
          open={networkOpen}
          list={chainsList}
          onClick={switchChainHandler}
          active={chainId}
          position={{ top: 62, left: 10 }}
        />
      </div>
      <BurgerMenu />
    </div>,
    document.body,
  );
};

export default MobileRoomMenu;
