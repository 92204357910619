import { FC, useMemo } from 'react';

import { renderIcon } from 'shared/components/ui/HistoryIcon/renderIconByMove';
import { RoundWinnerOutcomes, bgColor } from 'shared/constants/shared-enums';
import { useRoomStore } from 'shared/store/roomStore';

interface Props {
  round: {
    moveA: number;
    moveB: number;
    winner: RoundWinnerOutcomes;
  };
  gameCount: number;
  lastRound: boolean;
}

const HistoryMove: FC<Props> = ({ round, gameCount, lastRound }) => {
  const { amIPlayerA, someoneElseGame } = useRoomStore();

  const playerAMoveIcon = useMemo(
    () => renderIcon(round.moveA, round.winner === RoundWinnerOutcomes.PlayerA),
    [round],
  );

  const playerBMoveIcon = useMemo(
    () => renderIcon(round.moveB, round.winner === RoundWinnerOutcomes.PlayerB),
    [round],
  );
  const playerAClass = someoneElseGame ? 'order-1' : amIPlayerA ? 'order-1' : 'order-3';

  const playerBClass = someoneElseGame ? 'order-3' : amIPlayerA ? 'order-3' : 'order-1';

  if (round.winner === RoundWinnerOutcomes.None) return null;
  let bgColorClass = bgColor.default;
  if (lastRound && !someoneElseGame) {
    if (round.winner === RoundWinnerOutcomes.PlayerA && amIPlayerA) bgColorClass = bgColor.win;
    if (round.winner === RoundWinnerOutcomes.PlayerB && !amIPlayerA) bgColorClass = bgColor.win;
    if (round.winner === RoundWinnerOutcomes.PlayerA && !amIPlayerA) bgColorClass = bgColor.lose;
    if (round.winner === RoundWinnerOutcomes.PlayerB && amIPlayerA) bgColorClass = bgColor.lose;
  }
  return (
    <div className="flex items-center flex-col w-full">
      <div
        className={`flex justify-between mb-2 items-center w-[95%] ${
          bgColorClass === bgColor.default ? 'bg-violet' : ''
        }
           ${bgColorClass === bgColor.win ? 'bg-history-content-win' : ''}
           ${bgColorClass === bgColor.lose ? 'bg-history-content-lose' : ''}  rounded-[4px]`}
      >
        <div className={`flex w-[33%] justify-center ${playerAClass}`}>{playerAMoveIcon}</div>
        <span className="order-2 w-[33%] flex justify-center text-[30px] font-medium text-white">
          {gameCount}
        </span>
        <div className={`flex w-[33%] justify-center ${playerBClass}`}>{playerBMoveIcon}</div>
      </div>
    </div>
  );
};

export default HistoryMove;
