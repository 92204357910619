import { PublicClient, WalletClient } from 'viem';
import { erc20Abi } from 'viem';

import abi from 'shared/contract/contract.abi.json';

const contract = import.meta.env.VITE_RSP_CONTRACT_ADDRESS;
const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

export async function getAllowance({
  tokenAddress,
  ownerAddress,
  provider,
}: {
  tokenAddress: `0x${string}`;
  ownerAddress: `0x${string}`;
  provider: PublicClient;
}) {
  try {
    return await provider.readContract({
      address: tokenAddress,
      abi: erc20Abi,
      functionName: 'allowance',
      args: [ownerAddress, contract],
    });
  } catch (error) {
    console.error('Error fetching allowance:', error);
    throw error;
  }
}

interface approveParams {
  amount: bigint;
  currencyAddress: `0x${string}` | null;
  userAddress: `0x${string}`;
  walletClient: WalletClient;
  publicClient: PublicClient;
}

export async function approveHandler({
  amount,
  functionName,
  currencyAddress,
  userAddress,
  walletClient,
  publicClient,
}: approveParams & { functionName: 'approve' | 'increaseAllowance' }) {
  try {
    const { request } = await publicClient.simulateContract({
      address: currencyAddress ?? NULL_ADDRESS,
      abi: [
        {
          constant: false,
          inputs: [
            { name: 'spender', type: 'address' },
            { name: 'amount', type: 'uint256' },
          ],
          name: functionName,
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      functionName,
      args: [contract, amount],
      account: userAddress,
    });
    return await walletClient.writeContract({ ...request });
  } catch (error) {
    console.error('Error executing approve transaction:', error);
    throw error;
  }
}

export interface CreateRoomParams {
  wins: number;
  currency: `0x${string}`;
  betAmount: bigint;
  playerB: `0x${string}`;
  referrer: `0x${string}`;
  walletClient: WalletClient;
  publicClient: PublicClient;
  userAddress: `0x${string}`;
}

export async function createRoom({
  wins,
  currency,
  betAmount,
  playerB,
  referrer,
  walletClient,
  publicClient,
  userAddress,
}: CreateRoomParams) {
  const { request } = await publicClient.simulateContract({
    address: contract,
    abi: abi,
    functionName: 'initRoom',
    args: [wins, currency, betAmount, playerB, referrer],
    account: userAddress,
  });
  return await walletClient.writeContract({ ...request });
}

interface JoinRoomParams {
  roomId: bigint;
  referrer: `0x${string}`;
  userAddress: `0x${string}`;
  walletClient: WalletClient;
  publicClient: PublicClient;
}

export async function joinRoom({
  roomId,
  referrer,
  walletClient,
  publicClient,
  userAddress,
}: JoinRoomParams) {
  const { request } = await publicClient.simulateContract({
    address: contract,
    abi: [
      {
        type: 'function',
        name: 'joinRoom',
        inputs: [
          { name: 'roomId', internalType: 'uint256', type: 'uint256' },
          { name: 'referrer', internalType: 'address', type: 'address' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
      },
    ],
    functionName: 'joinRoom',
    args: [roomId, referrer],
    account: userAddress,
  });
  return await walletClient.writeContract({ ...request });
}
