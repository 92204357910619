import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreateGameSectionMobile } from 'modules/lobby-page/components';
import { LiveGamesSectionMobile } from 'modules/lobby-page/components/live-games-section';

import { Header } from 'shared/components/sections/header/header';
import { useUIStore } from 'shared/store/uiStore';

interface MobileViewProps {
  approveAllowanceHandler: (fullAllowance: boolean, bet: number) => void;
  startGameHandler: (bet: number) => void;
  joinRoomHandler: (roomId: number) => void;
}

export const MobileView = ({
  approveAllowanceHandler,
  startGameHandler,
  joinRoomHandler,
}: MobileViewProps) => {
  const {
    lobby: { createGameOpen },
    setLobbyCreateGameOpen,
  } = useUIStore();

  const toggleCreateGameSection = () => setLobbyCreateGameOpen(!createGameOpen);
  const { t } = useTranslation();
  return (
    <div className="relative w-full min-h-[100vh] flex flex-col">
      <Header
        title={createGameOpen ? t('modals.setBet.title') : t('pages.liveGamesSection.title')}
        className="px-[16px] py-[12px]"
      />
      {createGameOpen && (
        <CreateGameSectionMobile
          openLiveGames={toggleCreateGameSection}
          approveAllowanceHandler={approveAllowanceHandler}
          startGameHandler={startGameHandler}
        />
      )}
      {!createGameOpen && (
        <LiveGamesSectionMobile
          openCreateGame={toggleCreateGameSection}
          joinRoomHandler={joinRoomHandler}
        />
      )}
    </div>
  );
};
