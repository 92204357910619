import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { useCloseUnusedRoom } from 'modules/Room/game-status/hooks/use-close-unused-room';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import DigitalClock from 'shared/components/ui/DigitalClock/DigitalClock';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { TIME_BEFORE_SHOW_TIMER_IN_MODALS } from 'shared/constants/timers';
import { Timer } from 'shared/constants/types';
import { useRoomStore } from 'shared/store/roomStore';
import { useTimersStore } from 'shared/store/timersStore';
import { epochToLocalUTC } from 'shared/utils/time';

import { useWaitYourMoveGameStatusText } from './use-wait-your-move-game-status-text';

export const WaitYourMoveStatus = () => {
  const { waitYourMoveShowTimer, setWaitYourMoveShowTimer } = useTimersStore();
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const timeOutRef = useRef<null | Timer>(null);
  const { closerRoom, roundCounter, lastRoundOutcome, setRoomExpire } = useRoomStore();
  const onExpire = () => {
    setShowCloseButton(true);
    setRoomExpire(true);
  };
  useEffect(() => {
    if (!waitYourMoveShowTimer?.[activeRoomId! + address + chainId + roundCounter]) {
      if (new Date() >= epochToLocalUTC(closerRoom?.deadline)) {
        setShowTimer(true);
      } else {
        setWaitYourMoveShowTimer(
          activeRoomId! + address + chainId + roundCounter,
          new Date(new Date().getTime() + TIME_BEFORE_SHOW_TIMER_IN_MODALS),
        );
        timeOutRef.current = setTimeout(() => {
          setShowTimer(true);
        }, TIME_BEFORE_SHOW_TIMER_IN_MODALS);
      }
    } else {
      const currentTime = new Date();
      const targetTime = new Date(
        waitYourMoveShowTimer?.[activeRoomId! + address + chainId + roundCounter] as string,
      );
      const diffInMilliseconds = targetTime.getTime() - currentTime.getTime();
      if (diffInMilliseconds >= TIME_BEFORE_SHOW_TIMER_IN_MODALS) {
        setShowTimer(true);
      } else {
        const timeRemaining = targetTime.getTime() - currentTime.getTime();
        timeOutRef.current = setTimeout(() => {
          setShowTimer(true);
        }, timeRemaining);
      }
    }
    return () => clearTimeout(timeOutRef.current as Timer);
  }, []);

  const { closeUnusedRoomHandler, isSuccess, technicalDefeatError } = useCloseUnusedRoom();
  const { getTextComponent } = useWaitYourMoveGameStatusText(
    isSuccess,
    showTimer,
    showCloseButton,
    technicalDefeatError,
    lastRoundOutcome,
  );

  return (
    <>
      {getTextComponent()}
      <AnimatedTransition show={showCloseButton && !isSuccess}>
        <div className="flex justify-center mt-3">
          <PrimaryButton text={'common.technicalDefeat'} onClick={closeUnusedRoomHandler} />
        </div>
      </AnimatedTransition>
      <AnimatedTransition show={showTimer && !showCloseButton}>
        <div className="w-full flex justify-center pt-2">
          <DigitalClock
            key={(epochToLocalUTC(closerRoom?.deadline) as Date).toString()}
            expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
            className="w-fit"
            onExpire={onExpire}
          />
        </div>
      </AnimatedTransition>
    </>
  );
};
