import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'wagmi';

import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { useRoomStore } from 'shared/store/roomStore';

import './Score.scss';

const MobileScore = () => {
  const { t } = useTranslation();
  const { someoneElseGame, roomStatus, playerA, playerB } = useRoomStore();
  const { address } = useAccount();
  const amIPlayerA = playerA.address === address!.toLowerCase();
  const classNamePlayerAScore = `  ${
    someoneElseGame ? 'order-1' : amIPlayerA ? 'order-1' : 'order-3'
  }`;
  const classNamePlayerBScore = ` ${
    someoneElseGame ? 'order-3' : amIPlayerA ? 'order-3' : 'order-1'
  }`;
  return (
    <div className="flex flex-col items-center mb-[2px]">
      <h4 className="text-blue-text text-[10px] sm:text-[16px]">Score</h4>
      {someoneElseGame && (
        <h5
          className={`${
            roomStatus === RoomStatusEnum.Closed ? 'text-gray' : 'text-green'
          }  text-[10px] sm:text-[16px] mb-[2px]`}
        >
          {roomStatus === RoomStatusEnum.Closed ? t('common.finished') : t('common.active')}
        </h5>
      )}
      <div className="score bg-black bg-opacity-30  border border-border rounded-[4px] w-[57px] sm:w-[80px] flex justify-center ">
        <div className="score__text w-full text-[16px] sm:text-[32px] text-white font-medium flex px-[10px]  justify-around">
          <span className={`${classNamePlayerAScore} `}>{`${playerA.wins}`}</span>
          <span className="order-2">:</span>
          <span className={`${classNamePlayerBScore}`}>{`${playerB.wins}`}</span>
        </div>
      </div>
    </div>
  );
};

export default MobileScore;
