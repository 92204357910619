import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAccount } from 'wagmi';

import { GameStatusEnum, MintingEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import MintingLoader from 'shared/components/ui/Loaders/MintingLoader/MintingLoader';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import SocialMediaBtn from 'shared/components/ui/SocialMediaBtn/SocialMediaBtn';
import { SOCIAL_MEDIA } from 'shared/constants/socialMedia';
import { takeMoneyBackMessages } from 'shared/constants/text-loaders';
import { useWriteRspContractExitRoom } from 'shared/features/contract.abi';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';

import IconDiscord from 'assets/discord.svg?react';
import IconTelegram from 'assets/telegram.svg?react';
import IconX from 'assets/x.svg?react';

export const NoOneJoinStatus = () => {
  const [localHash, setLocalHash] = useState<`0x${string}`>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { chainId } = useAccount();
  //todo check all player stores for hash
  const { setNoOneJoinHash, noOneJoinHash } = usePlayerGamesStore();
  const { setMintingLoader, setMintingHash } = useGameStatusStore();
  const {
    data,
    writeContractAsync: writeContractExit,
    isSuccess,
    error,
  } = useWriteRspContractExitRoom();

  const { isSuccess: isExitSuccess, isError } = useTransactionReceipt(
    localHash,
    noOneJoinHash[chainId!]?.date,
  );

  useEffect(() => {
    if (isSuccess) {
      // setNoOneJoinHash(chainId!, data!, new Date());
      setMintingLoader(MintingEnum.TAKE_MONEY_BACK);
      setLocalHash(data);
      if (data) setMintingHash(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isExitSuccess) {
      setMintingLoader(MintingEnum.NONE);
      setMintingHash('');
      setLoading(false);
      setLocalHash(undefined);
      setNoOneJoinHash(chainId!, null);
    }
  }, [isExitSuccess]);

  useEffect(() => {
    if (error || isError) {
      console.error('Error exiting room', error);
      setNoOneJoinHash(chainId!, null);
      setLocalHash(undefined);
      setMintingLoader(MintingEnum.NONE);
      setMintingHash('');
    }
  }, [error, isError]);
  useEffect(() => {
    if (chainId && noOneJoinHash?.[chainId!]?.hash) {
      setLocalHash(noOneJoinHash?.[chainId!]?.hash);
    }
  }, []);
  const { activeRoomId } = useParams();

  const handleExit = (activeRoomId: bigint) => async () => {
    if (!activeRoomId || loading) return;
    setLoading(true);
    try {
      await writeContractExit({
        args: [BigInt(activeRoomId)],
      });
    } catch (e) {
      setLoading(false);
      //TODO: handle error
    }
  };

  const handleClick = (url: string) => () => {
    window.open(url, '_blank');
  };

  return (
    <>
      <p className="text-[14px] sm:text-[24px] mb-[10px] text-yellow font-bold">
        {t('modals.noOneJoin.situation')}
      </p>
      <p className="text-white text-[10px] sm:text-[16px] mb-[20px] text-justify	">
        {t('modals.noOneJoin.unpopularBet')}
      </p>
      <p className="text-white text-[10px] sm:text-[16px] mb-[24px] text-justify	">
        {t('modals.noOneJoin.proTip')}
      </p>

      <PrimaryButton
        className="w-full mb-4"
        text={'modals.takeABetBack.collectButton'}
        onClick={handleExit(BigInt(activeRoomId || 0))}
        isLoading={loading}
      />

      <div className="h-[1px] w-full bg-border mb-4"></div>
      <div className="grid grid-cols-3 w-full gap-4">
        <button
          className="flex-grow bg-[linear-gradient(91.27deg,#1B4563_-6.69%,#151552_106.59%)] border-[1px] border-[#424269] py-[18px] rounded-[12px] flex  justify-center"
          onClick={handleClick(SOCIAL_MEDIA.TELEGRAM)}
        >
          <IconTelegram className="w-[34px]" />
        </button>
        <button
          className="flex-grow border-[1px] bg-[linear-gradient(91.27deg,#040435_-6.69%,#151552_106.59%)] border-[#424269] py-[18px] rounded-[12px] flex  justify-center"
          onClick={handleClick(SOCIAL_MEDIA.TWITTER)}
        >
          <IconX className="w-[28px]" />
        </button>
        <button
          className="flex-grow border-[1px] bg-[linear-gradient(91.27deg,#352372_-6.69%,#151552_106.59%)] border-[#424269] py-[18px] rounded-[12px] flex  justify-center"
          onClick={handleClick(SOCIAL_MEDIA.DISCORD)}
        >
          <IconDiscord className="w-[44px]" />
        </button>
      </div>
    </>
  );
};
