import { useEffect } from 'react';

import { CardPlayerEnum } from 'modules/Room/constants/enums';
import GameFinishViews from 'modules/Room/game-status/components/game-finish-views/GameFinishViews';
import { GameStatusEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { MintingEnum } from 'modules/Room/game-status/constants/game-status-enum';
import { useGameStatusStore } from 'modules/Room/game-status/store/game-status-store';

import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { useRoomStore } from 'shared/store/roomStore';

import { InitGameStatuses } from './components/init-game-statuses';
import { PlayGameStatuses } from './components/play-game-statuses';

interface GameStatusProps {
  player?: CardPlayerEnum;
  isShowEndGameAnimation: boolean;
}

export const GameStatus = ({ player, isShowEndGameAnimation }: GameStatusProps) => {
  const { gameStatus, setMintingLoader } = useGameStatusStore();
  const { roomStatus } = useRoomStore();

  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed) {
      setMintingLoader(MintingEnum.NONE);
    }
  }, [roomStatus]);

  if (isShowEndGameAnimation) return null;

  return (
    <div className="relative flex justify-around  sm:static z-[10000] hd:absolute hd:top-1/2 hd:left-1/2 hd:transform hd:-translate-x-1/2 hd:-translate-y-1/2 w-full">
      <div
        className={`w-full p-4 md:p-8 ${
          gameStatus === GameStatusEnum.NONE && roomStatus !== RoomStatusEnum.Closed ? 'hidden' : ''
        } border border-border rounded-[12px] lg:rounded-[0] lg:border-0 bg-grape lg:bg-[transparent] max-w-[500px]`}
      >
        <InitGameStatuses player={player} />
        <PlayGameStatuses player={player} />
        <GameFinishViews player={player} />
      </div>
    </div>
  );
};
