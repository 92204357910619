import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GameStatus } from '../game-status';
import { useGameStatusStore } from '../game-status/store/game-status-store';
import classNames from 'classnames';
import { useAccount } from 'wagmi';

import { useActiveRoom } from 'modules/Room/Hooks/useActiveRoom';
import useCreateJoinRoomEvents from 'modules/Room/Hooks/useCreateJoinRoomEvents';
import { useSaltGenerator } from 'modules/Room/Hooks/useSaltGenerator';
import { Hash } from 'modules/Room/features/Hash/Hash';
import { MintingEnum } from 'modules/Room/game-status/constants/game-status-enum';
import MobileGameCard from 'modules/Room/mobile/MobileGameCard/MobileGameCard';
import MobileHistoryMoves from 'modules/Room/mobile/MobileHistoryMoves/MobileHistoryMoves';
import MobilePlayMenu from 'modules/Room/mobile/MobilePlayMenu/MobilePlayMenu';
import MobileRoomMenu from 'modules/Room/mobile/MobileRoomMenu/MobileRoomMenu';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import GuysLoader from 'shared/components/ui/Loaders/GuysLoader/GuysLoader';
import MintingLoader from 'shared/components/ui/Loaders/MintingLoader/MintingLoader';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { RoomStatusEnum, WinnerStatusEnum } from 'shared/constants/shared-enums';
import {
  lookingForOpponent,
  takeMoneyBackMessages,
  technicalDefeatMessages,
  waitYourMoveMessages,
  waitYourRevealMessages,
} from 'shared/constants/text-loaders';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';

import defeat from 'assets/png/defeat.png';
import floor from 'assets/png/floor.png';
import mobHumanLeft from 'assets/png/mob-human-left.png';
import mobHumanRight from 'assets/png/mob-human-right.png';
import victory from 'assets/png/victory.png';

const MobileRoom = () => {
  const { chainId, address } = useAccount();
  useActiveRoom();
  useSaltGenerator();
  const { setCloseHash, closeHash } = usePlayerGamesStore();
  const { isSuccess: isSuccessCloseUnusedRoom } = useTransactionReceipt(
    closeHash?.[chainId!]?.hash,
    closeHash?.[chainId!]?.date,
  );
  useEffect(() => {
    if (isSuccessCloseUnusedRoom) {
      setCloseHash(chainId!, null);
    }
  }, [isSuccessCloseUnusedRoom]);
  const [showMoves, setShowMoves] = useState<boolean>(false);
  const { resetGameStatus } = useGameStatusStore();
  const { showHash, setShowHash } = useRoomStore();
  const { bet, playerB, someoneElseGame, winnerStatus, amIPlayerA, roomStatus, playerA } =
    useRoomStore();
  const { activeRoomId } = useParams();
  useEffect(() => {
    if (
      activeRoomId &&
      playerB.address !== NULL_ADDRESS &&
      winnerStatus === WinnerStatusEnum.None &&
      roomStatus !== RoomStatusEnum.Closed &&
      !someoneElseGame
    ) {
      setShowMoves(true);
    } else {
      setShowMoves(false);
    }
  }, [activeRoomId, playerB, winnerStatus]);
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      #sentry-feedback {
        --inset: auto auto 0 0;
      }
    `;
    const sentry = document.querySelector('#sentry-feedback');
    let timeoutId: NodeJS.Timeout;
    if (sentry) {
      const shadowRoot = sentry.shadowRoot;
      if (shadowRoot) {
        const tooltip = document.createElement('span');
        tooltip.textContent = 'Report a Bug';
        tooltip.style.backgroundColor = '#0C0B4D';
        tooltip.style.color = '#F7F7F7';
        tooltip.style.padding = '7px';
        tooltip.style.borderRadius = '4px';
        tooltip.style.border = '1px solid #202066';
        tooltip.style.zIndex = '1000';
        tooltip.style.position = 'absolute';
        tooltip.style.bottom = '76px';
        tooltip.style.left = '6px';
        tooltip.style.fontFamily = 'Poppins, sans-serif';
        shadowRoot.appendChild(tooltip);
        timeoutId = setTimeout(() => {
          shadowRoot.removeChild(tooltip);
        }, 5000);
        document.head.appendChild(style);

        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          if (shadowRoot && shadowRoot.contains(tooltip)) {
            shadowRoot.removeChild(tooltip);
          }
          document.head.removeChild(style);
        };
      }
    }
  }, []);
  useCreateJoinRoomEvents();
  const [animationsTriggered, setAnimationsTriggered] = useState(false);
  useEffect(() => {
    if (activeRoomId && playerB.address !== NULL_ADDRESS && roomStatus !== RoomStatusEnum.Closed) {
      setAnimationsTriggered(true);
    }
  }, [activeRoomId, playerB]);
  const [isShowEndGameAnimation, setIsShowEndGameAnimation] = useState<boolean>(false);
  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed && !someoneElseGame) {
      resetGameStatus();
      setIsShowEndGameAnimation(true);
    }
  }, [roomStatus]);
  const renderGameResultImage = () => {
    if (someoneElseGame || !isShowEndGameAnimation) {
      return null;
    }
    setTimeout(() => {
      setIsShowEndGameAnimation(false);
    }, 6000);

    if (!amIPlayerA) {
      if (winnerStatus === WinnerStatusEnum.WinnerPlayerB) {
        return (
          <img
            src={victory}
            alt="victory image"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-fadeIn`}
          />
        );
      }

      if (winnerStatus === WinnerStatusEnum.WinnerPlayerA) {
        return (
          <img
            src={defeat}
            alt="defeat image"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 `}
          />
        );
      }
    }

    if (winnerStatus === WinnerStatusEnum.WinnerPlayerA) {
      return (
        <img
          src={victory}
          alt="victory image"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
        />
      );
    }

    if (winnerStatus === WinnerStatusEnum.WinnerPlayerB) {
      return (
        <img
          src={defeat}
          alt="defeat image"
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 `}
        />
      );
    }

    return null;
  };
  const { gameStatus, mintingLoader, mintingHash } = useGameStatusStore();
  const getMessages = () => {
    if (mintingLoader === MintingEnum.YOUR_MOVE) {
      return waitYourMoveMessages;
    }
    if (mintingLoader === MintingEnum.YOUR_REVEAL) {
      return waitYourRevealMessages;
    }
    if (mintingLoader === MintingEnum.TAKE_MONEY_BACK) {
      return takeMoneyBackMessages;
    }
    if (mintingLoader === MintingEnum.TECHNICAL_DEFEAT) {
      return technicalDefeatMessages;
    }
  };
  return (
    <div className="w-full h-full relative overflow-x-hidden">
      <MobileRoomMenu />
      <div className="h-screen min-h-[570px] max-h-full flex flex-col justify-between ">
        <div className="pt-[12px] sm:pt-[30px] px-[14px] watch-game-card player-card-host z-[100]">
          <div className="h-[44px] mb-[12px]" />

          <MobileGameCard />
          {!isShowEndGameAnimation && <MobileHistoryMoves />}
          <GameStatus
            isShowEndGameAnimation={
              isShowEndGameAnimation ||
              mintingLoader === MintingEnum.YOUR_MOVE ||
              mintingLoader === MintingEnum.YOUR_REVEAL ||
              mintingLoader === MintingEnum.TECHNICAL_DEFEAT
            }
          />
        </div>
        <AnimatedTransition
          show={
            mintingLoader === MintingEnum.YOUR_MOVE || mintingLoader === MintingEnum.YOUR_REVEAL
          }
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full flex justify-center pt-10`}
        >
          <MintingLoader className="pt-5" hash={mintingHash} messages={getMessages()} />
        </AnimatedTransition>
        {showMoves && <MobilePlayMenu />}
        <AnimatedTransition
          show={roomStatus === RoomStatusEnum.Open && playerA.address === address.toLowerCase()}
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full`}
        >
          <GuysLoader className="w-full mx-auto " messages={lookingForOpponent} />
        </AnimatedTransition>

        <AnimatedTransition show={isShowEndGameAnimation}>
          {renderGameResultImage()}
        </AnimatedTransition>
        <AnimatedTransition show={showHash}>
          <div className="border-[2px] sm:border-[3px] border-border rounded-[4px] sm:rounded-[14px] w-[90%] mx-auto max-w-[500px] h-[450px] overflow-hidden radi h-content absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Hash setIsShow={setShowHash} />
          </div>
        </AnimatedTransition>
      </div>
      <img
        src={mobHumanLeft}
        alt="guy"
        className={classNames(
          'absolute bottom-10 left-1/2 transform -translate-x-full z-[100] h-[500px] opacity-0',
          animationsTriggered && 'animate-slide-in-from-left-mobile',
        )}
      />
      <img
        src={mobHumanRight}
        alt="guy"
        className={classNames(
          'absolute bottom-10 right-1/2 transform translate-x-full z-[100] h-[500px] opacity-0',
          animationsTriggered && 'animate-slide-in-from-right-mobile',
        )}
      />
      <img
        src={floor}
        alt="floor"
        className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-0 h-[20%] min-w-[1120px]"
      />
    </div>
  );
};

export default MobileRoom;
