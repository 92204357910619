import { useEffect, useState } from 'react';

export const TimeAgo = ({ time }: { time: number }) => {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getTimeAgo = (time: number) => {
    const diffInSeconds = Math.max(Math.floor((currentTime - time) / 1000), 0);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec ago`;
    } else {
      const diffInMinutes = Math.max(Math.floor(diffInSeconds / 60), 0);
      const remainingSeconds = diffInSeconds % 60;
      return remainingSeconds > 0
        ? `${diffInMinutes} min ${remainingSeconds} sec ago`
        : `${diffInMinutes} min ago`;
    }
  };

  if (!time) return null;
  return <span>{getTimeAgo(time)}</span>;
};
