export const coinIcons = {
  USDT: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=040',
  USDC: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=040',
  ARB: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=040',
  BONK: 'https://cryptologos.cc/logos/bonk1-bonk-logo.svg?v=040',
  DAI: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.svg?v=040',
  LINK: 'https://cryptologos.cc/logos/chainlink-link-logo.svg?v=040',
  PENDLE: 'https://cryptologos.cc/logos/pendle-pendle-logo.svg?v=040',
  WBTC: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/wrapped-bitcoin-wbtc-icon.png',
  AERO: 'https://coin-images.coingecko.com/coins/images/31745/large/token.png?1696530564',
  APE: 'https://cryptologos.cc/logos/apecoin-ape-ape-logo.svg?v=040',
  BLUR: 'https://static.mercadobitcoin.com.br/web/img/icons/assets/ico-asset-blur-color.svg',
  BOND: 'https://cryptologos.cc/logos/barnbridge-bond-logo.svg?v=040',
  BRETT: 'https://cryptologos.cc/logos/based-brett-brett-logo.svg?v=040',
  DEGEN: 'https://cryptologos.cc/logos/degen-base-degen-logo.svg?v=040',
  FRIEND: 'https://s2.coinmarketcap.com/static/img/coins/64x64/31056.png',
  PEPE: 'https://cryptologos.cc/logos/pepe-pepe-logo.svg?v=040',
  SAND: 'https://cryptologos.cc/logos/the-sandbox-sand-logo.svg?v=040',
  SHIB: 'https://cryptologos.cc/logos/shiba-inu-shib-logo.svg?v=040',
  WETH: 'https://cryptofonts.com/img/SVG/weth.svg',
  WMATIC: 'https://polygonscan.com/token/images/wMatic_32.png',
};
