import { KeyMap, KeyMapA, KeyMapB } from '../components/ConfigHero';
// Import animationMap and AnimationConfig
import SpineHero from '../components/SpineHero';
import Controller from '../core/Controller';
import Scene from '../core/Scene';
import SpritesheetAnimation from '../core/SpritesheetAnimation';
import { Debug } from '../utils/debug';
import { Spine } from '@esotericsoftware/spine-pixi-v8';
import GUI from 'lil-gui';
import { Sprite } from 'pixi.js';

export default class Main extends Scene {
  sprites: Record<string, Sprite | SpritesheetAnimation | Spine | SpineHero>;
  spineHeroA: SpineHero;
  spineHeroB: SpineHero;
  controller: Controller;
  gui = GUI;
  isGUIanim = false;
  currentAnimationA = KeyMap.WALK_A;
  currentAnimationB = KeyMap.WALK_B;
  myObject = {
    time: 0,
    isGUIanim: false,
    // timeScale: 1,
    myFunction: () => Debug.log('GUI log'),
    // myString: 'lil-gui',
    // myNumber: 1,
    currentAnimationA: KeyMap.WALK_A,
    currentAnimationB: KeyMap.WALK_B,
  };
  setupDebugGUI() {
    this.gui = new GUI();

    this.gui
      .add(this.myObject, 'isGUIanim')
      .name('off/on')
      .onChange((value: boolean) => {
        this.isGUIanim = value;
        this.isGUIanim ? this.controller.clear() : null;
      });
    this.gui
      .add(this.myObject, 'currentAnimationA', KeyMapA)
      .name('Animation_A')
      .onChange((key: string) => {
        Debug.log(`Selected animationA: ${key}`);
        this.controller.clear();
        this.playAnimation(key);
      });

    this.gui
      .add(this.myObject, 'currentAnimationB', KeyMapB)
      .name('Animation_B')
      .onChange((key: string) => {
        Debug.log(`Selected animationB: ${key}`);
        this.controller.clear();
        this.playAnimation(key);
      });
  }

  positionSprites(w: number, h: number, _h: number = 1200) {
    Debug.log('🖥️ Resized to', w, h);
    const maxScale = 0.55;
    let sideMargin = 100;
    let bottumMargin = 100;
    let finalH_AB = h;

    let finalW_A = 0;
    let finalW_B = w;

    //
    let scale = h >= 1200 ? maxScale : (maxScale * (h * 0.54)) / this.spineHeroA.spine.height;

    const finalScale = w < 1200 ? maxScale * 0.8 : ((_h - 700) / w) * maxScale;

    //// scale=====
    scale = maxScale;
    finalH_AB = h;

    if (h < 1180) {
      if (w < 1200) {
        scale = maxScale * 0.9;
        if (h < 1080) {
          scale = (h / 1400) * 0.8;
        }
      } else {
        scale = maxScale * 0.9;
      }
    }

    // if (finalScale <= maxScale) {
    this.spineHeroA.scale = scale;
    this.spineHeroB.scale = scale;
    this.spineHeroB.direction = -1;
    //=pos
    finalW_A = 0;
    finalW_B = w;
    sideMargin = 0;
    bottumMargin = 55;

    //==
    if (w >= 1200) {
      //sideMargin = 90;
      sideMargin = h < 1200 ? (h > 1120 ? 0 : 20) : 90;
      bottumMargin = 0;
    } else if (w >= 1100) {
      sideMargin = 60;
      if (h < 1170 && h > 649) {
        let val = this.spineHeroA.view.width * 0.43;
        finalW_A = -val;
        finalW_B = w + val;
      }
    } else if (w < 970 || w > 470) {
      sideMargin = 0;

      let val = this.spineHeroA.view.width * (w <= 649 ? 0.5 : 0.33);

      finalW_A = -val;
      finalW_B = w + val;
    }
    //==
    finalW_A = finalW_A + sideMargin;
    finalW_B = finalW_B - sideMargin;
    finalH_AB = finalH_AB - bottumMargin;
    this.spineHeroA.directionalView.position.set(finalW_A, finalH_AB);
    this.spineHeroB.directionalView.position.set(finalW_B, finalH_AB);
  }

  playAnimation(key: string) {
    this.controller.playAnimation(key);
  }

  playSequence(isLeft: boolean, arr: Array<string>, looplast: boolean) {
    /*  Debug.log(
      `Playing animation first= ${arr[0]}, last= ${arr[1]} looplast=${looplast}`,
    );
    if (isLeft) {
      // this.playerLeft.playSequence(arr, looplast);
    } else {
      // this.playerRight.playSequence(arr, looplast);
    } */
  }

  updatePhase: UpdatePhaseMethod = (phase, round, rounds, room, queue) => {
    if (this.isGUIanim) return;
    const currentTimeMillis = Date.now();
    const currentTimeSeconds = Math.floor(currentTimeMillis / 1000);
    Debug.log(`🖥️  Main Updating Phase: ${phase}, Round: ${round}, sec: ${currentTimeSeconds}`);
    this.controller.update(phase, round, rounds, room, queue);
  };

  async load() {
    this.spineHeroA = new SpineHero();
    this.spineHeroB = new SpineHero();
    this.controller = new Controller();
    this.spineHeroA.view.visible = false;
    this.spineHeroB.view.visible = false;

    this.sprites = {
      viewA: this.spineHeroA.view,
      viewB: this.spineHeroB.view,
    };

    this.addChild(...Object.values(this.sprites));

    // if (!import.meta.env.DEV) return;
    // this.setupDebugGUI();
  }

  onUpdate(delta: number): void {
    if (this.spineHeroA.isSpawning() || this.spineHeroB.isSpawning()) {
      return;
    }
    //== A
    this.spineHeroA.state.walk = this.controller.keys.walk_A.set;
    this.spineHeroA.state.idle = this.controller.keys.idle_A.set;
    this.spineHeroA.state.shake = this.controller.keys.shake_A.set;
    this.spineHeroA.state.show_rock = this.controller.keys.show_rock_A.set;
    this.spineHeroA.state.show_paper = this.controller.keys.show_paper_A.set;
    this.spineHeroA.state.show_scissors = this.controller.keys.show_scissors_A.set;

    //== B
    this.spineHeroB.state.walk = this.controller.keys.walk_B.set;
    this.spineHeroB.state.idle = this.controller.keys.idle_B.set;
    this.spineHeroB.state.shake = this.controller.keys.shake_B.set;
    this.spineHeroB.state.show_rock = this.controller.keys.show_rock_B.set;
    this.spineHeroB.state.show_paper = this.controller.keys.show_paper_B.set;
    this.spineHeroB.state.show_scissors = this.controller.keys.show_scissors_B.set;

    // Update character's animation based on the latest state.
    this.spineHeroA.update();
    this.spineHeroB.update();
  }

  onResize(width: number, height: number) {
    if (width === 0) return;
    this.positionSprites(width, height /* >= 1200 ? 1200 : height */, height);
  }

  start() {
    setTimeout(() => {
      this.spineHeroA.view.visible = true;
      this.spineHeroB.view.visible = true;
    }, 3000);
    this.controller.playAnimation(KeyMap.WALK_A);
    this.controller.playAnimation(KeyMap.WALK_B);
  }
}
