import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePublicClient } from 'wagmi';
import { useAccount } from 'wagmi';

import { ExploreCard } from 'modules/explore-games/components/explor-card';
import { TableBar } from 'modules/explore-games/pages/components/table-bar';

import DesktopLayout from 'shared/components/layout/desktop-layout';
import { Spinner } from 'shared/components/ui/Loaders/spinner/spinner';
import { useGamesStore } from 'shared/store/games-store';

export const ExploreGames = () => {
  const { t } = useTranslation();
  const publicClient = usePublicClient();
  let { chainId } = useAccount();
  let { startStore, activeRooms, playedRooms, loading, clearStore } = useGamesStore();
  useEffect(() => {
    if (publicClient) {
      clearStore();
      startStore(publicClient);
    }
    return () => {
      clearStore();
    };
  }, [publicClient, chainId]);
  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);
  return (
    <DesktopLayout title={t('pages.explore')} key={chainId}>
      {loading && (
        <div className="w-full h-[calc(100vh-90px)] flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <div className=" max-w-[1920px]  flex flex-col justify-center mx-auto ">
        {/*<TableBar />*/}
        {!!activeRooms?.length && (
          <>
            <div className="flex items-center gap-6 mb-6">
              <div className="flex-grow h-[1px] bg-[rgba(255,255,255,0.25)]"></div>

              <p className="font-medium text-2xl text-[rgba(247,247,247,0.4)]">Active Games</p>

              <div className="flex-grow h-[1px] bg-[rgba(255,255,255,0.25)]"></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-8 mb-6">
              {activeRooms.map((room, index) => (
                <ExploreCard key={index} room={room} />
              ))}
            </div>
          </>
        )}

        {!!playedRooms?.length && (
          <>
            <div className="flex items-center  gap-6 mb-6">
              <div className="flex-grow h-[1px] bg-[rgba(255,255,255,0.25)]"></div>
              <p className="font-medium text-2xl text-[rgba(247,247,247,0.4)]">Game Replays</p>
              <div className="flex-grow h-[1px] bg-[rgba(255,255,255,0.25)]"></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-8  pb-6">
              {playedRooms
                .slice(-21)
                .reverse()
                .map((room, index) => (
                  <ExploreCard key={Number(room.data.roomId)} room={room} />
                ))}
            </div>
          </>
        )}
      </div>
    </DesktopLayout>
  );
};
