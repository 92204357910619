import { erc20Abi } from 'viem';
import { useReadContract } from 'wagmi';

interface Props {
  currencyAddress: `0x${string}` | undefined;
  walletAddress: `0x${string}`;
}

export const useBalance = ({ currencyAddress, walletAddress }: Props) => {
  const { data, isSuccess, isError, refetch } = useReadContract({
    address: currencyAddress,
    abi: erc20Abi,
    functionName: 'balanceOf',
    args: [walletAddress],
    query: {
      enabled: !!currencyAddress,
    },
  });

  const {
    data: decimals,
    isSuccess: isSuccessDecimals,
    isError: isErrorDecimals,
    refetch: refetchDecimals,
  } = useReadContract({
    address: currencyAddress,
    abi: erc20Abi,
    functionName: 'decimals',
    // query: {
    //   enabled: false,
    // },
  });
  if (isError) {
    console.error('useBalance balanceOf error:', data);
    refetch();
  }
  if (isErrorDecimals) {
    console.error('useBalance decimals error:', decimals);
    refetchDecimals();
  }
  if (
    isSuccess &&
    isSuccessDecimals &&
    typeof data !== 'undefined' &&
    typeof decimals !== 'undefined'
  ) {
    return {
      balance: Number(data) / 10 ** decimals,
      refetch,
    };
  }
  return {
    balance: null,
    refetch,
  };
};
