export enum CloserEnum {
  None,
  PlayerA,
  PlayerB,
}

export enum RoomStatusEnum {
  None, // 0 - default value
  Open, // 1 - room is created and waiting for second entrant
  ReadyForGame, // 2 - both entrants are in the room and waiting for game play
  ReadyForReveal, // 3 - both entrants are added move, waiting for reveal
  Closed, // 4 - room is closed
}

export enum WinnerStatusEnum {
  None, // 0 - default value
  WinnerPlayerA, // 1 - Entrant A is winner in room
  WinnerPlayerB, // 2 - Entrant B is winner in room
  PlayerAExitRoom, // 3 - entrant A exit room
  UnusedRoomClosed, // 4 - Both players are in the room, but the room is not used for a long time
  TechnicalWinnerA, // 5 - Entrant A is technical winner in room
  TechnicalWinnerB, // 6 - Entrant B is technical winner in room
}

// DO NOT LOOK AT GameStagesEnum in FRONTEND CODE
enum GameStagesEnum {
  None, // 0 - default value
  WaitForOpponent, // 1 - WaitForOpponent
  WaitForReveal, // 2 - WaitForReveal
  DoneWithWinner, // 3 - DoneWithWinner
  DoneWithDraw, // 4 - DoneWithDraw
}

export enum RoundStagesEnum {
  None, //
  InitGame, // new round
  AddMove, // one do a play
  RevealMove, // reveal 1 or 2
  BothRevealed,
  Done, //check
}

export enum MovesEnum {
  None,
  Rock,
  Scissors,
  Paper,
}

export enum MoveCardStatus {
  Active,
  Inactive,
  Winner,
  Looser,
}

export enum RoundWinnerOutcomes {
  None, // 0 - default value
  PlayerA, // 1 - PlayerA
  PlayerB, // 2 - PlayerB
  Draw, // 3 - Draw
}

export enum RoundOutcome {
  None,
  Win,
  Lose,
  Draw,
}

export enum bgColor {
  default,
  win,
  lose,
}
