import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { selectOption } from 'shared/components/ui/Select/Select';
import useMediaQuery from 'shared/hooks/useMediaQuery';

import QuestionIcon from 'assets/question.svg?react';

interface Props {
  open: boolean;
  list: selectOption[];
  onClick: (option: selectOption) => void;
  active?: string | number;
  position: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
}

const Dropdown = ({ open, list, onClick, position, active }: Props) => {
  const { t } = useTranslation();
  const [showTip, setShowTip] = React.useState<null | number>(null);
  const isMobileDevice = useMemo(
    () => /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    [],
  );
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <AnimatedTransition show={open} asFragment>
      <div
        className="rounded-[4px] border border-border bg-blue-menu absolute z-[100] w-[196px]"
        style={{ ...position }}
      >
        <ul className=" flex flex-col items-center text-[16px] cursor-pointer">
          {list?.length > 0 &&
            list.map((item, index) => (
              <li
                className={`w-[174px] py-[12px] relative flex justify-between ${
                  index !== list.length - 1 ? 'border-b border-border' : ''
                }
                ${item.value == active ? 'text-yellow-text' : 'text-white'}
                `}
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(item);
                }}
              >
                {t(item.label)}
                <AnimatedTransition show={index === showTip}>
                  <div className={`absolute top-0 left-[110%] w-[250px] lg:w-[400px] `}>
                    <div className="bg-blue-border relative rounded-[8px] tip px-[16px] sm:py-[12px] py-[5px] z-50">
                      <p className="text-white text-[12px] sm:text-[16px]">{t(item.tooltip!)}</p>
                    </div>
                  </div>
                </AnimatedTransition>
                {item.tooltip && !isMobileDevice && !isMobile && (
                  <QuestionIcon
                    className={`cursor-pointer`}
                    onMouseEnter={() => setShowTip(index)}
                    onMouseLeave={() => setShowTip(null)}
                  />
                )}
              </li>
            ))}
        </ul>
      </div>
    </AnimatedTransition>
  );
};

export default Dropdown;
