import React from 'react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick: () => void;
  className?: string;
}

const BetButton = ({ text, onClick, className, ...rest }: Props) => {
  return (
    <button
      className={`${className ?? ''} ${
        rest.disabled ? 'border-gray-border text-gray-border' : 'border-white text-white'
      } border-[1px] rounded-[7px] py-[6px] sm:pt-[14px] sm:pb-[10px] px-[10px] sm:px-[20px] focus:outline-none`}
      onClick={onClick}
      {...rest}
    >
      <span className=" text-[11px] sm:text-[21px] font-medium leading-5 whitespace-nowrap">
        {text}
      </span>
    </button>
  );
};

export default BetButton;
